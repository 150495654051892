.upsell-card__top-pick {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--rc-color-palette-gray-900);
  color: var(--rc-color-palette-bw-white);
  text-transform: uppercase;
  padding: 2px 2px 2px 35px;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.05em;
}

.upsell-card__top-pick__logo {
  display: inline-block;
  background: url('../images/icons/reverb-top-pick-icon.svg') left center no-repeat;
  background-size: 100% auto;
  height: 30px;
  width: 30px;
  position: absolute;
  left: 3px;
  top: -6px;
}
