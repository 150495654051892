.stamp {
  display: inline-block;
  font-size: 22px;
  font-weight: bold;
  border: double 6px var(--rc-color-sentiment-positive-dark);
  color: var(--rc-color-sentiment-positive-dark);
  margin-left: 14px;
  padding: 4px 12px;
  border-radius: 4px;
  text-transform: uppercase;
  transform: rotate(12deg);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: -6px;
    right: -6px;
    bottom:-6px;
    left: -6px;
    background-image: url('../svg/white-grunge.svg');
    background-repeat: repeat;
  }
}
