@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.reviews-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.product-reviews-list {
  & > li {
    border-bottom: 1px solid var(--rc-color-border-primary);
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  .avatar {
    &:hover { opacity: 1; }
  }

  .readmore-js-toggle {
    margin-top: 4px;
    font-size: 1.2rem;
    color: var(--rc-color-palette-gray-500);
  }
}

.hero-header-review-stars {
  @include breakpoint-tablet-up {
    &:hover { opacity: 0.85; }
  }

  .fa-star {
    &:not(.product-review-star) { color: var(--rc-color-text-secondary); }
  }
}

.product-review-entry {
  min-height: 8rem;
  margin-bottom: 1rem;
  width: 100%;
  resize: vertical;
}

.product-reviews__title {
  font-size: 22px;

  @include mobile {
    font-size: 18px;
  }
}

.product-review-title {
  font-weight: bold;
  margin-bottom: 0;

  .description-section section & {
    font-size: 90%;
    line-height: 1.4em;
    margin-bottom: 0.5rem;
  }
}

.product-review-description {
  font-size: 1.4rem;
  white-space: pre-wrap;
  margin: 0;
}

.product-review-star { color: var(--rc-color-palette-vu-orange-300); }

.write-a-review-form {
  max-height: 0;
  overflow: hidden;
  transition: max-height .4s ease-in-out;

  &.write-a-review-form--open {
    max-height: 70rem;
    margin-top: 2rem;
  }

}

.write-a-review {
  @include clearfix;
  @include scaling(padding, 2rem);
  background-color: var(--rc-color-palette-bw-white);
  border-radius: 0.3rem;

  .description-section & {
    padding: 0;
  }

  .alert-error:first-letter {
    text-transform: capitalize;
  }
}

.write-a-review__close-button {
  @include button-reset;
  float: right;
  font-size: 1.6rem;
  padding: 0.25em;

  color: var(--rc-color-palette-blue-600);

  &:hover {
    color: var(--rc-color-palette-vu-orange-500);
  }
}

.product-review-star-input {
  cursor: pointer;
  display: inline-block;

  .fa-star, .fa-star-o {
    color: var(--rc-color-text-secondary);
    display: block;
    float: left;
  }

  // Selected stars color
  .fa-star.product-review-star {
    color: var(--rc-color-palette-vu-orange-300);
  }
}

.product-review-edit-btn {
  @include rc-button.filled;
  font-size: 12px;
  padding: 4px;
}
