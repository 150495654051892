.order-details {
  td {
    padding-left: 0;
    padding-right: 0;
  }

  td,
  th {
    vertical-align: top;
    padding-bottom: 0.5em;
  }

  .invoice-total {
    padding-top: 0.5em;
  }

  .top-bordered {
    border-top: .1rem solid var(--rc-color-border-primary);
  }

  .invoice-bucks {
    color: var(--rc-color-text-alert-success);
  }
}

.invoice {
  margin-top: 2.8rem;

  .no-btm-margin {
    margin-bottom: 0;
  }

  hr {
    margin: 1.8rem 0;
  }

  p {
    margin-top: 0;
    line-height: 1.7em;
    margin-bottom: 1em;
  }

  td {
    padding-left: 0;
    padding-right: 0;
  }

  td,
  th {
    vertical-align: top;
    padding-bottom: 0.5em;
  }

  .left-col {
    width: 31rem;
  }

  .right-col {
    width: 22.6rem;
    padding-left: 3.2rem;
  }

  .half-table {
    width: 12rem;
    font-size: 1.4rem;

    &.right-side {
      font-size: 1.4rem;
      text-align: right;
    }
  }

  .top-bordered {
    border-top: .1rem solid var(--rc-color-border-primary);
  }

  .invoice-total {
    padding-top: 0.5em;
    font-size: 1.4rem;
  }

  .muted-title {
    margin-bottom: 0;
  }

  .delivery {
    line-height: 1.5em;
  }

  .invoice-bucks {
    color: var(--rc-color-text-alert-success);
  }
}
