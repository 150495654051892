.sponsored {
  background: var(--rc-color-palette-gray-300);
  padding: 8px;
  margin: 0 -8px;
  border-radius: 4px;
  margin-bottom: $grid-gutter;

  .full-width-footer__dark & {
    background: var(--rc-color-palette-gray-300);
  }

  .card-grid__item {
    margin-bottom: 0;
  }

  .bump-label {
    padding-bottom: 4px;
    color: #A09DA1;
    a {
      color: #A09DA1;
      &:hover { text-decoration: underline; }
    }
  }

  .product {
    margin-right: 18px;
    border: 1px solid var(--rc-color-border-primary);
  }

  @include tablet { padding: 4px; }

  @include mobile {
    padding: 8px;
    width: 620px;
    .card-grid__item:nth-of-type(n+3) {
      display: none;
    }
  }
}
