@use './responsive-hover';

// Box Sizing
//
// This mixin is a template for our most common use case, but the args are there for use if needed.
// Notes:
// - "inset" is not supported by this mixin. If you need to use inset just use box-shadow directly.
// - transparency decreases the alpha channel. IE, 0.9 will make something 90% more transparent.
//   This is equivalent to rgba(myColor, 0.1)
@mixin box-shadow(
  $x-offset: 0,
  $y-offset: 0,
  $blur-radius: var(--rc-space-gap-grid),
  $spread-radius: 0,
  $color: var(--rc-color-box-shadow-primary),
) {
  box-shadow: $x-offset $y-offset $blur-radius $spread-radius $color;
}

// this is a separate call and not built into the above in case
// an element requires a different `transition` property.
@mixin shadow-animation {
  transition: box-shadow 0.2s, background 0.2s, color 0.2s;
}

@mixin shadow-on-hover {
  @include shadow-animation;

  box-shadow: transparent;
  border: 0.1rem solid var(--rc-color-border-primary);

  @include responsive-hover.responsive-hover {
    @include box-shadow;
  }
}
