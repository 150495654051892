@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '../../base/mixins/style';

.checkout-product-list__item {
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.checkout-product-list__item__discount-applied {
  font-size: 90%;
  font-weight: bold;
  color: var(--rc-color-text-price-nudge);
  margin: 0;

  &:before {
    @include fa-icon(tag);
    margin-right: 0.1em;
  }
}

.new-checkout {
  .content-frame {
    @include mobile {
      width: 100%;
    }
  }

  .module {
    border-radius: 0.4rem;
    margin-bottom: 2rem;

    @include mobile {
      border-radius: 0;
      padding: 1rem;
    }

    &.btm-square-module {
      border-radius: 6px 6px 0 0;
      margin-bottom: 0;

      @include mobile {
        border-radius: 0;
      }
    }

    &.contrast-module-footer {
      border-radius: 0 0 6px 6px;
      background-color: var(--rc-color-palette-gray-100);
      border-top: 1px solid var(--rc-color-background-page-secondary);

      @include mobile {
        border-radius: 0;
      }
    }
  }

  input[type='text'],
  input[type='tel'],
  input[type='password'],
  select {
    width: 100%;
  }

  // Override speech-bubble width so it doesn't wrap outside container
  .speech-bubble--error {
    box-sizing: border-box;
    width: 100%;
  }
}

.btn-checkout-edit {
  @include rc-button.filled;
  font-size: 12px;
  padding: 5px 7px;
}

.cc-visa {
  @include style.fluid-bg-image(60px, 40px);

  background: url('../images/checkout/visa.svg') no-repeat;
}

.cc-discover {
  @include style.fluid-bg-image(60px, 40px);

  background: url('../images/checkout/discover.svg') no-repeat;
}

.cc-amex, .cc-american-express {
  @include style.fluid-bg-image(60px, 40px);

  background: url('../images/checkout/amex.svg') no-repeat;
}

.cc-mastercard {
  @include style.fluid-bg-image(60px, 40px);

  background: url('../images/checkout/mastercard.svg') no-repeat;
}

.cc-generic {
  @include style.fluid-bg-image(60px, 40px);

  background: url('../images/checkout/generic.svg') no-repeat;
}

.cc-bucks {
  @include svg.icon-bg(payment-icon-reverb-bucks);
  height: 4rem;
}

.cc-reverb-credits {
  @include svg.icon-bg(payment-icon-reverb-credits);
  height: 4rem;
}

.cc-paypal {
  @include svg.icon-bg(payment-icon-paypal);
  height: 4rem;
}

.cc-header-icon {
  width: 35px;
}

.confirmation-frame {
  width: 800px;
  margin: 0 auto;

  @include mobile {
    width: 100%;
  }
}

.confirmation-product-img {
  background: var(--rc-color-border-primary);
  border: 0.1rem solid var(--rc-color-palette-gray-200);
  border-radius: var(--rc-border-radius-lg);
}

.confirmation-product-col {
  @include grid-column(8);

  @include mobile {
    @include grid-column(12);
  }
}

$paypal-button-bg: #FFC439;
$paypal-button-border: #F8A905;

.pay-with-paypal-btn {
  display: table;
  cursor: pointer;
  background: $paypal-button-bg;
  border: 1px solid $paypal-button-border;
  -webkit-appearance: none;
  display: block;
  border-radius: 4px;
  padding: 8px 12px;
  font-family: 'Helvetica Neue', 'Helvetica', sans-serif;
  font-size: 14px;
  font-weight: bold;

  @include mobile {
    float: none;
    margin: 0 auto;
  }

  @include breakpoint-tablet-up {
    float: right;
  }

  &:hover {
    opacity: 0.85;
  }

  &[disabled="disabled"], &[disabled], &:disabled {
    padding: 11px;

    &:hover {
      opacity: 1;
    }
  }
}

.pay-with-paypal-btn__text {
  display: table-cell;
  vertical-align: middle;
}

.pay-with-paypal-btn__paypal-icon {
  display: table-cell;
  vertical-align: middle;
  -webkit-appearance: none;
  border: none;
  width: 106px;
  height: 24px;
  background: url('../images/cart/paypal-button-icon.png') no-repeat;
  background: url('../images/cart/paypal-button-icon.svg') no-repeat;
}
