.video-page-header {
  @include commons-svg-background('instruments.svg');

  box-sizing: border-box;
  width: 100%;
  background-color: var(--rc-color-palette-gray-900);
  color: var(--rc-color-palette-bw-white);
  overflow: hidden;
  z-index: 100;

  @include mobile {
    height: auto;
    box-shadow: none;
  }
}

.video-page-header__inner {
  @include site-wrapper;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.video-page-header__nav {
  flex: 0;
  padding: 2rem 0 0;

  @include tablet {
    display: none;
  }
}

.video-page-header__nav-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video-page-header__title {
  font-size: 2.4rem;
  font-weight: 700;
  color: var(--rc-color-palette-bw-white);

  a {
    color: currentColor;
  }
}

.video-page-header__nav-links {
  border: 0.2rem solid var(--rc-color-palette-bw-white);
  border-radius: 5rem;
  overflow: hidden;
  display: flex;
}

.video-page-header__nav-link {
  display: block;
  color: var(--rc-color-palette-bw-white);
  font-weight: 700;
  padding: 0.6rem 1.4rem;

  &.video-page-header__nav-link--active,
  &:hover {
    background-color: var(--rc-color-palette-bw-white);
    color: var(--rc-color-text-primary);
  }
}

.video-page-header__content {
  flex: 1;
  padding: 2rem 0;
  display: flex;

  @include tablet {
    display: block;
  }

  @include mobile {
    padding: 3vw 0;
  }
}

.video-page-header__embed-container {
  width: 50%;

  @include tablet {
    width: 100%;
  }
}

.video-page-header__embed {
  width: 100%;
  height: 0;
  padding-top: percentage(9/16); // 16:9 ratio
  position: relative;
  background-color: black;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.video-page-header__details {
  flex: 1;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;

  @include tablet {
    padding-left: 0;
    margin-top: 2rem;
  }

  @include mobile {
    display: none;
  }
}

.video-page-header__video-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5em;
  flex: 0;
}

.video-page-header__video-description {
  flex: 1;
  overflow-y: auto;

  @include mobile {
    font-size: 1.4rem;
  }
}

.video-page-header__footer {
  flex: 0;
}

.video-page-header__subscribe {
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  top: -0.6rem;
  margin-right: 0.4rem;
}
