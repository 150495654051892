@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.gift-card-module {
  margin: 2rem 0;
  padding: 1.8rem;
  background-color: var(--rc-color-palette-bw-white);
  border-radius: 0.4rem;

  @include mobile {
    margin: 1rem 0;
    padding: 1.6rem;
  }

  &.gift-card-module--footer {
    padding: 0;
    background-color: transparent;

    @include mobile {
      padding: 0;
    }
  }

  &.gift-card-module--alert {
    background-color: var(--rc-color-sentiment-warning-lighter);
    border: solid 0.1rem var(--rc-color-sentiment-warning-light);
  }

  &.gift-card-module--alert-success {
    color: var(--rc-color-text-alert-success);
    background-color: var(--rc-color-sentiment-positive-lighter);
    border: solid 0.1rem var(--rc-color-sentiment-positive-light);
  }

  &.gift-card-module--print {
    background: none;
    border-radius: 0;
    margin: 0 auto 1.8rem;
    padding: 0;
    width: 32rem; // used for print only in app/views/gift_cards/print.haml

    @include mobile {
      margin: 0 auto 1.8rem;
      padding: 0;
    }
  }

  // CC style overrides until disco-ui can be fixed
  input[type='radio'].boxed-list__toggle + .boxed-list__item--selectable {
    padding-left: 1em;
  }
}

.gift-card-module__text {
  font-size: 1.6rem;
  margin-bottom: 0;

  &.gift-card-module__text--muted {
    @include responsive(font-size, 1.4rem, $mobile: 1.2rem);
    color: var(--rc-color-text-secondary);
  }

  @include mobile {
    font-size: 1.5rem;
  }
}

.gift-card-module__title {
  font-size: 1.8rem;
  margin: 0 0 1.8rem;

  @include mobile {
    font-size: 1.7rem;
    margin-bottom: 1.6rem;
  }
}

.gift-card-module__values {
  position: relative;

  &.gift-card-values--error {
    border: 1px solid var(--rc-color-border-alert-error) !important;
    background: var(--rc-color-background-alert-error) !important;
    border-radius: 6px;
    padding: 1rem;
  }
}

.gift-card-module__values__inner {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
}

.gift-card-module__value {
  flex: 1;
  margin: 0.5rem;

  input { display: none; }

  @include tablet {
    min-width: 26%;
    max-width: 50%;
  }
}

.gift-card-module__value-label {
  display: block;
  padding: 1em;
  border: 0.2rem solid var(--rc-color-border-selectable);
  border-radius: var(--rc-border-radius-md);
  font-weight: normal;
  cursor: pointer;
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 0;

  @include mobile {
    padding: 1rem 1.6rem;
  }

  &:hover {
    background-color: var(--rc-color-background-module-hovered);
  }

  .gift-card-module__value input:checked + & {
    border-color: var(--rc-color-border-selected);
    font-weight: bold;
    cursor: default;
  }
}

.gift-card-module__card-thumbs {
  position: relative;
  margin-top: 1.8rem;

  @include mobile {
    margin-top: 1.6rem;
    // fade effect on mobile horizontal scrolling
    &::after {
      background: linear-gradient(to right, var(--rc-color-highlight-0) 0%, var(--rc-color-background-module) 100%);
      content: '';
      display: block;
      position: absolute;
      right: -0.2rem;
      top: 0;
      bottom: 0;
      width: 3rem;
    }
  }
}

.gift-card-module__card-thumbs__inner {
  @include grid-container;

  @include mobile {
    @include smooth-scroll;
    margin-left: 0;
    margin-right: 0;
    white-space: nowrap;
    overflow-x: auto;
  }
}

.gift-card-module__card-thumb {
  display: inline-block;
  width: 34%;
  margin-right: 1rem;

  @include min-width($site-width-narrow) {
    @include grid-column(3, $cycle: true);
  }

  input {
    display: none;
  }

  label {
    box-sizing: border-box;
    background-size: cover;
    border-radius: var(--rc-border-radius-md);
    border: solid 0.2rem var(--rc-color-border-primary);
    width: 100%;
    height: 0;
    padding-top: 50%;
    cursor: pointer;
    opacity: 0.85;
    transition: opacity 0.1s ease;
    position: relative;
    margin: 0;

    &:hover {
      opacity: 1;
    }

    &::before {
      @include fa-icon(check);
      display: none;
      align-items: center;
      justify-content: center;
      position: absolute;
      inset: 0;
      background-color: var(--rc-color-shadow-700);
      color: var(--rc-color-palette-bw-white);
      border-radius: calc(var(--rc-border-radius-md) - 0.2rem);
      overflow: hidden;
      text-align: center;
      font-size: 2.8rem;
    }
  }

  input:checked + label {
    opacity: 1;

    &:hover {
      cursor: default;
    }

    &::before {
      display: flex;
    }

  }
}
.gift-card-module__gift-message {
  textarea {
    display: block;
    width: 100%;
    margin: 0;
  }
}

.gift-card-module__gift-message-label {
  position: relative;
}

.gift-card-module__gift-message-character-limit {
  display: none; // sets initial state
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.2rem;
  color: var(--rc-color-text-secondary);
}

.gift-card-module__form-header {
  color: var(--rc-color-text-secondary);
  font-size: 1.6rem;
  margin: 2.7rem 0 0.9rem;
  padding-bottom: 0.9rem;
  border-bottom: solid 0.1rem var(--rc-color-palette-gray-200);
  font-weight: 700;
}

.gift-card-module__submit {
  text-align: right;

  @include mobile {
    text-align: center;
  }
}

.gift-card-module__submit-button {
  @include rc-button.filled;
  padding-left: 4rem;
  padding-right: 4rem;
  margin: 0;

  @include mobile {
    width: 100%;
  }
}

.gift-card-module__links {
  border-top: solid 0.1rem var(--rc-color-border-primary);
  text-align: center;
  margin-top: 2.8rem;
  padding-top: 2.8rem;

  @include mobile {
    margin-top: 1.4rem;
    padding-top: 1.4rem;
  }

  a {
    display: inline-block;
    padding: 0.2rem 1.6rem;

    @include mobile {
      font-size: 1.2rem;
    }
  }
}

.gift-card-module__card-preview-wrapper {
  margin-top: 1.8rem;

  @include mobile {
    margin-top: 1.6rem;
  }
}
