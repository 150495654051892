.item-summary {
  display: flex;
  justify-content: space-between;

  @include mobile {
    display: block;
  }
}

.item-summary__info {
  display: flex;

  @include mobile {
    display: block;
  }
}

.item-summary__thumbnail {
  width: 6.5rem;

  @include mobile {
    width: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.item-summary__details {
  flex: 1;
  margin-left: 2rem;

  @include mobile {
    margin-left: 0;
    margin-top: 1rem;
  }
}

.item-summary__title {
  font-weight: bold;

  @include mobile {
    margin-bottom: 1rem;
  }
}

.item-summary__meta {
  font-size: 1.4rem;
}

.item-summary__meta-detail {
  margin: 0;
}

.item-summary__costs {
  text-align: right;
  flex-shrink: 0;
  margin-left: 3rem;

  @include mobile {
    text-align: left;
    margin-top: 1rem;
    margin-left: 0rem;
  }
}

.item-summary__total {
  margin: 0;
}

.item-summary__price {
  margin: 0;
  display: inline-block;

  &.item-summary__price--original {
    text-decoration: line-through;
    margin-right: 0.5rem;
    color: var(--rc-color-text-secondary);
  }
}

.item-summary__totals {
  font-size: 1.4rem;
  width: 100%;

  td:last-child {
    padding-left: 2rem;

    @include mobile {
      text-align: right;
    }
  }

  tr:last-child {
    td {
      padding-bottom: 0.5rem;
    }
  }

  tfoot {
    font-weight: bold;

    td {
      border-top: solid 0.1rem var(--rc-color-border-primary);
      padding-top: 0.5rem;
    }
  }
}

.item-summary__discount-highlight {
  color: var(--rc-color-sentiment-positive-dark);
}
