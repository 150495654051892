@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.push-offer-input {
  .g-container {
    margin: 0;
  }

  .price-field {
    margin-bottom: 0.5rem;
  }
}

.listing-offer-input {
  .price-field {
    @include grid-column(6);
  }
  .shipping-field {
    @include grid-column(6);
  }

  .offer-action {
    @include rc-button.muted;
    width: 100%;
  }
}

.offer-input__make-button {
  @include rc-button.muted;
  width: 100%;
}

.offer-input__cancel-button {
  @include rc-button.muted;
  width: 100%;
}

.offer-input__submit-button {
  @include rc-button.filled;
  width: 100%;

  &.offer-input__submit-button--disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.offer-input__email-confirm-button {
  @include rc-button.muted;
  width: 100%;
}
