/////////////////////////////
// legacy placeholder styles, do not use
/////////////////////////////

$height-base-unit: 14px;

.loading-placeholder-content {
  .bordered { border: 1px solid var(--rc-color-border-primary); }
  .divider { border-color: var(--rc-color-border-primary); }
}

.placeholder-h1,
.placeholder-p,
.placeholder-li,
.placeholder-input,
.placeholder-btn,
.placeholder-gallery,
.placeholder-image {
  @include loading-pulse;
  background-color: var(--rc-color-background-loading-placeholder);
}

.placeholder-h1 {
  height: $height-base-unit * 1.8;
  width: 50%;

  &.quarter-width {
    width: 25%;
  }
}

.placeholder-p {
  height: $height-base-unit * 1.3;

  &.quarter-width {
    width: 25%;
  }
}

.placeholder-li {
  height: $height-base-unit * 0.75;

  &:nth-child(even) { width: 70%; }
  &:nth-child(odd)  { width: 90%; }
}

.placeholder-input {
  height: $height-base-unit * 2;
  width: 100%;
}

.placeholder-btn {
  height: $height-base-unit * 1.3;
  width: $height-base-unit * 5;
  border-radius: 3px;

  &.size-large {
    height: $height-base-unit * 2.5;
    width: $height-base-unit * 7;

    &.wide { width: 100%; }
  }
}

.placeholder-gallery {
  min-height: 440px;
  border-radius: 3px;
}

.placeholder-image {
  border-radius: 3px;

  &.size-small {
    width: $height-base-unit * 4.5;
    height: $height-base-unit * 4.5;
  }

  &.size-medium {
    width: $height-base-unit * 7.5;
    height: $height-base-unit * 7.5;
  }

  &.size-card {
    width: 226px;
    height: 226px;
  }

  &.size-featured {
    width: $height-base-unit * 19.6;
    height: $height-base-unit * 19.6;
  }

  &.size-square-tile-card {
    width: 100%;
    height: 226px;
  }

  &.size-gallery {
    width: 100%;
    height: 100%;
  }

  &.fluid-square {
    width: 100%;
    padding-bottom: 100%;
  }
}

.placeholder-spacer {
  height: $height-base-unit;
}
