.ribbon {
  font-size: 12px;
  font-weight: bold;
  padding: .25em .5em .3em .5em;
  color: white;
  background-color: var(--rc-color-text-price-nudge);
  opacity: 0.9;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 1;
  text-align: center;

  &.ribbon--auction {
    text-transform: uppercase;
    background-color: var(--rc-color-sentiment-positive-dark);
  }

  &.ribbon--pricedrop {
    text-transform: uppercase;
  }

  &.ribbon--ended {
    background-color: var(--rc-color-palette-gray-900);
    text-transform: uppercase;
  }

  .digital-listing-gallery &,
  // product show page
  .g-col-8 & {
    font-size: 1.6rem;
    right: auto;
    left: 0.5rem;
    top: 0.5rem;
  }
}

.small-ribbon .ribbon {
  font-size: 12px;
}

.listing-set .ribbon {
  box-shadow: none;
  top: auto;
  bottom: 1px;
  left: 1px;
  right: 1px;
  background: var(--rc-color-sentiment-negative-dark);
  padding: 1px 0;
}
