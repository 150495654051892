@mixin button-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-size: inherit;
  color: var(--rc-color-text-primary);
}
