@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

ul.reverb-embedded-comparison-shopping-pages-horizontal {
  @include grid-container;
  box-sizing: border-box;
  list-style: none;
  display: block;

  li {
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid var(--rc-color-border-primary);
    overflow: hidden;
    display: block;
  }

  .reverb-embedded-comparison-shopping-page-left-content {
    @include grid-column(3);
    box-sizing: border-box;
    min-height: 1px;
    border-right: 1px solid var(--rc-color-border-primary);

    @include mobile {
      @include grid-column(4);
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      transition: opacity .1s;

      &:hover {
        opacity: 0.85;
      }
    }
  }

  .reverb-embedded-comparison-shopping-page-right-content {
    @include grid-column(9);
    box-sizing: border-box;
    min-height: 1px;
    padding: 8px 12px 6px;

    @include mobile {
      @include grid-column(8);
      padding: 7px 10px;
    }
  }

  a {
    text-decoration: none;
    display: block;
  }

  .reverb-embedded-comparison-shopping-page-prices {
    color: var(--rc-color-text-primary);
    margin-top: 8px;

    @include mobile {
      margin-top: 4px;
    }
  }

  .reverb-embedded-comparison-shopping-page-price {
    margin-right: 8px;
    display: inline-block;
  }

  .reverb-embedded-comparison-shopping-page-price-amount {
    font-weight: bold;
    color: var(--rc-color-palette-vu-orange-500);
    display: block;
    font-size: 18px;
    line-height: 24px;
  }

  .reverb-embedded-comparison-shopping-page-title {
    @include truncate-text.truncate-text(
      $font-size: 18px,
      $line-height: 1.3,
    );
    color: var(--rc-color-text-primary);
    font-weight: bold;

    @include mobile {
      @include truncate-text.truncate-text(
        $font-size: 16px,
        $line-height: 1.2,
        $lines-to-show: 2
      );
    }
  }

  .reverb-embedded-comparison-shopping-page-summary {
    @include truncate-text.truncate-text(
      $font-size: 14px,
      $line-height: 1.3,
      $lines-to-show: 3
    );
    color: var(--rc-color-text-primary);
    margin-top: 3px;

    @include mobile { display: none; }
  }
}
