.listing-description-short-readmore,
.listing-description-readmore,
.feedback-response-readmore,
.previous-listing-description,
.feedback-readmore {
  position: relative;

  p:only-child {
    margin: 0;
  }

  &.readmore-js-collapsed:before {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    height: 2em;
    @include background-gradient(rgba(255,255,255, 0), rgba(255,255,255, 1.0));
  }
}

.previous-listing-description {
  &.readmore-js-collapsed:before {
    @include background-gradient(transparent, var(--rc-color-palette-blue-100));
  }
}

.feedback-response-readmore {
  &.readmore-js-collapsed:before {
    @include background-gradient(rgba(241,241,241, 0), rgba(241,241,241, 1.0));
  }
}

.listing-description-readmore .readmore-js-toggle {
  font-size: 16px;
}
