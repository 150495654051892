@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

.video-list {
  @include clearfix;
}

.video-list__item {
  @include grid-column(6, $cycle: true);
  position: relative;
  box-sizing: border-box;
  margin-bottom: 3%;
  padding: 0.5rem;
  color: var(--rc-color-text-secondary);
  border: 2px solid var(--rc-color-palette-gray-200);
  border-radius: 5px;
  background-color: var(--rc-color-palette-bw-white);
  cursor: pointer;

  @include mobile {
    @include grid-column(12, $cycle: false);
  }

  &.video-list__item--selected {
    color: inherit;
    border-color: var(--rc-color-sentiment-positive-dark);
    cursor: default;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.video-list__item__title {
  padding-top: 0.5rem;
  font-weight: bold;
  margin: 0;

  @include truncate-text.truncate-text(
    $font-size: 13px,
    $line-height: 1.2,
    $fixed-height: true
  );
}

.video-list__item__publisher {
  padding-top: 0.25rem;
  margin: 0;

  @include truncate-text.truncate-text(
    $font-size: 13px,
    $line-height: 1.2,
    $fixed-height: true
  );
}

.video-list__item__remove {
  @include button-reset;

  cursor: pointer;
  display: none;
  box-sizing: border-box;
  position: absolute;
  top: -0.7em;
  right: -0.5em;
  color: var(--rc-color-text-secondary);
  font-size: 2rem;
  background-color: var(--rc-color-palette-bw-white);

  .video-list__item--selected & {
    display: block;
  }

  &:hover {
    color: var(--rc-color-text-danger);
  }
}

.video-list__item__link {
  @include truncate-text.truncate-text(
    $font-size: 12px,
    $line-height: 1.2,
    $fixed-height: true
  );
}

.video-list__item__video-frame {
  position: relative;
  padding: percentage((9/16) / 2); /* 16:9 */
  height: 0;
  transform: translateZ(0);
  animation: loadingPlaceholderGradient 1.3s ease 7;
  background: linear-gradient(270deg, var(--rc-color-palette-gray-300), var(--rc-color-palette-gray-200));
  background-size: 400% 400%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

.youtube-embed-form {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;

  @include mobile {
    display: block;
  }
}

.youtube-embed-form__input-col {
  flex: 1 1 48%;
  box-sizing: border-box;

  @include mobile {
    width: 100%;
  }
}

.youtube-embed-form__or-col {
  flex: 1 1 4%;
  margin: 0 0.5rem;
  box-sizing: border-box;
  text-align: center;

  @include mobile {
    width: 100%;
    padding: 0.5em;
  }
}
