.autocomplete-suggestions {
  border: 1px solid var(--rc-color-border-primary);
  background: var(--rc-color-palette-bw-white);
  overflow: auto;
  margin-top: -4px; // cover up rounded corners of input
}

.autocomplete-selected {
  background: #F0F0F0;
}

.autocomplete-suggestions strong {
  font-weight: bold;
}

// New autocomplete
.autocomplete-cursor, .autocomplete-suggestion:hover {
  background: #F0F0F0;
  color: inherit;
}

form input[type=text].autocomplete-hint {
  color: #999;
}

.autocomplete-menu {
  @include box-shadow;
  padding: 8px 0;
  background-color: var(--rc-color-palette-bw-white);
  border: 1px solid var(--rc-color-border-primary);
  margin-top: -1px;
  right: 0;

  box-sizing: border-box;
}

.autocomplete-suggestion {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 6px 12px;
  line-height: 20px;
  display: block;
  color: var(--rc-color-text-primary);
  font-weight: bold;
  text-align: left;

  @include mobile {
    line-height: 25px;
  }

  .filter, .curated-set {
    color: var(--rc-color-palette-vu-orange-500);
  }

  .autocomplete-highlight {
    font-weight: normal;
  }
}

.autocomplete-dataset {
  position: relative;
}

.autocomplete-header {
  padding: 3px 12px;
  font-size: 80%;
  font-weight: bold;
  color: var(--rc-color-text-secondary);
}

.autocomplete-shop-search {
  font-weight: normal;
  color: var(--rc-color-palette-vu-orange-500);
}

.autosuggest-input__make, .autosuggest-input__model {
  width: 100%;
  margin-bottom: 0;
}
