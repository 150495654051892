table.table {
  margin: 0 auto;
  width: 100%;

  select {
    width: 100%;
  }

  tr.heading td {
    background: var(--rc-color-palette-gray-100);
    border-color: var(--rc-color-palette-blue-600);
  }

  th { font-weight: bold; }

  td, th {
    border-bottom: 1px solid var(--rc-color-border-primary);
    padding: 10px 6px;
  }

  td.quarter { width: 25%; }

  &.tight-rows {
    td, th { padding: 4px; }
  }

  &.medium-rows {
    td, th { padding: 6px; }
  }

  &.unbordered {
    td, th { border: none; }
  }

  tr.unbordered {
    td, th { border: none; }
  }

  &.four-col {
    th, td {
      width: 24.5%;
    }
  }

  &.no-bottom-border {
    tr:last-child {
      td, th { border-bottom: none; }
    }
  }

  .light-bg {
    background-color: var(--rc-color-background-page-secondary);
  }
}

// specific to override user agent styles on in-app web view
table.summation-table {
  width: 100%;

  td {
    border: none;
    padding: 5px 0;
    padding: 5px 0;
  }

  tr:first-child {
    td { padding-top: 0; }
  }

  tr:nth-last-child(2) {
    td {
      padding-bottom: 10px;
      padding-bottom: 10px;
    }
  }

  tr:last-child {
    td {
      padding: 10px 0 0;
      border-top: 2px solid var(--rc-color-border-primary);
    }
  }

  // specific to override weird user agent styles on %table elements in-app web view
  font-size: 1.4rem;
}

table.topaligned {
  td { vertical-align: top; }
}

table.wide {
  width: 100%;
}

table.header {
  width: 100%;
  text-align: center;

  .number {
    font-size: 2.4rem;
    @include mobile { font-size: 1.4rem; }
  }

  .subtitle {
    font-size: 1.3rem;
    @include mobile { font-size: 1.1rem; }
  }

  td {
    border: 0 none;
    padding: 15px;
  }
}

tr.table-sum {
  font-weight: bold;
  td {
    border: none;
    text-align: right;
    &.text-left {
      text-align: left;
    }
  }
}

td.right, th.right {
  text-align: right;
}

.alt-row-table {
  width: 100%;
  vertical-align: middle;
  border-collapse: separate;

  tr {
    td, th {
      padding: 0.5rem 0.8rem;
      padding-right: 0;

      &:last-child {
        min-width: 33px;
        padding-right: 0.8rem;
        box-sizing: border-box;

        &:empty {
          width: 0;
        }
      }
    }

    &:nth-child(even):not(.bg-no) td {
      background: var(--rc-color-palette-gray-100);
    }
  }

  th { font-weight: 900; }
  td {
    &.align-center {
      padding: 0;

      a {
        display: block;
        margin: 0.5rem 0;
      }
    }

    &.has-select {
      padding: 0.8rem 0.2rem 0.5rem;
    }
  }

  input {
    margin-bottom: 0;
  }

  // Themes
  &.cozy {
    td, th {
      padding: 0.7rem 1rem;
      padding-right: 0;

      &:last-child {
        padding-right: 1rem;
      }
    }
  }

  &.comfortable {
    td, th {
      padding: 1rem 1.3rem;
      padding-right: 0;

      &:last-child {
        padding-right: 1.3rem;
      }
    }
  }
}

.table-row {
  display: table;
  box-sizing: border-box;
  width: 100%;

  .table-row__cell {
    display: table-cell;
  }

  &.table-row--stack-mobile {
    .table-row__cell {
      @include mobile {
        display: block;
      }
    }
  }
}
