.in-demand-tag {
  line-height: 1.2;
  font-size: 1.4rem;
  margin-bottom: 0;
  color: var(--rc-color-text-price-nudge);
  text-align: center;
}

.in-demand-tag__title {
  background-color: var(--rc-color-sentiment-negative-dark);
  color: var(--rc-color-palette-bw-white);
  display: inline-block;
  border-radius: $input-border-radius;
  padding: 0.2rem 0.6rem;
  margin-bottom: 0.4rem;
}
