/* PowerTip Plugin */
#powerTip {
	cursor: default;
	background-color: #333; /* fallback for browsers that dont support rgba */
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 6px;
	color: #FFF;
	display: none;
	padding: 10px;
	position: absolute;
	white-space: nowrap;
	z-index: 1000;
}
#powerTip.n:before, #powerTip.e:before, #powerTip.s:before, #powerTip.w:before,
#powerTip.ne:before, #powerTip.nw:before, #powerTip.se:before, #powerTip.sw:before {
	content: "";
	position: absolute;
}
#powerTip.n:before, #powerTip.s:before {
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	left: 50%;
	margin-left: -5px;
}
#powerTip.e:before, #powerTip.w:before {
	border-bottom: 5px solid transparent;
	border-top: 5px solid transparent;
	margin-top: -5px;
	top: 50%;
}
#powerTip.n:before {
	border-top: 10px solid rgba(0, 0, 0, 0.8);
	bottom: -10px;
}
#powerTip.e:before {
	border-right: 10px solid rgba(0, 0, 0, 0.8);
	left: -10px;
}
#powerTip.s:before {
	border-bottom: 10px solid rgba(0, 0, 0, 0.8);
	top: -10px;
}
#powerTip.w:before {
	border-left: 10px solid rgba(0, 0, 0, 0.8);
	right: -10px;
}
#powerTip.ne:before, #powerTip.se:before {
	border-right: 10px solid transparent;
	border-left: 0;
	left: 10px;
}
#powerTip.nw:before, #powerTip.sw:before {
	border-left: 10px solid transparent;
	border-right: 0;
	right: 10px;
}
#powerTip.ne:before, #powerTip.nw:before {
	border-top: 10px solid rgba(0, 0, 0, 0.8);
	bottom: -10px;
}
#powerTip.se:before, #powerTip.sw:before {
	border-bottom: 10px solid rgba(0, 0, 0, 0.8);
	top: -10px;
}
