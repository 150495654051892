@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.article-card {
  width: 100%;
  height: 100%;

  a {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    background: var(--rc-color-background-page-secondary);
    color: var(--rc-color-text-primary);
    border-radius: var(--rc-border-radius-primary);
    transition: background-color 0.15s ease, box-shadow 0.15s ease;
    overflow: hidden;

    &:active,
    &:hover {
      @include box-shadow.box-shadow;
    }


  }
}

.article-card__primary {
  flex: 0 0 100%;
}

.article-card__image-container {
  position: relative;
  background-color: var(--rc-color-palette-gray-900);
  background-size: cover;
  height: 0;
  padding-bottom: 50%;

  @include mobile {
    padding-bottom: 30%;
  }
}

.article-card__title {
  @include rc-text.utility('400', 'bold');
  padding: var(--rc-space-5);
}

.article-card__meta {
  align-self: flex-end;
  flex: 0 0 100%;
  box-sizing: border-box;
  padding: var(--rc-space-5);
  padding-top: 0;
}

.article-card__date {
  @include rc-text.utility('350', 'semibold');
  float: left;
}

.article-card__category {
  float: right;

  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.06em;
}
