@use 'sass:list';
@use 'sass:map';
@use 'sass:math';
@use 'sass:string';
@use '../../config/configuration';
@use '../../config/scss-tokens';
@use './strings';

$svg-images: ();
$svg-placeholders: ();

// Public function to inject an svg path directly, see the svg.md readme
// stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
@function svg-url($name, $fill: scss-tokens.$rc-color-text-primary, $rendering: configuration.$svg-rendering, $preserveaspectratio: 'defer') {
  @if map.has-key($svg-images, $name) == false {
    @error 'Invalid svg key: #{$name}';
  }

  $icon: map.get($svg-images, $name);
  $width: map.get($icon, width);
  $height: map.get($icon, height);

  $path: "<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 #{$width} #{$height}' width='#{$width}px' height='#{$height}px' preserveAspectRatio='#{$preserveaspectratio}' shape-rendering='#{$rendering}'><g fill='#{$fill}'>#{map.get($icon, path)}</g></svg>";

  @return _svg-encode($path);
}

// SVG background support
// Everything south of here are internal functions
@function _svg-encode($svg) {
  $encoded: '';
  $slice: 200;
  $index: 0;
  $loops: math.ceil(math.div(string.length($svg), $slice));
  @for $i from 1 through $loops {
    $chunk: string.slice($svg, $index, $index + $slice - 1);
    $chunk: strings.str-replace($chunk, '"', '\'');
    $chunk: strings.str-replace($chunk, '<', '%3C');
    $chunk: strings.str-replace($chunk, '>', '%3E');
    $chunk: strings.str-replace($chunk, '&', '%26');
    $chunk: strings.str-replace($chunk, '#', '%23');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  // stylelint-disable-next-line string-quotes -- Need double quotes for interpolation
  @return url("data:image/svg+xml;charset=utf8,#{$encoded}");
}

@function icon-width-ratio($name) {
  $icon: map.get($svg-images, $name);
  $width: map.get($icon, width);
  $height: map.get($icon, height);

  @return math.div($width, $height);
}

// Deprecated below

@mixin register-svg($name, $width, $height, $path) {
  $new-icon: (
    $name: (
      width: $width,
      height: $height,
      path: $path
    )
  );

  $svg-images: map.merge($svg-images, ($new-icon)) !global;
}

// stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
@mixin svg-background-image($icon, $fill: scss-tokens.$rc-color-text-primary) {
  $placeholder-name: '-svg-#{$icon}-#{$fill}';
  $placeholder-name: strings.str-replace($placeholder-name, '#', '');


  @if list.index($svg-placeholders, $placeholder-name) == null {
    $svg-placeholders: list.append($svg-placeholders, $placeholder-name) !global;

    @at-root {
      %#{$placeholder-name} {
        background-image: svg-url($icon, $fill);
      }
    }
  }

  @extend %#{$placeholder-name};
}
