.avatar {
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 50%;

  img {
    width: 100%;
    height: auto;
  }

  &.avatar-large {
    width: 64px;
    height: 64px;

    @include mobile {
      width: 128px;
      height: 128px;
    }
  }

  &.avatar-messages {
    border-radius: 3px;
    display: block;

    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 3px;
    }

    &:not(.img-hoverable) {
      &:hover { opacity: 1; }
    }
  }

  &.avatar--dashboard {
    @include clearfix;
    position: relative;
    border-radius: $input-border-radius;
    display: block;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .upload-your-photo {
      bottom: 9px;
      width: 100%;
    }
  }

  &:hover { opacity: 0.9; }
}

.shop-summary-avatar {
  @include scaling(margin-bottom, 5px);

  &:hover {
    opacity: 0.85;
  }

  img {
    width: 50px;
    height: auto;
    border-radius: 50%;
  }
}
