@use '@reverbdotcom/cadence/styles/tools/mixins/links';
@use '@reverbdotcom/cadence/styles/tools/mixins/box-shadow' as shadows;
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.messages-index {
  border: none;
  padding: 0;
}

.messages-index__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.conversation {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: var(--rc-space-8) 0;
  gap: var(--rc-space-5);

  @include mobile {
    padding: var(--rc-space-4) 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  + .conversation {
    border-top: 0.1rem solid var(--rc-color-border-primary);
  }
}

.conversation__message {
  flex: 1;

  // this should be removed once the HAML view is updated
  .opacity-70.mb-1.size-80.color-gray {
    opacity: 1;
    color: var(--rc-color-text-secondary);
    font-size: 1.4rem;
    margin-bottom: var(--rc-space-2);
  }

  // the user name
  .mt-1.size-80 {
    font-size: 1.4rem;
  }

  // the message title link
  > a {
    color: var(--rc-color-text-textlink);
  }
}

.conversation__thumb {
  flex: 0 0 7rem;
  border-radius: var(--rc-border-radius-md);
  background-color: var(--rc-color-palette-gray-100);
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}

.conversation__subject {
  @include rc-text.default;
  @include hyphenate;
  @include links.text-link;

  .conversation--unread & {
    font-weight: var(--rc-font-weight-bold);
  }
}
