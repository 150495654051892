@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

[data-feedback-input-hint] {
  font-size: 80%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

[data-feedback-input]:focus + [data-feedback-input-hint] {
  opacity: 1;
}

.tab-pane.tab-pane--feedback {
  padding: 0;

  .heading-3 {
    @include rc-text.title('500');
  }

  ul li.feedback {
    border-radius: 4px;
    border: 2px solid var(--rc-color-palette-gray-200);
    padding: 16px 18px;
    margin-bottom: 1.5rem;

    .cell {
      display: block;
      float: left;
      &.successful-feedback { width: 100%; }
      &.feedback {
        width: 280px;
        @include mobile {
          width: 100%;
        }
      }
      &.product { width: 110px; }
      &.details {
        width: 150px;
        padding-right: 10px;
        margin-bottom: 1rem;
        p.title { line-height: 1.2em; }
        @include mobile { width: 425px; }
      }
      &.form {
        width: 366px;
        @include mobile {
          width: 100%;
        }
      }
    }
    .user-feedback {
      border: 0px none;
      padding: 0;
    }
  }
}
