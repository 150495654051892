@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.newsletter-signup-form {
  background: url('../images/icons/gear-icon-tile.png') repeat;
  border-top: 1px solid var(--rc-color-border-primary);

  @include mobile {
    text-align: center;
  }
}

.newsletter-signup-form__inner {
  @include site-wrapper;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletter-signup-form__content {
  padding: 2em;

  h3 {
    font-weight: bold;
    font-size: 1.2em;
  }

  p {
    margin-bottom: 1em;
  }
}

.newsletter-signup-form__email-input {
  display: inline-block;
  vertical-align: top;
  min-width: 20rem;

  @include tablet {
    display: block;
    margin-bottom: 1rem;
  }
}

.newsletter-signup-form__subscribe {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;

  @include tablet {
    display: block;
    margin: 0;
  }
}

.newsletter-signup-form__subscribe__button {
  @include rc-button.filled;

  @include tablet {
    width: 100%;
  }
}

.newsletter-signup-form__images {
  overflow: hidden;
  flex: 0 0 40rem;
  position: relative;
  height: 30rem;

  @include mobile {
    display: none;
  }

  img {
    position: absolute;
    height: 90%;
    width: auto;

    &:last-child {
      left: 6%;
      bottom: -5%;
      transform: rotate(-7deg);
      box-shadow: 1px 1px 3px var(--rc-color-palette-gray-300);
    }

    &:first-child {
      right: 6%;
      bottom: -3%;
      transform: rotate(6deg);
    }
  }
}

