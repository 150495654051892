@use '@reverbdotcom/cadence/styles/tools/mixins/svg';

.cart-payment-methods__titleblock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.cart-payment-methods__title {
  font-size: 2.2rem;
  font-weight: bold;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.cart-payment-methods__protection {
  display: flex;
  align-items: center;
  margin: 0 0 1rem;
  flex-wrap: wrap;

  &::before {
    @include svg.icon-bg(trust-shield-nudge);
    content: '';
    display: block;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
  }
}

.cart-payment-method {
  position: relative;

  .cart-payment-methods__list & {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }
  }
}

.cart-payment-method__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.cart-payment-method__label {
  border: solid 0.1rem var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-input);
  padding: var(--rc-space-5);
  padding-left: 6rem; // to make room for radio button UI
  cursor: pointer;
  font-weight: normal;
  font-size: 1.6rem;

  &::before {
    content: '';
    display: block;
    box-sizing: border-box;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    border: 0.1rem solid var(--rc-color-border-input-primary);
    transition: border-color 0.2s, background 0.2s, box-shadow 0.2s;
    background-color: var(--rc-color-background-input);
    box-shadow: inset 0 0 0 0.2rem var(--rc-color-background-input);

    position: absolute;
    left: var(--rc-space-5);
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover::before {
    background-color: var(--rc-color-background-input-disabled);
  }

  .cart-payment-method__input:checked + & {
    border-color: var(--rc-color-border-selected);
  }

  .cart-payment-method__input:checked + &::before {
    background-color: var(--rc-color-background-input-checked);
  }
}

.cart-payment-method__titleblock {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .cart-payment-method--rp & {
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.cart-payment-method__title {
  font-size: 1.8rem;
}

.cart-payment-method__icons {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;

  .cart-payment-method--rp & {
    @include mobile {
      margin-top: 1rem;
    }
  }

  .payment-icon {
    width: 4rem;
    margin-left: 1rem;

    @include mobile {
      width: 3rem;

    }

    .cart-payment-method--rp & {
      @include mobile {
        margin-left: 0;
        margin-right: 0.5rem;
      }
    }
  }
}

.cart-payment-method__subtitle {
  font-size: 1.6rem;
  color: var(--rc-color-text-secondary);
  margin: 0.5rem 0 0;

  @include mobile {
    font-size: 1.4rem;
  }
}

.cart-payment-method__credits {
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--rc-color-text-primary);
  display: inline-block;
  border-radius: $input-border-radius;
  background-color: var(--rc-color-sentiment-positive-lighter);
  border: solid 0.1rem var(--rc-color-sentiment-positive-light);
  padding: 0.5rem 1rem;
  margin: 0.5rem 0 0;
}
