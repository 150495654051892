@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.reverb-modal--offer-modal {
  padding-left: 250px;
  min-height: 415px;

  @include mobile {
    padding-left: 0;
    min-height: initial;
  }
}

.offer-modal {
  width: 480px;

  @include tablet {
    width: auto;
  }

  @include mobile {
    width: 100%;
  }

  .price-field {
    @include grid-columns(6, 6, 12);

    @include mobile {
      margin-bottom: 10px;
    }

    input & {
      margin-bottom: 0;
    }
  }

  .shipping-field {
    @include grid-columns(6, 6, 12);
  }
}

.offer-modal__shipping-locked {
  background: var(--rc-color-background-module-secondary);
  border: 0.1rem solid var(--rc-color-palette-gray-200);
  border-radius: 0.4rem;
  padding: 0.6rem;
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0;
  line-height: normal;
}

.offer-modal__trigger {
  @include rc-button.muted($fullwidth: true);
  display: block;

  .product-row-card__actions__secondary__action__on-csp & {
    @include rc-button.muted($size: 'small', $extend: false);
  }
}

.offer-modal__label {
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 4px;
}

.offer-modal__form-row {
  @include grid-container;
}

.offer-modal__form-column {
  @include grid-columns(6, 12);

  & + .offer-modal__form-column {
    @include mobile {
      margin-top: 12px;
    }
  }
}

.offer-modal__select {
  select {
    display: block;
    width: 100%;
    margin: 0;

    // small hack necessary to override legacy styles
    form & {
      margin-bottom: 0;
    }
  }
}

.offer-modal__offer-input {
  margin: 0 0 12px;

  label {
    padding-top: 0; // utility class specificity override
  }
}

.offer-modal__message {
  textarea {
    display: block;
    width: 100%;
  }
}

.offer-modal__disclaimer {
  font-size: 13px;
  color: var(--rc-color-text-secondary);
  margin: 12px 0 0;
}

.offer-modal__terms {
  margin-top: 1.6rem;
}

.offer-modal__checkbox {
  @include checkbox;
  margin-bottom: 0;

  label {
    font-weight: 400;
    margin: 0;
  }
}

.offer-modal__footer {
  @include scaling(margin-top, 2rem);
  text-align: center;
}

.offer-modal__submit-button {
  @include rc-button.filled;
  margin-bottom: 0;
  width: 100%;
}

.offer-modal__alert {
  border-radius: 4px;
  background: var(--rc-color-background-alert-warning);
  padding: 12px;
  line-height: 1.5;
  margin: 0;

  &.offer-modal__alert--success {
    color: var(--rc-color-text-primary);
    background-color: var(--rc-color-background-alert-success);
  }

  &.offer-modal__alert--error {
    color: var(--rc-color-text-danger);
    background-color: var(--rc-color-background-alert-error);
  }
}

.offer-modal__response-timeline {
  color: var(--rc-color-text-secondary);
  margin: 1rem 0 0 0;
}

.offer-modal__product {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  background-color: var(--rc-color-palette-gray-200);
  border-radius: 4px 0 0 4px;

  @include mobile {
    display: none;
    // width: auto;
    // position: static;
    // background-color: transparent;
  }

  // subtle fade effect on bottom of container
  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 28px;
    right: 2rem;
    bottom: 0;
    left: 2rem;
    border-radius: 0 0 0 4px;
    background: linear-gradient(to top, var(--rc-color-palette-gray-200), transparent);
    z-index: 5; // so it sits on top of .offer-modal__product__info
  }
}

.offer-modal__product__info {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2rem;
  overflow-y: auto;
  z-index: 1;

  @include mobile {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: static;
    padding: 0;
    margin-bottom: 1rem;
  }
}

.offer-modal__product__thumb {
  margin-bottom: 1rem;

  @include mobile {
    flex: 0 0 18%;
    margin: 0;
  }

  a {
    display: block;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
}

.offer-modal__product__title {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 1rem;

  @include mobile {
    flex-basis: 76%;
    line-height: 1.25;
    margin: 0;
  }
}

.offer-modal__product__details {
  font-size: 90%;

  @include mobile {
    flex-basis: 100%;
    margin-top: 8px;
  }

  table {
    width: 100%;
  }

  td {
    padding: 4px 0;

    &:first-child {
      font-weight: 700;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.offer-modal__body__meta {
  @include scaling(margin-bottom, 2rem);
}

.offer-modal__body__meta__offer-amt {
  @include scaling(font-size, 18px);
  font-weight: bold;
}

.offer-modal__tax-hint {
  font-size: 90%;
  margin: 0;
}

.offer-modal__body__meta__user-country {
  font-size: 90%;
}

.offer-modal__offer-form {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  label {
    white-space: nowrap;
    margin-bottom: 0.5rem;
  }

  @include tablet {
    display: block;
  }
}

.offer-modal__offer-form__qty-and-price__qty {
  flex: 1;

  .styled-dropdown {
    margin-bottom: 0;
  }
}

.offer-modal__offer-form__qty-and-price__qty__text {
  padding: 6px;
  text-align: center;
}

.offer-modal__offer-form__sign {
  padding: 20px 8px 0;
  text-align: center;
  font-weight: bold;

  &.offer-modal__offer-form__sign--equals,
  &.offer-modal__offer-form__sign--plus {
    @include tablet {
      display: none;
    }
  }
}

.offer-modal__offer-form__qty-and-price {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex: 1 1 auto;

  @include tablet {
    flex-basis: 100%;
    margin-bottom: 10px;
  }
}

.offer-modal__offer-form__qty-and-price__price {
  min-width: 120px;
  width: 100%;
}

.offer-modal__offer-form__shipping {
  box-sizing: border-box;
  flex: 1 1 auto;

  @include tablet {
    margin-bottom: 10px;
  }
}

.offer-modal__offer-form__shipping__input {
  box-sizing: border-box;
  width: 100%;

  &:disabled {
    background: var(--rc-color-background-module-secondary);
    font-weight: bold;
    color: var(--rc-color-text-primary);
  }
}

.offer-modal__offer-form__total {
  flex: 1 1 auto;
}

form input.offer-modal__offer-form__total__input[disabled='disabled'],
form input.offer-modal__offer-form__total__input[disabled] {
  color: var(--rc-color-text-primary);
  font-weight: bold;
}
