.page-search {
  // nested so styles override `form input[type='text'] selector`
  .page-search__input {
    border: 0.1rem solid var(--rc-color-border-primary);
    color: var(--rc-color-text-primary);
    border-radius: 0.6rem 0 0 0.6rem;
    font-weight: bold;
    height: 5rem;
    width: 35rem;
    font-size: 2rem;
    color: var(--rc-color-text-primary);
    font-weight: normal;
    padding: 0.5em;
    margin: 0;

    @include mobile {
      width: 21rem;
      font-size: 1.6rem;
    }
  }

  &.page-search--small {
    .page-search__input {
      width: 21rem;
      height: 4rem;
      font-size: 1.6rem;
    }
  }

  &.page-search--wide {
    width: 100%;

    form {
      display: flex;
      width: 100%;
    }

    .page-search__input {
      width: auto;
      flex: 1;
      min-width: 0;
    }
  }
}

.page-search__inner {
  display: flex;

  @include mobile {
    justify-content: center;
  }
}

.page-search__submit {
  @include button-reset;
  @include background-gradient(var(--rc-color-palette-bw-white), var(--rc-color-background-page-secondary), 0%, 100%);
  background: var(--rc-color-palette-bw-white);
  vertical-align: middle;
  box-sizing: border-box;
  border: 0.1rem solid var(--rc-color-border-primary);
  border-left: none;
  color: var(--rc-color-text-primary);
  border-radius: 0 0.6rem 0.6rem 0;
  font-weight: bold;
  line-height: 2rem;
  height: 5rem;
  padding: 1.5rem 2rem;
  cursor: pointer;
  font-size: 1.6rem;
  margin: 0;

  &:hover {
    @include background-gradient(var(--rc-color-palette-bw-white), var(--rc-color-palette-gray-100), 0%, 100%);
  }

  .page-search--small & {
    height: 4rem;
    font-size: 1.4rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .page-search--wide & {
    flex: 0 0 auto;
  }

  .page-search--orange-button & {
    background: var(--rc-color-palette-vu-orange-500);
    color: var(--rc-color-palette-bw-white);
    border: 0.1rem solid var(--rc-color-palette-vu-orange-600);

    &:hover {
      background: var(--rc-color-palette-vu-orange-600);
    }
  }

  @include mobile {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
