.statement-stats__title {
  font-size: 1.4rem;
  text-transform: uppercase;
}

.statement-stats__data {
  font-size: 2rem;
  @include mobile { font-size: 1.6rem; }
}

.statement-stats__sub-stat {
  display: inline-block;
  margin-right: 1rem;
}

.statement-stats__sub-stat__title {
  font-size: 0.8em;
  margin-top: 0.4rem;
  color: var(--rc-color-palette-gray-500);
}
