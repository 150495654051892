.sale-list-wrapper {
  box-sizing: border-box;
  transition: background-color .2s ease-in-out;

  @include responsive-hover {
    background-color: var(--rc-color-palette-gray-200);
  }
}

.sale-list {
  display: flex;
  padding: 1rem;
  justify-content: flex-end;

  @include tablet {
    padding: 0;
    justify-content: flex-start;
  }
}

.sale-list__item {
  @include scaling(font-size, 1.4rem);

  flex: 1;
  max-width: 14rem;
  margin-right: 1rem;
  background-color: var(--rc-color-palette-bw-white);
  border: 0.1rem solid var(--rc-color-palette-gray-200);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @include mobile {
    max-width: none;
  }

  &:last-child {
    margin-right: 0;
  }
}

.sale-list__item__count {
  padding: 1rem;

  .number {
    @include scaling(font-size, 2rem);
  }
}
