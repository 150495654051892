.found-on-reverb {
  @include clearfix;
  @include scaling(padding-bottom, 2rem);
  @include scaling(padding-top, 2rem);
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
  text-align: right;

  @include mobile {
    text-align: left;
  }

  &.found-on-reverb--header {
    text-align: left;
    padding-top: 0;
  }
}

.found-on-reverb__image {
  position: relative;
  width: 60%;
  float: right;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  @include mobile {
    width: 100%;
    float: none;
  }

  .found-on-reverb--header & {
    @include responsive(float, left, $mobile: none);
  }
}

.found-on-reverb__image__logo {
  position: absolute;
  top: 35.7%;
  left: 90%;
  width: 70%;

  img {
    height: auto;
    transform: translate(-44%, -54%);
  }

  @include mobile {
    left: 50%;
    width: 90%;

    img {
      transform: translate(-50%, -54%);
    }
  }
}

.found-on-reverb__content {
  position: relative;
  box-sizing: border-box;
  width: 40%;
  float: left;
  padding: 2rem 4rem 0 4rem;
  font-size: 90%;

  @include mobile {
    width: auto;
    padding: 10px;
    font-size: 80%;
  }

  .found-on-reverb--header & {
    @include responsive(float, right, $mobile: none);
    @include responsive(margin-top, 18%, $mobile: 0);

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 150px;
      height: 4px;
      background: var(--rc-color-palette-vu-orange-500);
      top: 3em;
      left: -120px;

      @include mobile {
        display: none;
      }
    }
  }

  .video-embed {
    margin: 0;
  }
}

.found-on-reverb__content__title {
  transform: translateX(7rem);
  color: var(--rc-color-palette-vu-orange-500);
  text-transform: uppercase;
  font-size: 70px;
  font-weight: bold;
  line-height: 1.2;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 150px;
    height: 4px;
    background: var(--rc-color-palette-vu-orange-500);
    bottom: -20px;
    right: -80px;
  }

  @include mobile {
    font-size: 30px;
    transform: none;

    &:after {
      display: none;
    }
  }
}
