.shipping-label-address {
  @include breakpoint-tablet-up {
    min-height: 20rem;
  }
}

.shipping-label__checkbox-hint {
  font-size: 1.3rem;
  padding-left: 2.1rem; // to align with checkbox label
}

.shipping-label__checkbox-label {
  margin-bottom: 0;
}
