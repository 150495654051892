@use '../../config/configuration' as config;

// This mixin is designed to support single AND multi-line CSS truncation
// For browsers that support it, we're going to use -webkit-box
// to get CSS-only multi-line "..." truncation.
// https://css-tricks.com/line-clampin and https://caniuse.com/?search=line-clamp
@mixin truncate-text(
  $lines-to-show: 1,
  $font-size: 1em,
  $line-height: config.$body-line-height,
  $fixed-height: false,
) {
  display: block; // Fallback for the rare non-webkit-supported browsers
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-to-show;
  font-size: $font-size;
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;

  #{if($fixed-height == true, 'height', 'max-height')}:
    calc(#{$font-size} * #{$line-height} * #{$lines-to-show});
}
