a.youtube-videogallery-link {
  display: inline-block;
  position: relative;
}

a.youtube-videogallery-link:hover img.youtube-videogallery-play,
a.youtube-videogallery-link:focus img.youtube-videogallery-play {
  opacity: 1;
}

.youtube-videogallery-play {
  left:50%;
  position: absolute;
  top:45%;
  z-index: 1;
}

.youtube-videogallery-allowtitle .youtube-videogallery-title {
  height: auto;
}

.youtube-videogallery-screen-reader-only {
  position: absolute;
  left:-9999px;
}

.youtube-videogallery-bodycover {
  background-color: #000;
  height: 100%;
  left:0;
  opacity: 0;
  position: absolute;
  top:0;
  width:100%;
  z-index: 100;
}

.youtube-videogallery-display {
  background-color: #000;
  border-radius: 5px;
  box-shadow: 3px 3px 7px #000;
  left:50%;
  opacity: 0;
  padding: 3px;
  position: fixed;
  top:50%;
  z-index: 101;
}

.youtube-videogallery-bodycover,
.youtube-videogallery-display {
  transform: scale(0);
  -webkit-transform: scale(0);
}

.youtube-videogallery-active .youtube-videogallery-bodycover,
.youtube-videogallery-active .youtube-videogallery-display {
  display: block;
  transform: scale(1);
  -webkit-transform: scale(1);
}

.youtube-videogallery-active .youtube-videogallery-bodycover {
  opacity: 0.5;
}

.youtube-videogallery-active .youtube-videogallery-display {
  opacity: 1;
}
