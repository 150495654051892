.col-order-show-img {
  @include grid-column(2);

  @include mobile {
    @include grid-column(12);
  }
}

.col-order-show-details {
  @include grid-column(10);

  @include mobile {
    @include grid-column(12);
  }
}

.col-order-contact {
  @include grid-column(3);

  @include mobile {
    @include grid-column(12);
  }
}
