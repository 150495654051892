.storefront-tabs {
  display: flex;
  margin-bottom: 28px;

  @include mobile {
    margin-bottom: 9px;
  }
}

.storefront-tab {
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: var(--rc-color-text-secondary);
  margin-right: 32px;

  @include mobile {
    margin-right: 14px;
  }

  &:last-child {
    margin: 0;
  }

  &::before {
    display: block;
    content: '';
    height: 24px;
    margin-right: 8px;
    filter: saturate(0);
    flex: 0 0 auto;
    background: center center no-repeat;
    background-size: cover;
  }

  &.storefront-tab--reverb {
    &::before {
      width: 24px;
      background-image: url('../images/dashboard/storefront-icon-reverb.svg');
    }

    &:hover,
    &.storefront-tab--active {
      color: var(--rc-color-palette-vu-orange-500);

      &::before {
        filter: saturate(100%);
      }
    }
  }

  &.storefront-tab--lp {
    &::before {
      width: 34px;
      background-image: url('../images/dashboard/storefront-icon-lp.svg');
      opacity: 0.7;
    }

    &:hover,
    &.storefront-tab--active {
      color: var(--rc-color-palette-blue-600);

      &::before {
        filter: saturate(100%);
        opacity: 1;
      }
    }
  }
}
