// THESE ARE GENERALLY LEGACY MIXINS that are unnecessary / superseded by design system
// pls don't use

// Gradient mixins
@mixin background-gradient($start, $end, $start-stop: 0%, $end-stop: 100%) {
  background: linear-gradient(to bottom, $start $start-stop, $end $end-stop);
}

// Retina images
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    // on retina, use image thats scaled by 2
    background-image: url($image);
    background-size: $width $height;
  }
}

// Fluid background image - use http://petercollingridge.appspot.com/svg-editor to compress svgs
@mixin fluid-bg-image($image-width, $image-height) {
  display: block;
  width: 100%;
  background-size: contain;
  padding-bottom: percentage($image-height / $image-width); // height divided by width to get correct ratio
}

@mixin legacy-button(
  $background-color,
  $background-color-hover,
  $text-color,
  $border-color,
) {
  color: $text-color;
  border: 0.1rem solid $border-color;
  background: $background-color;
  filter: none; // to remove any previously set gradients in IE

  &:hover {
    background: $background-color-hover;
    border: 0.1rem solid $background-color-hover;
    color: $text-color;
  }

  &.btn-disabled,
  &.btn-loading {
    opacity: 0.6;
    cursor: default;

    &:hover {
      background: $background-color;
      border-color: $border-color;
    }
  }
}

@mixin social-button($icon-color) {
  color: var(--rc-color-palette-bw-white);
  border: 0.1rem solid var(--rc-color-palette-gray-800);
  background: var(--rc-color-text-secondary);
  filter: none; // to remove any previously set gradients in IE

  &:hover {
    background: $icon-color;
    border: 0.1rem solid $icon-color;
  }

  &.no-icon-bg {
    color: var(--rc-color-text-secondary);
    background: none;
    border: none;

    &.gear-story-share {
      color: var(--rc-color-palette-bw-white);
    }

    &:hover {
      background: none;
      color: $icon-color;
    }
  }
}

@mixin button-simple($background-color) {
  color: $background-color;
  border: 2px solid $background-color;
  background: none;
  filter: none;
  padding-top: 8px;
  padding-bottom: 8px;

  &.btn-simple--lightweight {
    border-color: var(--rc-color-border-primary);
    font-weight: normal;
  }

  @include breakpoint-tablet-up {
    &:hover {
      color: var(--rc-color-palette-bw-white);
      border: 2px solid $background-color;
      background: $background-color;

      &.btn-simple--lightweight {
        background: inherit;
        color: $background-color;
      }
    }
  }

  &.btn-disabled,
  &.btn-loading {
    opacity: 0.6;
    cursor: default;
  }
}
