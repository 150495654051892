.radio-checkbox-selector {
  position: relative;
  margin-bottom: 10px;

  label {
    @include clearfix;
    border-radius: 6px;

    background: var(--rc-color-palette-gray-100);
    border: 2px solid var(--rc-color-palette-gray-200);
    font-weight: inherit;
    padding-left: 27px;
  }

  input[type=checkbox],
  input[type=radio] {
    left: 9px;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    &:not([disabled]) ~ label {
      cursor: pointer;
      &:hover {
        background-color: var(--rc-color-palette-gray-200);
        border-color: var(--rc-color-palette-gray-300);
      }
    }
    &:checked ~ label,
    &:checked ~ label:hover {
      background-color: var(--rc-color-palette-blue-300);
      border-color: var(--rc-color-palette-blue-300);
    }
  }

}
