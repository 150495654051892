// Overrides to customize fotorama
#gallery,
.pg-image-gallery {
  .fotorama__stage__shaft,
  .fotorama__stage__frame {
    background: var(--rc-color-palette-gray-100);
    box-shadow: inset 0 0 30px 10px rgba(0, 0, 0, 0.05);

    img {
      cursor: zoom-in;
    }
  }
}

.fotorama__nav--thumbs { padding: 9px 0; }

.fotorama__thumb { border-radius: 3px; }

.fotorama--fullscreen {
  .fotorama__nav--thumbs {
    margin-top: 0;
    padding-top: 0;
  }
}

.fotorama__wrap--css3 .fotorama__stage__frame .fotorama__img {
  margin: 0;
  transform: translateX(-50%) translateY(-50%) !important;
}

// Don't show default active thumbnail overlay, use opacity instead
.fotorama__thumb-border {
  display: none;
}

.fotorama__nav__frame--thumb {
  opacity: 0.6;

  &:hover { opacity: 1; }
  &.fotorama__active { opacity: 1; }
}

.fotorama__nav--thumbs .fotorama__nav__frame {
  padding-left: 3px !important;
}
.fotorama--fullscreen {
  background: rgba(0,0,0,0.8);
}

.fotorama--fullscreen .fotorama__nav,
.fotorama--fullscreen .fotorama__stage {
  background: none;
}

// Background image
.fotorama__arr,
.fotorama__fullscreen-icon,
.fotorama__video-close,
.fotorama__video-play {
  background: url('../images/top-level/fotorama-custom.png') no-repeat;
  @include image-2x("../images/top-level/fotorama-custom@2x.png", 274px, 90px);
}

.fotorama__loaded {
  .fotorama__video-play,
  .fotorama__video-close,
  .fotorama__nav__frame .fotorama__video-play,
  .fotorama--fullscreen .fotorama__fullscreen-icon {
    opacity: 0.8;
  }
}

// Navigation
.fotorama__arr {
  position: absolute;
  width: 48px;
  height: 48px;
  top: 50%;
  margin-top: -30px;
}

.fotorama__arr--prev,
.fotorama__arr--next {
  opacity: 0.6;

  &:hover { opacity: 1; }

  &.fotorama__arr--disabled {
    opacity: 0; // Don't show nav control if you can't navigate more that direction
  }
}

.fotorama__arr--prev { background-position: 0 0; }
.fotorama__arr--next { background-position: -48px 0; }

// Close buttons
.fotorama__video-close,
.fotorama--fullscreen .fotorama__fullscreen-icon {
  width: 116px;
  height: 42px;
  top: 10px;
  right: 10px;
  background-position: 0 -48px;

  &:hover { opacity: 1; }
}

.fotorama__fullscreen-icon { display: none; }

.fotorama--fullscreen {
  .fotorama__fullscreen-icon {
    display: block;
  }
}

// Video Controls
.fotorama__wrap--css3 .fotorama__video-close {
  transform: translate3d(126px, -52px, 0);
}

.fotorama__video-play {
  width: 90px;
  height: 90px;
  background-position: -116px 0;

  &:hover { opacity: 1; }
}

.fotorama__nav__frame {
  .fotorama__video-play {
    width: 40px;
    height: 40px;
    margin: -20px 0 0 -19px;
    background-position: -114px 1px;
    background-size: 152px 50px;
    opacity: 0.8;

    &:hover { opacity: 1; }
  }
}

.fotorama__stage__frame--video {
  background: black;
}

// override for video control
#gallery, .gallery {
  .fotorama__wrap--video .fotorama__video-close { display: none; }
}

.pg-image-gallery {
  min-height: 400px;
}

.listing-page-gallery {
  transform: translateZ(0);
  animation: loadingPlaceholderGradient 1.3s ease 7;
  background: linear-gradient(270deg, var(--rc-color-palette-gray-200), var(--rc-color-palette-gray-100));
  background-size: 400% 400%;
  min-height: 300px;
}

.listing-lightbox-gallery {
  border: 0.1rem solid var(--rc-color-border-primary);

  background: white;
  min-height: 300px;
  margin-bottom: 2rem;

  // overriding commons styles
  .lightbox-image__primary {
    border: none;
  }

  .lightbox-image__thumbs {
    margin: 0;
  }
}

.fotorama__nav-wrap {
  background: var(--rc-color-palette-bw-white);
  border-radius: 0 0 3px 3px;
}

.fotorama > * {
  visibility: hidden;
}

.fotorama > .fotorama__wrap {
  visibility: visible;
}
