@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.blog-social-list {
  margin-bottom: 24px;

  a {
    display: block;
    padding: 10px;
    color: var(--rc-color-palette-gray-500);
    transition: color 0.2s;

    &.fa-facebook:hover {
      color: var(--rc-color-vendor-facebook);
    }

    &.fa-twitter:hover {
      color: var(--rc-color-vendor-twitter);
    }

    &.fa-instagram:hover {
      color: var(--rc-color-vendor-instagram);
    }

    &.fa-youtube:hover {
      color: var(--rc-color-vendor-youtube);
    }
  }
}

.blog-widget {
  overflow: hidden;
  margin: var(--rc-space-10) 0;

  h3 {
    @include rc-text.title('700');
    padding-bottom: var(--rc-space-6);
  }

  &.blog-widget--facebook {
    border-top-color: var(--rc-color-vendor-facebook);
  }
}

.blog-widget__facebook {
  iframe[style] {
    width: 100%;
  }
}
