[data-is-admin='true'] {
  [data-admin-message] {
    &:before {
      display: none;
      content: attr(data-admin-message);
      position: fixed;
      bottom: 4em;
      right: 0.5em;
      background: var(--rc-color-sentiment-negative-dark);
      padding: 0.3em 0.5em;
      font-size: 11px;
      font-weight: bold;
      max-width: 300px;
      color: var(--rc-color-palette-bw-white);
      z-index: 16000003; // render above zopim

      @include mobile {
        left: 0;
        bottom: 0;
        right: 0;
      }
    }

    &:hover {
      outline: 10px dashed var(--rc-color-sentiment-negative-lighter);
      &:before {
        display: block;
      }
    }
  }
}
