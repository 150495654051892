@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

// this is necessary because AjaxMultiAddToCartButton and a couple
// other components are nested too deeply to pass through props
// just to adjust font size. Worth refactoring when the components
// themselves are.

.add-to-cart-button {
  @include rc-button.filled;

  width: 100%;

  .product-card &,
  .upsell-card &,
  .reverb-modal & {
    font-size: 1.2rem;
  }
}
