.horizontal-nav {
  @include clearfix;
  font-size: 0.9em;

  & > ul:not(.dashboard-top-nav) {
    float: left;

    & > li {
      float: left;
      margin-left: 15px;

      &:first-child {
        margin-left: 0px;
      }
    }
  }

  .dashboard-nav-container & {
    & > ul {
      display: flex;
      float: none;
      justify-content: space-between;

      & > li {
        float: none;
        margin: 0 0.5rem;
        white-space: nowrap;
      }
    }
  }
}
