.multi-order-summary {
  padding: 2rem 1rem;
  border-left: none;
  border-right: none;

  &.multi-order-summary--index {
    border-top: 0.1rem solid var(--rc-color-border-primary);
    padding: 1rem;
  }

  // site-modules are already padded so we need to tweak to get
  // the left and right sides to be flush
  .site-module & {
    padding-left: 0;
    padding-right: 0;
  }
}

.multi-order-summary__title {
  @include fa-icon-before('shopping-cart', $margin-right: 0.25rem);
  margin: 0 0 0.5rem;
}

.multi-order-summary__orders {
  .multi-order-summary--index & {
    display: flex;
    margin: -0.5rem;
  }
}

.multi-order-summary__textlink {
  display: block;
  border: solid 0.1rem var(--rc-color-border-primary);
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border-radius: 0.4rem;
  background-color: var(--rc-color-background-page);
  color: var(--rc-color-text-primary);
}

.multi-order-summary__order {
  display: block;
  border: solid 0.1rem var(--rc-color-border-primary);
  border-radius: 1.6rem;
  padding: 1rem;
  display: flex;
  margin: 0.5rem 0 0;
  width: 50%;
  color: var(--rc-color-text-primary);

  &:hover {
    color: var(--rc-color-palette-blue-600);
    border-color: var(--rc-color-palette-gray-500);
  }

  @include mobile {
    width: 100%;
  }
}

.multi-order-summary__order__thumbnail {
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 0.2rem;
}

.multi-order-summary__order__info {
  flex: 1;
  margin-left: 1rem;
  font-size: 1.4rem;
}

.multi-order-summary__order__titleblock {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.multi-order-summary__order__title {
  font-weight: bold;
}

.multi-order-summary__order__id {
  margin: 0.5rem 0 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
}

.multi-order-summary__order__price {
  margin-left: 2rem;
}
