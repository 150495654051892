.percent-label {
  margin-left: 4px;
}

.tax-hint {
  background: #efefef;
}

.span-tax {
  width: 75px;
  width: (75/14) + rem;
  @media screen and (max-width: 640px) {
    margin-left: 0 !important;
  }
}

form .span-tax input.span0 {
  width: 40px;
  width: (40/14) + rem;
}

.break-word {
  word-break: break-all;
  word-break: break-word;
}
