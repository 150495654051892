.img-hoverable {
  transition: opacity .1s;

  &:hover {
    opacity: 0.85;
  }
}

.img-wide {
  width: 100%;
  height: auto;
  display: block;
}
