@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/links';
@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.offer-card {
  background-color: var(--rc-color-background-module);
  border: solid 0.1rem var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-lg);

  .offer-cards & {
    margin-bottom: var(--rc-space-8);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .shop-icon-link {
    @include links.text-link;
    @include svg.icon-before(shop, scss-tokens.$rc-color-text-primary);

    &:before {
      margin-right: var(--rc-space-half);
    }

    &:hover::before {
      margin-right: var(--rc-space-half);
    }
  }
}

.offer-card__header,
.offer-card__body,
.offer-card__negotiation,
.offer-card__links {
  padding: var(--rc-space-4) var(--rc-space-4) 0;
}

.offer-card__header {
  display: flex;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }
}

.offer-card__titleblock {
  display: flex;
  font-size: 1.6rem;
  flex-wrap: wrap;

  // specificity needed here to get the legacy PreviousOrders React component
  // working in this context without a rewrite/overhaul
  [data-react-class='Reverb.PreviousOrders'] {
    display: block;

    .inline-block {
      display: block;
    }

    .repeat-customer-badge {
      display: block;
      margin-right: 1rem;
      margin-bottom: 0.5rem;
    }

    .fa-tags {
      margin-left: 0.2em;
    }
  }
}

.offer-card__title {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.offer-card__tags {
  display: flex;
  flex-wrap: wrap;
}

.offer-card__tag {
  display: block;
  font-size: 1.2rem;
  background-color: var(--rc-color-palette-gray-300);
  border-radius: $input-border-radius;
  padding: 0.2rem 0.6rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;

  &.offer-card__tag--direct-offer {
    @include fa-icon-before(rotate-right, 0.25em);
  }

  &.offer-card__tag--email-offer {
    @include fa-icon-before(envelope-o, 0.25em);
  }

  &.offer-card__tag--reverb-sites {
    @include fa-icon-before(desktop, 0.25em);
  }

  &.offer-card__tag--lp {
    @include fa-icon-before(music, 0.25em);
  }
}

.offer-card__location {
  color: var(--rc-color-text-secondary);
  display: block;
}

.offer-card__state {
  color: var(--rc-color-text-secondary);
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  flex-shrink: 0;
  line-height: 1.5;
  text-align: right;

  @include mobile {
    text-align: left;
    margin-top: 0.5rem;
  }

  &.offer-card__state--active {
    color: var(--rc-color-text-success);
  }

  &.offer-card__state--accepted {
    color: var(--rc-color-text-success);
  }

  &.offer-card__state--rejected {
    color: var(--rc-color-text-danger);
  }
}

.offer-card__negotiation {
  .offer {
    background-color: transparent;
  }
}

.offer-card__footer {
  padding: var(--rc-space-4);

  li {
    display: inline-block;
    margin: 0 0.5rem 0.5rem 0;

    @include mobile {
      margin: 0 0 0.5rem;
      width: 100%;
    }
  }

  .button {
    @include rc-button.muted($size: 'mini');
  }
}
