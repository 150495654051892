@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.address-form__header {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 1em;
}

.address-form__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1em;
  width: 100%;

  @include mobile {
    display: block;

    .rc-button {
      width: 100%;
    }
  }
}

.address-form__actions__options-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: var(--rc-space-3);

  @include mobile {
    flex-direction: column;
    margin-top: var(--rc-space-3);
  }
}
