.bottom-similar-module { background: var(--rc-color-palette-gray-300); }

.bottom-related-content { padding: 36px 0; }

.related-content-overlay-link {
  display: block;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 1;
}
