.payouts-bucks-callout {
  background: url('../images/icons/reverb-bucks-icon-grey.svg') 5% center no-repeat;
  padding-left: 20%;
  background-size: 10% auto;

  .update-shop-settings {
    margin-right: 1em;
  }

  @include mobile {
    background: none;
    padding: 0 1em;
    text-align: center;

    .update-shop-settings {
      display: block;
      margin-right: 0;
    }
  }
}

.pay-out-to-bucks-tips {
  li {
    &:first-of-type {
      margin-bottom: 0.5em;
    }
  }
}

.payout-header {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.payout-header__line {
  margin-bottom: 1.5rem;

  &:nth-child(even) {
    &:before {
      content: '';
      display: block;
      width: 85%;
      height: 1px;
      border-top: 1px solid var(--rc-color-border-primary);
      margin: 0 auto;
      padding-bottom: 1.5rem;
    }
  }
}

.transaction-table {
  margin: 0 auto;
  width: 100%;

  tr {
    border-bottom: 1px solid var(--rc-color-border-primary);
  }

  td,
  th {
    padding: 10px 5px;
  }

  th {
    font-weight: bold;
  }

  td {
    vertical-align: top;
  }
}

.transaction-table__date {
  @include breakpoint-tablet-up {
    width: 60px;
  }
}

.transaction-table__order {
  @include breakpoint-tablet-up {
    width: 50px;
  }
}

.transaction-table__table-row {
  cursor: pointer;
}

.transaction-table__breakdown {
  margin-top: 0.5rem;

  td {
    padding: 5px 0;

    &.pt-0 {
      padding-top: 0;
    }
  }

  tr {
    border-bottom: none;

    &.transaction-table__breakdown__row--border-top {
      border-top: 1px solid var(--rc-color-border-primary);

      td {
        padding-top: 7px;
      }
    }
  }
}

.transaction-table__toggler {
  color: var(--rc-color-text-primary);
  cursor: pointer;
  font-size: 1.5em;
  line-height: 1em;
  width: 0.5em;

  &:hover {
    color: var(--rc-color-palette-vu-orange-500);
  }
}
