.video-gallery {
  @include mobile {
    .mobile-search { display: none; }
  }

  .container {
    background-color: var(--rc-color-palette-bw-white);
    padding-top: 1px; // this is a hack to keep this page working without rewriting it entirely
  }
}

.video-playlist-section {
  @include scaling(padding-top, 30px);
  min-height: 250px;

  &:first-of-type {
    padding-top: 10px;
  }
}

.video-playlist-title {
  font-weight: bold;
  font-size: 24px;
  vertical-align: middle;
  color: var(--rc-color-text-primary);
}

.video-see-all {
  margin-top: 8px;
}

.video-playlist-link:hover {
  .video-playlist-title { color: var(--rc-color-palette-vu-orange-500); }
  .video-playlist-icon {
    opacity: 0.85;
  }
}

.video-playlist-icon {
  height: 50px;
  width: auto;
  vertical-align: middle;
  display: inline-block;
}
