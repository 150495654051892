.transaction-history__footer {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.transaction-history__footer__hint {
  flex: 1;
  display: flex;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 13px;
}

.transaction-history__footer__nav {
  display: flex;
  justify-content: flex-end;
}
