
.user-conversation {
  border-top: solid 0.1rem var(--rc-color-palette-gray-200);
  padding: 38px;

  @include tablet {
    padding: 38px 0;
  }

  @include mobile {
    padding: 0;
    border: none;
  }

  .offer-message {
    @include mobile {
      margin-bottom: 1rem;
    }
  }
}
