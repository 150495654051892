// this subclass is generated by modal.tsx via a subClass prop of 'cart-upsell'.
// getting us off of concatenated class names when that component moves to frontend
// is a worthwhile task

.reverb-modal {
  &.reverb-modal--cart-upsell {
    width: 80vw;
    max-width: 70rem;

    @include mobile {
      width: auto;
      max-width: none;
    }
  }
}
