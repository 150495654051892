.bump-icon-arrows {
  width: 58px;
  height: 46px;
  fill: var(--rc-color-text-primary);
  vertical-align: top;

  @include mobile {
    width: 93px;
    height: 93px;
  }
}

.reverb-direct-checkout-icon {
  width: 13.8rem;
  height: 2rem;
  display: inline-block;
  background: center url('../images/icons/reverb-payments-icon.svg') no-repeat;
  background-size: contain;

  @include mobile {
    width: 27.6rem;
    height: 4rem;
  }
}

.bump-icon {
  height: 16px;
  width: 20px;
  background: url('../images/icons/bump-icon.svg') no-repeat;
  background-size: 100%;
}

.bump-icon-gray {
  height: 16px;
  width: 20px;
  background: url('../images/icons/bump-icon-gray.svg') no-repeat;
  background-size: 100%;
}

.bump-search-icon .bump-label {
  margin-left: 4px;
  margin-top: 1px;
  line-height: 1rem;
  margin-bottom: 3px;

  @include mobile {
    line-height: 1.2rem;
    margin-bottom: 10px;
  }
}

.paypal-icon {
  width: 80px;
  height: 20px;
  display: inline-block;
  background: center no-repeat url('../images/checkout/paypal-checkout-logo.svg');
  background-size: contain;
}

.reverb-bucks-icon {
  width: 28px;
  height: 24px;
  display: inline-block;
  vertical-align: bottom;
  background: center no-repeat url('../images/icons/reverb-bucks-icon.svg');
  background-size: contain;

  @include mobile {
    height: 48px;
    width: 56px;
  }
}

.affirm-icon {
  background: url('../images/icons/affirm-icon.svg') no-repeat;
  @include fluid-bg-image(126px, 36px);
}

.direct-checkout-cart-label {
  background: url('../images/cart/direct-checkout-cart-label.svg') no-repeat;
  width: 160px;
  height: 20px;
}

.paypal-cart-label {
  background: url('../images/cart/paypal-cart-label.svg') no-repeat;
  width: 135px;
  height: 20px;
}

.affirm-cart-label {
  background: url('../images/cart/affirm-cart-label.svg') no-repeat;
  width: 64px;
  height: 20px;
  margin-right: 4px;
}

.affirm-cart-label-text {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
}

.priceguide-icon {
  background: url('../images/price-guides/pg-book-small.png') no-repeat 2px;
  line-height: 26px;
  padding-left: 26px;
}

.payment-options-direct-checkout {
  width: 124px;
  height: 24px;
  background: center no-repeat url('../images/icons/payment-options/reverb-payments-eu-cards.svg');
  background-size: contain;
}

.payment-options-giftcard {
  width: 34px;
  height: 24px;
  background: center no-repeat url('../images/icons/payment-options/giftcard@4x.png');
  background-size: contain;
}

.empty-state-icon-tiny {
  width: 100px;
  height: 100px;
}

.empty-state-icon-small {
  max-width: 150px;
  max-height: 150px;
}

.empty-state-icon-medium {
  width: 280px;
  height: 280px;
}

.empty-state-icon-large {
  width: 340px;
  height: 300px;
}

.storefront-icon {
  height: 18px;
  width: 22px;
  display: inline-block;
  vertical-align: top;
  background: url("../images/icons/storefront-icon.svg") no-repeat;
}

.article-in-feed-icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  vertical-align: middle;
  background: url("../images/icons/article-in-feed-icon.svg") no-repeat;
}

.handshake-icon {
  width: 30px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  background: url("../images/icons/handshake-icon-orange.svg") no-repeat;
}

.reverb-orange-circle-icon {
  height: 20px;
  width: 20px;
  display: inline-block;
  vertical-align: top;
  background: url("../images/icons/reverb-orange-circle-logo.svg") no-repeat;
  margin-right: 2px;
}

.reverb-digital-download-icon {
  height: 23px;
  width: 35px;
  display: inline-block;
  vertical-align: middle;
  background: url("../images/icons/reverb-digital-download-icon.svg") 100%/cover no-repeat;
}

.icon-small {
  background-size: contain;
  height: 20px;
  width: 22px;
}
