@use 'sass:map';
@use '../../config/configuration';
@use 'focus-outline';
@use 'responsive';
@use 'responsive-hover';

@mixin radio($size: configuration.$radio-size, $mobile-size: configuration.$radio-size, $simulated: false) {
  $unchecked-background-color: var(--rc-color-background-page);
  $checked-background-color: var(--rc-color-background-input-checked);

  $base-selector: '> label';
  $checked-selector: '> input:checked + label';
  $focus-selector: '> input:focus-visible + label';
  $disabled-selector: '> input:disabled + label';
  $disabled-checked-selector: '> input:checked:disabled + label';

  @if ($simulated == true) {
    $base-selector: '&';
    $checked-selector: '&[data-checked]';
    $focus-selector: '&:focus-visible';
    $disabled-selector: '&[data-disabled]';
    $disabled-checked-selector: '&[data-disabled][data-checked]';
  }

  position: relative;

  @if ($simulated != true) {
    input[type='radio'] {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }

  #{$base-selector} {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    font-weight: map.get(configuration.$font-weights, normal);
    width: 100%;
    line-height: $size;

    &::before {
      content: '';
      display: block;
      box-sizing: border-box;
      width: $size;
      height: $size;
      border-radius: 50%;
      border: 0.1rem solid var(--rc-color-border-input-primary);
      transition: border-color .2s, background .2s, box-shadow .2s;
      background-color: $unchecked-background-color;
      box-shadow: inset 0 0 0 0.2rem $unchecked-background-color;
      flex: 0 0 $size;
      margin-right: $size * 0.5;

      @if ($mobile-size != $size) {
        @include responsive.mobile {
          width: $mobile-size;
          height: $mobile-size;
          flex-basis: $mobile-size;
          margin-right: $mobile-size * 0.3;
        }
      }
    }


    @include responsive-hover.responsive-hover {
      &::before {
        background-color: var(--rc-color-palette-gray-200);
      }
    }
  }

  #{$focus-selector} {
    &::before {
      @include focus-outline.focus-outline($offset: true);
    }
  }

  #{$checked-selector} {
    &::before {
      background-color: $checked-background-color;
    }
  }

  #{$disabled-selector} {
    opacity: 0.7;
    cursor: default;
    pointer-events: none;

    &::before {
      border-color: var(--rc-color-palette-gray-200);
    }
  }

  #{$disabled-checked-selector} {
    &::before {
      background-color: var(--rc-color-palette-gray-300);
    }
  }
}
