@use 'sass:map';
@use '@reverbdotcom/cadence/styles/tools/modules/condition';

.listing-editor {
  .fa-question-circle {
    color: var(--rc-color-text-secondary);
  }

  .handmade {
    margin-top: 28px;
    margin-left: 20px;
  }

  .hint-faded {
    opacity: 0;
  }

  fieldset {
    border-bottom: 1px solid var(--rc-color-palette-gray-200);
  }

  .hint {
    font-size: 90%;
  }

  [data-bind='change-country'] {
    display: block;
    margin-bottom: 20px;
  }
}

.gear-description {
  iframe {
    max-width: 100%;
  }

  textarea {
    height: 300px;
  }
}

.selling-selection {
  background: var(--rc-color-palette-gray-200);
  padding: 6px 18px;
}

// Shipping
.shipping-input {
  padding: 6px 0 0;

  .chzn-container {
    margin-bottom: 0;
  }

  .shipping-rate-cost {
    width: 14.8rem;

    @include mobile {
      width: auto;
    }
  }

  .shipping-rate-action {
    width: 5.5rem;
    padding-right: 0;

    @include mobile {
      width: 4.5rem;
    }
  }
}

table.table td.shipping-rate-location,
table.table th.shipping-rate-location {
  padding-left: 0;
}

.shipping-rate-label-pair {
  label {
    font-size: 0.8em;
    margin-bottom: 0.2rem;
    white-space: nowrap;
  }

  input[type='text'] {
    width: 50px;
  }
}

.shipping-rates-header {
  background-color: var(--rc-color-palette-gray-300);
}

table {
  &.new-location-table {
    td {
      border-bottom-color: transparent;
    }
  }
}

.android-app-listing-ad {
  width: 160px;
  margin-left: 78px;
}

.listing-form-dc-upgrade-col {
  @include grid-column(1);
  box-sizing: border-box;

  @include mobile {
    @include grid-column(12);
  }
}

.dc-upgrade-paypal-icon {
  margin-bottom: 3px;
}

.dc-upgrade-affirm-icon {
  margin-bottom: 6px;
}

.listing-form-dc-icons {
  height: 24px;
  line-height: 24px;
  vertical-align: middle;

  @include mobile {
    height: 48px;
  }
}

.dc-upgrade-icons {
  li {
    width: 3.6rem;
    display: inline-block;

    + li {
      margin-left: 0.5rem;
    }
  }
}

.dc-upgrade-plus {
  vertical-align: top;
  padding: 0 3px;
}

.dc-upgrade-btn-hint {
  display: none;
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);

  @include mobile {
    display: none;
  }
}

#advanced-options {
  &.open  {
    display: block;
  }
}

.matched-pg {
  @extend .panel;
  display: flex;
  align-items: center;
  margin: 2rem 0;

  @include mobile {
    flex-flow: column;
  }
}

.matched-pg__image {
  flex-basis: 16rem;
  width: 16rem;
  margin-right: 2%;

  img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 4px;
    overflow: hidden;
  }

  @include mobile {
    display: block;
    flex-basis: 10rem;
    width: 10rem;
  }
}

.matched-pg__data {
  flex-grow: 1;
  width: 100%;
}

.matched-pg__transactions {
  width: 100%;
  flex-basis: 100%;
  margin-top: .5rem;
}

.matched-pg__title {
  font-size: 1.5rem;
  margin-bottom: .5rem;
  font-weight: 700;

  @include mobile {
    font-size: 1.25rem;
    text-align: center;
    margin: .5rem 0;
  }
}

.matched-pg__table {
  width: 100%;
  text-align: center;
  font-weight: bold;
  border-radius: 4px;
  overflow: hidden;

  @include mobile {
    width: 100%;
    font-size: 1.2rem;
  }
}

.matched-pg__range {
  color: var(--rc-color-palette-bw-white);
  font-weight: bold;
  padding: 1rem;
  text-align: center;
  font-size: 1.5rem;
  width: calc(100% / 3);

  span {
    font-size: 1.25rem;
    width: 100%;
    display: block;

    @include mobile {
      font-size: 1rem;
    }
  }

  @include mobile {
    padding: .5rem;
  }

  @include tablet {
    font-size: 1.25rem;
  }
}

.matched-pg__range--below {
  color: var(--rc-color-text-secondary);
  background: var(--rc-color-palette-gray-300);
}

.matched-pg__range--fair {
  background: var(--rc-color-sentiment-positive-dark);
  border-left: .5rem solid var(--rc-color-palette-bw-white);
  border-right: .5rem solid var(--rc-color-palette-bw-white);
}

.matched-pg__range--above {
  color: var(--rc-color-text-secondary);
  background: var(--rc-color-palette-gray-300);
}

.matched-pg__range__label {
  padding: 6px 10px;
  font-weight: 400;

  @include mobile {
    font-size: 10px;
    line-height: 1;
  }
}

.matched-pg__toggle__transactions {
  color: var(--rc-color-palette-blue-600);
  text-decoration: none;
  cursor: pointer;
}

.matched-pg__toggle__transactions__count {
  font-weight: 700;
  margin-left: 1rem;
  font-size: 1.2rem;
}

.condition-explanation {
  width: 100%;
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-size: 13px;

  @include mobile {
    width: inherit;
    font-size: 1.1rem;
  }

  @each $tier in condition.$tiers {
    &.condition-explanation--#{map.get($tier, 'name')} {
      span {
        color: map.get($tier, 'color');
      }
    }
  }
}

.title-character-count-enter {
  opacity: 0;

  &.title-character-count-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in-out;
  }
}

.title-character-count-leave {
  opacity: 1;

  &.title-character-count-leave-active {
    opacity: 0;
    transition: opacity 400ms ease-in-out;
  }
}

.sell-form-hero {
  @include commons-svg-background('instruments.svg');

  overflow: hidden;
  position: relative;
  background-color: var(--rc-color-palette-gray-900);
  margin-bottom: 2rem;

  a {
    color: var(--rc-color-palette-bw-white);
  }

  @include mobile {
    display: none;
  }
}

.sell-form-hero__frame {
  @include site-wrapper;
  display: flex;
}

.sell-form-hero__frame__col {
  flex: 1;
  text-align: center;
  padding: 2rem 1rem;
}

.sell-form-hero__icon {
  margin: 1rem auto;
}

.sell-form-hero__title {
  font-size: 140%;
  font-weight: bold;
}

.additional-language-description,
.gear-description {
  iframe {
    resize: vertical;
    max-width: 100%;
    box-sizing: border-box;
  }
}
