@use '../../config/configuration';
@use 'button-reset';
@use 'svg';
@use 'focus-outline' as focus;

@mixin styled-dropdown {
  $handle-width: 2.8rem;

  border: 0.1rem solid var(--rc-color-border-input-primary);
  background: var(--rc-color-background-input);
  position: relative;

  border-radius: var(--rc-border-radius-input);
  margin-bottom: configuration.$input-bottom-margin;

  &:focus-within {
    @include focus.focus-outline;
    outline-offset: -0.1rem; // thickness of the border
  }

  &::after {
    @include svg.icon-mask-image(angle-down, var(--rc-color-text-primary), 2.4rem);
    content: '';
    position: absolute;
    width: $handle-width;
    top: 0;
    bottom: 0;
    right: 0;
    border-left: none;
    border-radius: 0 var(--rc-border-radius-input) var(--rc-border-radius-input) 0;
    box-sizing: border-box;
    z-index: 2;
    pointer-events: none;
    margin-right: var(--rc-space-2);
  }

  select {
    @include button-reset.button-reset;
    position: relative;
    padding: var(--rc-padding-tb-input-medium) var(--rc-padding-lr-input-medium);
    width: 100%;
    font-size: configuration.$input-font-size;
    font-weight: normal;
    box-shadow: none;
    color: var(--rc-color-text-primary);
    border-radius: var(--rc-border-radius-input); // needed to prevent weird corner clipping
    margin-bottom: 0; // core override
    z-index: 1;
    line-height: configuration.$input-line-height; // these are needed to deal with some bootstrap styles in core admin
    height: auto;
    padding-right: calc($handle-width + var(--rc-space-2));
    min-height: var(--rc-size-height-input);

    &::-ms-expand {
      display: none;
    }

    &:focus-visible {
      outline: none;
    }

    &[disabled],
    &[readonly] {
      cursor: default;
      color: var(--rc-color-text-primary-disabled);
      background-color: var(--rc-color-background-input-disabled);
    }
  }
}
