.page-404-bg {
  @include scaling(min-height, 900px);
  background: var(--rc-color-palette-gray-900) url('../images/top-level/404.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--rc-color-palette-gray-900);
  color: var(--rc-color-palette-bw-white);
}

.error-page-content {
  @include scaling(padding, 10px);
  @include scaling(padding-top, 100px);
  @include scaling(padding-bottom, 100px);
  margin: 0 auto;
  text-align: center;
}

.page-500 {
  @include scaling(min-height, 900px);
  background: var(--rc-color-palette-bw-white);
}

