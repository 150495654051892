.sales-popout {
  &.pointer-top:before {
    border-bottom-color: var(--rc-color-border-primary);
  }
}

.sales-popout-content {
  padding: 1rem;
  max-height: 35vh;
  overflow-y: scroll;
  font-size: 1.2rem;

  @include mobile {
    height: initial;
    overflow-y: initial;
  }
}

.alert-small.bulk-action-error-text { padding: 0.5rem 1rem; }

.on-sale { color: var(--rc-color-palette-vu-orange-500); }

.dashboard-add-to-sale {
  @include breakpoint-tablet-up {
    margin-top: 9px;
    width: 70px;
  }
}

.add-to-sale-checkbox {
  margin-right: 3px;
}

.bulk-sale-list {
  max-height: 55vh;
  overflow-y: auto;
}
