.listing-results {
  margin-top: 3px;

  .results-count {
    display: inline-block;
    margin-bottom: 3px;
    margin-right: 8px;
    line-height: 28px;
    vertical-align: top;
  }

  .collection-search-results {
    display: inline;

    .filter-term {
      display: inline-block;
      vertical-align: top;
    }
  }
}

.remote-pagination-container {
  min-height: 150px;
}
