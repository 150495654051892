/* ==========================================================================
   $BASE-DATE-PICKER
   ========================================================================== */
/* ==========================================================================
   $VARIABLES
   ========================================================================== */
/**
 * Base colors
 */
/**
 * Backgrounds
 */
/**
 * Borders
 */
/**
 * Buttons
 */
/**
 * Picker base
 */
/**
 * Date picker options
 */
/**
 * Theme configurations
 */
/* ==========================================================================
   $MIXINS
   ========================================================================== */
/**
 * Common picker item states
 */
/**
 * Opacity
 */
/**
 * Vendor prefixes
 */
/**
 * The picker box.
 */
.picker__box {
  padding: 0 1em;
}
/**
 * The header containing the month and year stuff.
 */
.picker__header {
  text-align: center;
  position: relative;
  margin-top: .75em;
}
/**
 * The month and year labels.
 */
.picker__month,
.picker__year {
  font-weight: 500;
  display: inline-block;
  margin-left: .25em;
  margin-right: .25em;
}
.picker__year {
  color: #999999;
  font-size: .8em;
  font-style: italic;
}
/**
 * The month and year selectors.
 */
.picker__select--month,
.picker__select--year {
  font-size: .8em;
  border: 1px solid #b7b7b7;
  height: 2.5em;
  padding: .66em .25em;
  margin-left: .25em;
  margin-right: .25em;
  margin-top: -0.5em;
}
.picker__select--month {
  width: 35%;
}
.picker__select--year {
  width: 22.5%;
}
.picker__select--month:focus,
.picker__select--year:focus {
  border-color: #0089ec;
}
/**
 * The month navigation buttons.
 */
.picker__nav--prev,
.picker__nav--next {
  position: absolute;
  top: -0.33em;
  padding: .5em 1.33em;
  width: 1em;
  height: 1em;
}
.picker__nav--prev {
  left: -1em;
  padding-right: 1.5em;
}
.picker__nav--next {
  right: -1em;
  padding-left: 1.5em;
}
.picker__nav--prev:before,
.picker__nav--next:before {
  content: " ";
  border-top: .5em solid transparent;
  border-bottom: .5em solid transparent;
  border-right: 0.75em solid #000000;
  width: 0;
  height: 0;
  display: block;
  margin: 0 auto;
}
.picker__nav--next:before {
  border-right: 0;
  border-left: 0.75em solid #000000;
}
.picker__nav--prev:hover,
.picker__nav--next:hover {
  cursor: pointer;
  color: #000000;
  background: #b1dcfb;
}
.picker__nav--disabled,
.picker__nav--disabled:hover,
.picker__nav--disabled:before,
.picker__nav--disabled:before:hover {
  cursor: default;
  background: none;
  border-right-color: #f5f5f5;
  border-left-color: #f5f5f5;
}
/**
 * The calendar table of dates
 */
.picker__table {
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  font-size: inherit;
  width: 100%;
  margin-top: .75em;
  margin-bottom: .5em;
}
@media (min-height: 33.875em) {
  .picker__table {
    margin-bottom: .75em;
  }
}
.picker__table td {
  margin: 0;
  padding: 0;
}
/**
 * The weekday labels
 */
.picker__weekday {
  width: 14.285714286%;
  font-size: .75em;
  padding-bottom: .25em;
  color: #999999;
  font-weight: 500;
  /* Increase the spacing a tad */

}
@media (min-height: 33.875em) {
  .picker__weekday {
    padding-bottom: .5em;
  }
}
/**
 * The days on the calendar
 */
.picker__day {
  padding: .3125em 0;
  font-weight: 200;
  border: 1px solid transparent;
}
.picker__day--today {
  color: #0089ec;
  position: relative;
}
.picker__day--today:before {
  content: " ";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 0;
  height: 0;
  border-top: 0.5em solid #0059bc;
  border-left: .5em solid transparent;
}
.picker__day--selected,
.picker__day--selected:hover {
  border-color: #0089ec;
}
.picker__day--highlighted {
  background: #b1dcfb;
}
.picker__day--disabled:before {
  border-top-color: #aaaaaa;
}
.picker__day--outfocus {
  color: #dddddd;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=66)";
  filter: alpha(opacity=66);
  -moz-opacity: 0.66;
  opacity: 0.66;
}
.picker__day--infocus:hover,
.picker__day--outfocus:hover {
  cursor: pointer;
  color: #000000;
  background: #b1dcfb;
}
.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted {
  background: #0089ec;
  color: #ffffff;
}
.picker__day--disabled,
.picker__day--disabled:hover {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #dddddd;
  cursor: default;
}
.picker__day--highlighted.picker__day--disabled,
.picker__day--highlighted.picker__day--disabled:hover {
  background: #bbbbbb;
}
/**
 * The footer containing the "today" and "clear" buttons.
 */
.picker__footer {
  text-align: center;
}
.picker__button--today,
.picker__button--clear {
  border: 1px solid #ffffff;
  background: #ffffff;
  font-size: .8em;
  padding: .66em 0;
  font-weight: bold;
  width: 50%;
  display: inline-block;
  vertical-align: bottom;
}
.picker__button--today:hover,
.picker__button--clear:hover {
  cursor: pointer;
  color: #000000;
  background: #b1dcfb;
  border-bottom-color: #b1dcfb;
}
.picker__button--today:focus,
.picker__button--clear:focus {
  background: #b1dcfb;
  border-color: #0089ec;
  outline: none;
}
.picker__button--today:before,
.picker__button--clear:before {
  position: relative;
  display: inline-block;
  height: 0;
}
.picker__button--today:before {
  content: " ";
  margin-right: .45em;
  top: -0.05em;
  width: 0;
  border-top: 0.66em solid #0059bc;
  border-left: .66em solid transparent;
}
.picker__button--clear:before {
  content: "\D7";
  margin-right: .35em;
  top: -0.1em;
  color: #ee2200;
  vertical-align: top;
  font-size: 1.1em;
}

/* ==========================================================================
   $CLASSIC-DATE-PICKER
   ========================================================================== */
/* ==========================================================================
   $VARIABLES
   ========================================================================== */
/**
 * Base colors
 */
/**
 * Backgrounds
 */
/**
 * Borders
 */
/**
 * Buttons
 */
/**
 * Picker base
 */
/**
 * Date picker options
 */
/**
 * Theme configurations
 */
/* ==========================================================================
   $MIXINS
   ========================================================================== */
/**
 * Common picker item states
 */
/**
 * Opacity
 */
/**
 * Vendor prefixes
 */
