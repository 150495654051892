.span-spacer.hidden-desktop {
  width: 76px;
}

.span-bid {
  width: 114px;
  width: (114/14) + rem;
}

div.span-auction-time {
  width: 84px;
  width: (84/14) + rem;
  margin-left: 0;
}

div.span-bid-count {
  width: 35px;
  width: (35/14) + rem;
  margin-left: 0;

  p.auction-label {
    text-align: right;
  }
}

