.progress-meter {
  border: 1px solid var(--rc-color-palette-gray-900);
  border-radius: 3px;
  background: transparent;
  height: 18px;
  display: block;
  padding: 2px;

  & > span {
    height: inherit;
    background-color: var(--rc-color-palette-gray-900);
    background-size: 100% 100%;
    display: block;
    text-indent: -9999px;
  }

  &.progress-meter-high > span {
    background-color: var(--rc-color-palette-vu-orange-500);
  }
}

.layaway-progress-meter .progress-meter {
  border: none;
  padding: 0;
  background-color: var(--rc-color-palette-gray-300);
  border-radius: 10px;
  overflow: hidden;

  & > span {
    background-color: var(--rc-color-sentiment-positive-dark);
  }
}
