.gift-card {
  @include box-shadow;
  border-radius: 22px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @include mobile {
    border-radius: 10px;
  }
}

.gift-card__image {
  background-size: cover;
  background-repeat: no-repeat;
  height: 0;
  padding-top: 50%;
  print-color-adjust: exact !important; // to help prevent browsers from stripping out background images in prints
}

.gift-card__details {
  position: relative;
  background-color: var(--rc-color-palette-bw-white);
  padding: 0 20px 20px;
  display: flex;
  justify-content: space-between;

  @include mobile {
    padding: 0 12px 12px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 20px;
    top: -19px;
    left: 0;
    right: 0;
    background: linear-gradient(to right top, var(--rc-color-palette-bw-white) 49%, transparent 52%);
    print-color-adjust: exact !important; // to help prevent browsers from stripping out background images in prints

    @include mobile {
      height: 12px;
      top: -11px;
    }
  }
}

.gift-card__info {
  margin-top: auto;

  &.gift-card__info--redeem {
    margin-left: auto;
  }
}

.gift-card__label {
  font-size: 16px;
 color: var(--rc-color-text-secondary);
  margin: 0;
  font-weight: normal;

  @include mobile {
    font-size: 13px;
  }
}

.gift-card__value {
  color: var(--rc-color-palette-gray-300);
  font-size: 30px;
  font-weight: 700;
  transition: color 0.1s ease;

  &.gift-card__value--filled {
    color: var(--rc-color-palette-gray-900);
  }

  @include mobile {
    font-size: 18px;
  }
}

.gift-card__code {
  font-size: 20px;
  color: var(--rc-color-palette-gray-300);

  &.gift-card__code--real-code {
    color: var(--rc-color-text-primary);
  }

  &.gift-card__code--highlight {
    background-color: var(--rc-color-palette-vu-orange-100);
    color: var(--rc-color-text-secondary);
  }

  @include mobile {
    font-size: 15px;
  }
}

.gift-card__branding {
  font-size: 24px;
  display: flex;
  align-items: center;
  padding-top: 1px;

  &::before {
    content: '';
    display: inline-block;
    width: 32px;
    height: 32px;
    background: transparent url('../images/icons/reverb-orange-circle-logo.svg') no-repeat center;
    margin-right: 10px;
  }
}
