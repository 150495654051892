@use './theme';
@use './responsive-hover' as hover;

// Disabling no-invalid-css-vars since this area concatenates vars as an exception to the norm
// stylelint-disable cadence/no-invalid-css-vars

$button-themes: 'main', 'danger', 'loud';
$button-variants: 'filled', 'outlined', 'muted', 'transparent';
$button-sizes: 'mini', 'small', 'medium', 'large';

@mixin size($size) {
  @if index($button-sizes, $size) {
    --font-size: var(--rc-font-size-button-#{$size});
    --padding-tb: var(--rc-padding-tb-button-#{$size});
    --padding-lr: var(--rc-padding-lr-button-#{$size});
    --min-height: var(--rc-min-height-button-#{$size});
  }

  @else {
    @error "Unsupported size '#{$size}'. Only mini, small, medium, and large are supported.";
  }
}

@mixin colors($theme, $variant, $inverted) {
  $isInverted: if($inverted, '-inverted', '');

  @if index($button-themes, $theme) {
    @if index($button-variants, $variant) {

      @if ($variant == 'filled') {
        --background-color: var(--rc-color-button-#{$theme}-primary#{$isInverted});
        --background-color-hovered: var(--rc-color-button-#{$theme}-primary-hovered#{$isInverted});
        --text-color: var(--rc-color-text-button-on-#{$theme}-primary#{$isInverted});
        --border-color: var(--rc-color-transparent);
      }

      @if ($variant == 'outlined') {
        --background-color: var(--rc-color-transparent);
        --background-color-hovered: var(--rc-color-button-#{$theme}-primary#{$isInverted});
        --text-color: var(--rc-color-text-button-#{$theme}#{$isInverted});
        --border-color: var(--rc-color-button-#{$theme}-primary#{$isInverted});

        @include hover.responsive-hover {
          --text-color: var(--rc-color-text-button-on-#{$theme}-primary#{$isInverted});
        }
      }

      @if ($variant == 'muted') {
        --background-color: var(--rc-color-button-#{$theme}-secondary#{$isInverted});
        --background-color-hovered: var(--rc-color-button-#{$theme}-secondary-hovered#{$isInverted});
        --text-color: var(--rc-color-text-button#{$isInverted});
        --border-color: var(--rc-color-transparent);

        // Adds a blur effect to prevent text behind the button from bleeding through the button's semi-transparent background
        backdrop-filter: blur(var(--rc-space-4));
      }

      @if ($variant == 'transparent') {
        --background-color: var(--rc-color-transparent);
        --background-color-hovered: var(--rc-color-button-#{$theme}-secondary#{$isInverted});
        --text-color: var(--rc-color-text-button-#{$theme}#{$isInverted});
        --border-color: var(--rc-color-transparent);

        @include hover.responsive-hover {
          --text-color: var(--rc-color-text-button#{$isInverted});
        }
      }
    }

    @else {
      @error "Unsupported variant '#{$variant}'. Only 'filled', 'outlined', 'muted', and 'transparent' supported.";
    }
  }

  @else {
    @error "Unsupported theme '#{$theme}'. Only 'main', 'loud', and 'danger' supported.";
  }
}

// TODO this make things easier to compute but can/should eventually replace all the rc-button.filled, rc-button.muted etc calls
@mixin create(
  $size,
  $theme,
  $variant,
  $inverted,
  $fullwidth,
  $extend,
) {
  @if ($extend) {
    @include base;
  }

  @if ($fullwidth) {
    width: 100%;
  }

  @include size($size);
  @include colors($theme, $variant, $inverted);
}

// we created this base mixin for the rare instances that we would need to @extend something inside
// a media query. In those cases, use this mixin directly. In others, just use the regular primary/secondary/etc modules.
@mixin base {
  --text-color-hovered: var(--text-color);

  appearance: none;
  font: inherit;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--rc-space-1);
  text-align: center;
  box-sizing: border-box;
  border-style: solid;
  white-space: nowrap;
  transition: background-color 0.1s, color 0.1s, border-color 0.1s;

  // These vars are the same across all button variants but from the token system
  border-radius: var(--rc-border-radius-button);
  border-width: var(--rc-border-width-button);
  font-weight: var(--rc-font-weight-button);
  letter-spacing: var(--rc-font-letter-spacing-default);

  // These vars are variant-specific and overridden below
  font-size: var(--font-size);
  padding-top: var(--padding-tb);
  padding-bottom: var(--padding-tb);
  padding-left: var(--padding-lr);
  padding-right: var(--padding-lr);
  min-height: var(--min-height);
  min-width: var(--min-height);
  background-color: var(--background-color);
  border-color: var(--border-color);
  color: var(--text-color);

  @include hover.responsive-hover {
    color: var(--text-color-hovered);
    background-color: var(--background-color-hovered);
  }

  &[aria-expanded='true'] {
    color: var(--text-color-hovered);
    background-color: var(--background-color-hovered);
  }

  &:disabled,
  &[data-disabled] {
    cursor: not-allowed;
    opacity: var(--rc-opacity-button-disabled);
  }
}

// The mixins filled, outlined, muted, and transparent are shortcuts so folks can type "@include rc-button.filled"
// in scss files throughout Frontend VS "@include rc-button.create($size: 'medium', $variant: 'filled'...)" repeatedly.

@mixin filled(
  $size: 'medium',
  $theme: 'main',
  $fullwidth: false,
  $inverted: false,
  $extend: true,
) {
  @include create(
    $size: $size,
    $theme: $theme,
    $fullwidth: $fullwidth,
    $variant: 'filled',
    $inverted: $inverted,
    $extend: $extend,
  );
}

@mixin outlined(
  $size: 'medium',
  $theme: 'main',
  $fullwidth: false,
  $inverted: false,
  $extend: true,
) {
  @include create(
    $size: $size,
    $theme: $theme,
    $fullwidth: $fullwidth,
    $variant: 'outlined',
    $inverted: $inverted,
    $extend: $extend,
  );
}

@mixin muted(
  $size: 'medium',
  $theme: 'main',
  $fullwidth: false,
  $inverted: false,
  $extend: true,
) {
  @include create(
    $size: $size,
    $theme: $theme,
    $fullwidth: $fullwidth,
    $variant: 'muted',
    $inverted: $inverted,
    $extend: $extend,
  );
}

// Transparent Button
@mixin transparent(
  $size: 'medium',
  $theme: 'main',
  $fullwidth: false,
  $inverted: false,
  $extend: true,
) {
  @include create(
    $size: $size,
    $theme: $theme,
    $fullwidth: $fullwidth,
    $variant: 'transparent',
    $inverted: $inverted,
    $extend: $extend,
  );
}
