@mixin shape-base {
  content: "";
  display: block;
  position: absolute;
}

@mixin circle($diameter, $color) {
  @include shape-base;

  background-color: $color;
  border-radius: $diameter;
  height: $diameter;
  width: $diameter;
}

@mixin triangle($width: 0, $length: $width, $color: black, $direction: up) {
  @include shape-base;

  height: 0;
  width: 0;

  @if $direction == up {
    border-left: $width solid transparent;
    border-right: $width solid transparent;
    border-bottom: $length solid $color;
  } @else if $direction == down {
    border-left: $width solid transparent;
    border-right: $width solid transparent;
    border-top: $length solid $color;
  } @else if $direction == left {
    border-top: $width solid transparent;
    border-bottom: $width solid transparent;
    border-right: $length solid $color;
  } @else if $direction == right {
    border-top: $width solid transparent;
    border-bottom: $width solid transparent;
    border-left: $length solid $color;
  }
}

