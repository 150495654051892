.trulioo-onboarding-wrapper {
  width: 49rem;
  margin-left: auto;
  margin-right: auto;
  min-width: 39rem;
  @include tablet {
    margin-left: auto;
    margin-right: auto;
    min-width: auto;
  }
}

.trulioo-iframe-container {
  margin-top: -5rem;

  @include mobile {
    margin-top: -2rem;
  }
}

.trulioo-verified-message-container {
  text-align: center;
  background-color: var(--rc-color-background-module);
  padding: 4rem;
  margin-bottom: 2rem;
  border: 0.1rem solid var(--rc-color-border-primary);
}

.next-step-container {
  padding: 0;
  padding-bottom: 10rem;
  text-align: right;
}

.next-step-text {
  margin-top: .8rem;
  margin-bottom: 1.4rem;
}

@media screen and (max-width: 57rem) {
  .trulioo-onboarding-wrapper {
      width: 100%;
      min-width: 100%;
  }

  .next-step-container {
    padding: 0 3rem;
  }
}
