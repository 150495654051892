.payout-timeline {
  margin-bottom: 0;

  .step-timeframe {
    margin-top: -26px;
    @include mobile {
      display: none;
    }

    span.step-tooltip { color: var(--rc-color-palette-blue-100); }
  }

  &.progress-bar {
    .step .label {
      @include mobile {
        visibility: visible;
        font-size: 0.8em;
      }
    }
  }
}
