.price-history-chart {
  @extend .module;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  display: block;
}

.price-history-chart--hidden {
  display: none;
}

.price-history-chart__header {
  @include grid-container;
}

.price-history-chart__legend {
  position: relative;
  text-align: right;
}

.price-history-chart__label {
  display: inline-block;
  margin: 0 0 0 10px;
  position: relative;

  @mixin label($color) {
    font-size: 12px;
    font-weight: 700;
    padding-left: 12px;

    &::after {
      $circle-diameter: 8px;
      @include circle($circle-diameter, $color);

      top: 50%;
      margin-top: -($circle-diameter/2);
      left: 0;
    }
  }

  &.price-final {
    @include label(map-get($chartist, line-color-a));
  }
}

.price-history-chart__line-chart {
  @media screen and (max-width: 640px) {
    .ct-label {
      font-size: 0.6rem;
    }
  }

  .ct-series-a {
    .ct-line {
      stroke: map-get($chartist, line-color-a);
    }
    .ct-point {
      stroke:  map-get($chartist, line-color-a);
    }
  }

  .ct-series-b {
    .ct-line {
      stroke: map-get($chartist, line-color-b);
    }
    .ct-point {
      stroke:  map-get($chartist, line-color-b);
    }
  }
}
