form,
.form {
  &.inline-form {
    display: inline;
  }

  .inline {
    @include clearfix;

    .input,
    div.control-group {
      float: left;
      margin-right: 1.6rem;
    }
  }

  .error,
  .formError {
    clear: both;
    color: var(--rc-color-text-danger);
    display: block;

    // For create_account_form and other forms that do not
    // get properly processed with errors, this will highlight
    // the field when there's an error
    + input {
      border: 1px solid var(--rc-color-border-alert-error) !important;
      background: var(--rc-color-background-alert-error) !important;
      border-radius: 6px;
    }
  }

  .form-actions {
    input { margin-bottom: 0; }
  }

  &.horizontal,
  &.form-horizontal {
    div.control-group {
      @include clearfix;

      div.controls { padding-left: 10em; }

      label.control-label {
        float: left;
        line-height: 3.4rem;
        text-align: right;
      }
    }
  }
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='number'],
input[type='password'],
textarea {
  &.auto-resize {
    resize: vertical;
  }

  &.small {
    padding: 0.4rem;
  }

  &.inline {
    display: inline-block;
  }

  // override for buttons
  &.btn,
  &.button {
    margin-bottom: 0;
  }
}
