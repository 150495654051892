@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.seller-sales-chart {
  min-height: 155px;
}

.bump-site-stats-table {
  display: flex;

  @include mobile {
    flex-wrap: wrap;
  }

  .stat-col {
    flex: 1 1 25%;
    box-sizing: border-box;

    @include mobile {
      margin-top: 0.5em;
      flex: 1 1 50%;
    }
  }
}

.bump-site-stats-table__error {
  @include scaling(padding, 5px);
  background-color: var(--rc-color-background-alert-error);
  color: var(--rc-color-text-danger);
  font-size: 1.3rem;
  text-transform: none;
  border-radius: 0.4rem;
  white-space: normal;
}


.dashboard-seller-action-panel-wrapper {
  .panel-container {
    display: block;
  }
}

.dashboard-seller-actions {
  float: right;

  .panel-container {
    display: block;
  }
}

.view-dashboard-index {
  .site-module__title {
    @include rc-text.title('700');
  }

  > .g-container {
    margin-top: var(--rc-space-10);
  }

  .g-col-9 > .scaling-mb-2 {
    margin-bottom: var(--rc-space-16);

    @include mobile {
      margin-bottom: var(--rc-space-8);
    }
  }
}
