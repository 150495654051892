@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.new-cart {
  .content-frame { @include mobile { width: 100%; }; }

  .module {
    @include clearfix;
    border-radius: $input-border-radius;
    @include mobile { border-radius: 0; }
    padding: 14px;
  }
}

.cart-item {
  & + .cart-item {
    margin-top: var(--rc-space-5);

    @include mobile {
      margin-top: var(--rc-space-3);
    }
  }

  // to target the <a> wrapping the thumbnail
  .width-20 > a {
    background-color: var(--rc-color-palette-gray-100);
    border-radius: var(--rc-border-radius-lg);
    overflow: hidden;
    display: block;
  }
}

.img-cart {
  width: 100%;
  height: auto;
  display: block;
  transition: opacity .1s;
  box-sizing: border-box;

  &:hover {
    opacity: 0.85;
  }
}

.payment-type-hint {
  cursor: default;
  line-height: 24px;
}

.unpaid-in-cart {
  font-weight: bold;
  font-size: 90%;
  border-radius: 3px;
  display: inline-block;
  padding: 2px 6px;
  color: var(--rc-color-sentiment-positive-dark);
  background-color: var(--rc-color-background-alert-success);
  border: 1px solid var(--rc-color-sentiment-positive-light);

  span.time-left-to-checkout {
    font-weight: normal;
  }
}

.body-cart {
  [data-cart-summary] {
    .site-module {
      border-radius: var(--rc-border-radius-primary);
    }

    .site-module__footer {
      border-bottom-left-radius: var(--rc-border-radius-primary);
      border-bottom-right-radius: var(--rc-border-radius-primary);
    }
  }
}

.cart-items-list {
  .loading-component-container {
    &.loading-overlay {
      background-color: transparent;
    }
  }
}

.cart-items-list__item {
  margin-bottom: 4rem;

  &:last-of-type {
    margin-bottom: 2rem;
  }
}

.payment-selection {
  @include clearfix;
  box-sizing: border-box;
  display: flex;

  @include mobile {
    display: block;
  }
}

.payment-selection__item {
  display: flex; // ensure that items stretch all to same height, regardless of content inside
  flex: 1;
  text-align: center;
  margin-right: 2%;

  &:last-child {
    margin-right: 0;
  }

  @include mobile {
    display: block;
    margin-right: 0;
  }
}

.payment-selection__item__label {
  width: 100%;
  box-sizing: border-box;

  @include breakpoint-tablet-up {
    margin-bottom: 0;
  }

  // To downsize the default boxed-list padding
  &.boxed-list__item {
    @include mobile {
      padding: 10px;
    }
  }
}

.payment-selection__item__icon {
  padding-bottom: 0.8em;

  @include mobile {
    padding-bottom: 0.5em;
  }
}

.payment-selection__item__hint {
  color: var(--rc-color-text-primary);
  font-size: 90%;
}

.direct-checkout-payment-icon {
  width: 196px;
  height: 24px;
  display: block;
  margin: 0 auto;
  background: url('../images/cart/direct-checkout-payment-icon.png') no-repeat;
  background: url('../images/cart/direct-checkout-payment-icon.svg') no-repeat;

  @include mobile {
    width: 163px;
    height: 20px;
    background-size: 100%;
  }
}

.direct-checkout-no-amex-payment-icon {
  width: 153px;
  height: 24px;
  display: block;
  margin: 0 auto;
  background: url('../images/cart/direct-checkout-no-amex-payment-icon.png') no-repeat;
  background: url('../images/cart/direct-checkout-no-amex-payment-icon.svg') no-repeat;

  @include mobile {
    width: 128px;
    height: 20px;
    background-size: 100%;
  }
}

.paypal-payment-icon {
  width: 95px;
  height: 24px;
  display: block;
  margin: 0 auto;
  background: url('../images/cart/paypal-payment-icon.png') no-repeat;
  background: url('../images/cart/paypal-payment-icon.svg') no-repeat;

  @include mobile {
    width: 79px;
    height: 20px;
  }
}

.affirm-payment-icon {
  width: 84px;
  height: 24px;
  display: block;
  margin: 0 auto;
  background: url('../images/cart/affirm-payment-icon.png') no-repeat;
  background: url('../images/cart/affirm-payment-icon.svg') no-repeat;

  @include mobile {
    width: 70px;
    height: 20px;
  }
}

.cart-same-currency-title {
  @include rc-text.title('500');
  margin-top: var(--rc-space-12);
  margin-bottom: var(--rc-space-3);

  @include mobile {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.cart-sale-reminder {
  padding-left: 1em;
  text-indent: -1em;
  color: var(--rc-color-text-price-nudge);
  font-weight: bold;
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.3;
  margin-bottom: 0.2rem;

  &:before {
    @include fa-icon(tag);
    width: 1em;
    text-indent: 0;
    margin-right: 0.1em;
    font-size: 0.9em;
  }

  @include mobile {
    line-height: initial;
  }
}

.cart-price-discounted {
  color: var(--rc-color-text-price-nudge);
}
