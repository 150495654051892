.trait-select {
  @include scaling((margin-left margin-right), -2rem);
  @include scaling((margin-top padding-top padding-left padding-right), 2rem);

  border-top: 0.1rem solid var(--rc-color-palette-gray-200);
}

.trait-select__fields {
  @include scaling((margin-left margin-right), -1.5rem);
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.trait-select__field {
  @include scaling(padding, 1.5rem);

  padding-top: 1rem;
  padding-bottom: 1rem;
  box-sizing: border-box;
  flex: 0 0 33%;

  @include tablet {
    flex-basis: 50%;
  }

  &:nth-child(n+4) {
    display: none;

    .trait-select--expanded & {
      display: block;
    }
  }

  // breaking our css rules to size these down a touch
  .form-group label {
    font-size: 1.3rem;
  }

  @include tablet {
    flex-basis: 50%;

    &:nth-child(n+3) {
      display: none;

      .trait-select--expanded & {
        display: block;
      }
    }
  }
}

.trait-select__expand {
  text-align: center;
}
