@use '@reverbdotcom/cadence/styles/tools/functions/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

// This is to override the JS-based width calculations so things resize properly
// when the window is resized by users
.chzn-container,
.chzn-container .chzn-drop,
.chzn-container-single .chzn-search input {
  width: 100% !important;
}

.chzn-container.chzn-container-single {
  @include styled-dropdown;
  border: none;
  font-size: $input-font-size;
  margin-bottom: 0;

  .add-shipping-region-select & {
    margin-bottom: 0;
    width: 100%;
  }
}

.chzn-container {
  .chzn-results {
    li {
      padding: 0.5rem 0.6rem;
      line-height: 1.15;
    }
  }
}

.chzn-container-single {

  .chzn-single {
    background: none;
    border: 0.1rem solid var(--rc-color-border-primary);
    border-radius: $input-border-radius;
    padding: $input-padding-vertical $input-padding-horizontal;
    box-sizing: border-box;
    font-size: $input-font-size;
    height: auto;
    color: var(--rc-color-text-primary);
    line-height: $input-line-height;
    box-shadow: none; // overrides Chosen's default

    span {
      margin-right: 14px;
    }

    // hide the default arrows
    div {
      display: none;
    }
  }

  &.ui-state-error .chzn-single {
    border-color: var(--rc-color-border-alert-error);
    background-color: var(--rc-color-background-alert-error);
  }

  &.chzn-container-active .chzn-single {
    border-color: var(--rc-color-palette-blue-600);
  }

  .chzn-drop {
    border-color: var(--rc-color-border-primary);
    border-top: 0.1rem solid var(--rc-color-border-primary);
  }

  .chzn-search {
    padding: 6px;
    border-color: var(--rc-color-border-primary);
  }

  &.chzn-container-single-nosearch .chzn-search {
    padding: 6px 0 0;
  }

  .chzn-results {
    margin-right: 6px;
    margin-bottom: 6px;
    padding-left: 6px;

    .highlighted {
      background-image: none;
      background-color: var(--rc-color-text-link-hovered);
      border-radius: 2px;
    }
  }
}

select.chosen-select,
select.chosen-select-no-search,
[data-chosen-select] {
  // override everything on mobile and style to be custom-ish
  @include mobile {
    background-image: svg.svg-url(angle-updown, scss-tokens.$rc-color-text-secondary);
    background-color: var(--rc-color-palette-bw-white);
    background-repeat: no-repeat;
    background-size: auto 44%;
    background-position: right 6px center;
    width: 100%;
    border: 0.1rem solid var(--rc-color-border-primary);
    border-radius: $input-border-radius;
    padding: $input-padding-vertical $input-padding-horizontal;
    line-height: 1.2;
    font-size: $input-font-size;
    font-weight: normal;
    position: relative;
    margin-bottom: $input-bottom-margin; // to match core legacy styles
  }
}
