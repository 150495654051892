@use '@reverbdotcom/cadence/styles/tools/mixins/svg';

// safe to assume these classes are deprecated
// in favor of design system

a {
  &.remove {
    color: var(--rc-color-sentiment-negative-darker);
  }

  &.underlined {
    text-decoration: underline;
  }

  &.link-on-dark-bg {
    text-decoration: underline;
    color: var(--rc-color-palette-bw-white);

    &:hover {
      color: var(--rc-color-palette-bw-white);
      opacity: 0.7;
    }
  }

  &.icon-after-text {
    white-space: nowrap;
    .fa {
      margin-left: 0.2em;
    }
  }
}

a.accent,
span.accent {
  color: var(--rc-color-text-accent);
}

a.muted-link {
  color: var(--rc-color-text-secondary);

  &:hover {
    color: var(--rc-color-text-secondary);
    text-decoration: underline;
  }
}

.dark-links a {
  color: var(--rc-color-text-primary);
  &:hover { color: var(--rc-color-text-accent); }
}

.red { color: var(--rc-color-text-danger); }

.navigation-breadcrumbs {
  margin: 1rem 0;
  font-size: 0.9em;
}

.navigation-breadcrumbs__item {
  display: inline-block;

  &:last-child {
    .navigation-breadcrumbs__link:after {
      display: none;
    }
  }
}

.navigation-breadcrumbs__link {
  color: var(--rc-color-text-secondary);
  display: inline-block;

  &:hover {
    color: var(--rc-color-text-accent);
  }

  @include svg.icon-after(angle-right);

  &:after {
    margin: 0 0.5em;
    opacity: 0.5;
  }

  &.navigation-breadcrumbs__link--back {
    font-weight: bold;

    &:before {
      @include fa-icon(arrow-circle-left);
      display: inline-block;
      margin-right: 0.5em;
    }

    &:after {
      content: "|";
      background-image: none;
    }
  }
}
