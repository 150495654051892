.badge {
  border-radius: 4px;
  font-weight: bold;
  color: var(--rc-color-palette-bw-white);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: var(--rc-color-text-secondary);
  padding: 6px 11px;
  margin-right: 3px;

  &.pill {
    border-radius: 3px;
    font-size: 1.4rem;
  }

  &.tiny {
    font-size: 1.2rem;
    padding: 2px 5px;
  }

  &.small {
    padding: 1px 6px;
  }

  &.important { background: var(--rc-color-text-danger) !important; }

  &.info { background: var(--rc-color-palette-blue-700); }

  &.success { background: var(--rc-color-sentiment-positive-dark); }

  &.blue-badge { background-color: var(--rc-color-palette-blue-600); }
}

a.important { color: var(--rc-color-text-danger); }

.regular-links {
  a.important {
    color: var(--rc-color-text-link);
    &:hover { color: var(--rc-color-text-accent); }
  }
  .notification { float: right; }
}

.regular-links li {
  padding: 6px 0;
  padding: (6/14)+rem 0;
}

.notification {
  display: inline-block;
  color: var(--rc-color-text-pip);
  background: var(--rc-color-background-pip);
  border-radius: 4px;
  line-height: 1;
  height: 1em;
  padding: 0.1em 0.2em 0.2em 0.2em;
  min-width: 1em;
  text-align: center;
  font-weight: bold;
  position: relative;
}

.status-completed .badge { background: var(--rc-color-sentiment-positive-dark) !important; }

.status-pending .badge { background: var(--rc-color-sentiment-negative-dark) !important; }

.product-live .badge { background: var(--rc-color-palette-blue-700); color: var(--rc-color-palette-bw-white); }

.product-ordered .badge { background: var(--rc-color-sentiment-positive-dark); color: var(--rc-color-palette-bw-white); }

.badge-icon {
  display: inline-block;
  padding-left: 39px;
  font-weight: bold;
  color: var(--rc-color-text-secondary);
  box-sizing: border-box;
  @include mobile { font-size: 20px; }
}

a.badge-icon:hover {
  color: var(--rc-color-text-secondary);
  text-decoration: underline;
}

.cc-badge {
  background: url('../images/icons/direct-checkout-credit-card-icon.png') left center no-repeat;
}

.gift-card-badge {
  background: url('../images/gift-cards/gift-card-icon.png') left center no-repeat;
}

.reverb-bucks-badge {
  color: var(--rc-color-text-alert-success);
  background: url('../images/icons/reverb-bucks-icon.svg') left center no-repeat;
  background-size: 28px 24px;
  line-height: 28px;

  @include mobile {
    line-height: 1.4;
    padding-left: 80px;
    background-size: 56px 48px;

    &.normal-size {
      font-size: 1.4rem;
      padding-left: 60px;
      line-height: 1.6;
    }
  }
}

.seller-badge-block {
  display: inline-block;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }

  @include mobile {
    margin-bottom: 0.5rem;
  }

  .shop-header__overview__badges & {
    @include mobile {
      margin-bottom: 0;
    }
  }
}

.shop-badge {
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 32px;
  height: 32px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.1;
  cursor: default;
  color: var(--rc-color-palette-blue-600);
  box-sizing: border-box;

  &:hover { opacity: 0.9; }
}

.shop-badge-standalone {
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  height: 34px;
  width: 34px;
  box-sizing: border-box;
  &.preferred-seller-badge { width: 40px; }

  &:hover { opacity: 0.9; }
}

.preferred-seller-badge,
a.preferred-seller-badge {
  background-image: url('../images/icons/badges/preferred-seller.svg');
  cursor: pointer;
}

.quick-shipper-badge {
  background-image: url('../images/icons/badges/quick-shipper.svg');
}

.quick-responder-badge {
  background-image: url('../images/icons/badges/quick-responder.svg');
}

.sale-status-upcoming { background-color: var(--rc-color-palette-blue-600); }
.sale-status-active { background-color: var(--rc-color-sentiment-positive-darker); }
.sale-status-ended { background-color: var(--rc-color-text-secondary); }
