.search-context-bar {
  border-bottom: 1px solid map-get($search-context-bar, border-color);

  .search-context-query {
    @extend %search-context-bar-vertical-height;

    display: inline-block;
    margin: 0 4px 0 0;

    @include mobile {
      display: block;
      height: auto;
      line-height: normal;
      margin-bottom: 10px;
    }
  }

  .collection-search-results {
    display: inline;
    vertical-align: middle;

    & .filter-term {
      &.category-filter ~ .category-filter {
        @include search-context-bar-category-sibling;
      }
    }

    a {
      @extend %search-context-bar-vertical-height;

      color: map-get($filter-term, color);

      &:hover {
        color:  map-get($filter-term, color-hover);
      }
    }
  }

  .follow { margin-top: 0; }

  a.btn-follow,
  a.btn-gear-alert {
    height: map-get($search-context-bar, height);
    line-height: map-get($search-context-bar, line-height);
    padding: 0;
  }
}

.collection-search-results {
  .filter-term {
    @include search-context-bar-filter-term;
    cursor: default;
  }

  .filter-term-remove {
    @extend %search-context-bar-vertical-height;
    padding: 6px map-get($filter-term, spacing);
    cursor: pointer;

    &:hover {
      color:  map-get($filter-term, color-hover);
    }
  }
}
