.trulioo-landing-page {
  max-width: 62.4rem;
}

.trulioo-landing-page__card {
  background: var(--rc-color-background-module);
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: 0.5rem;
  margin-bottom: 2.4rem;
}

.trulioo-landing-page__card-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 18.4rem;
  background: var(--rc-color-palette-blue-700);
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  width: 100%;
  @include mobile {
    height: 10rem;
  }
}

.trulioo-landing-page__card-banner-image {
  width: 10rem;
  height: 10em;
  @include mobile {
    width: 8rem;
    height: 8rem;
  }
}

.trulioo-landing-page__card-list-image {
  width: 4rem;
  height: 4rem;
}
