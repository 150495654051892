.tax-rate-section {
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.tax-rate-section__title {
  margin-bottom: 0.5rem;
  font-weight: 700;
}
.tax-rate-section__subtitle {
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
  margin: 0 0 0.5rem;
}

.tax-rate-section__rates {
  &.tax-rate-section__rates--columns {
    column-count: 3;

    @include tablet {
      column-count: 2;
    }

    @include mobile {
      column-count: auto;
    }
  }
}

.tax-rate-section__rate {
  padding: 0.5rem 0;

  &:last-child {
    padding-bottom: 0;
  }
}

.tax-rate-section__rate__location {
  &::before {
    @include fa-icon(check);
    color: var(--rc-color-sentiment-positive-dark);
    margin-right: 1rem;
  }

  &.tax-rate-section__rate__location--automatic {
    &::before {
      color: var(--rc-color-palette-gray-500);
    }
  }
}

.tax-rate-section__rate__delete {
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
  margin-left: 1rem;

  &:hover {
    color: var(--rc-color-text-danger);
  }
}
