.progress-bar-total {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  background-color: var(--rc-color-background-page-secondary);
  border-radius: 10px;
  height: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.progress-bar-total--badge-bar {
    background-color: var(--rc-color-palette-gray-300);
  }
}

.progress-bar-percentage {
  background-color: var(--rc-color-sentiment-positive-dark);
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
}

.progress-bar-count {
  font-size: 12px;
  font-weight: bold;
  color: var(--rc-color-text-secondary);
  margin-top: 4px;

  &.progress-bar-count--complete {
    color: var(--rc-color-text-alert-success);
  }
}

.progress-bar-count__digits {
  font-size: 14px;
}

.polling-progress-pending {
  font-style: italic;
  margin-bottom: 0;
}

.polling-progress {
  margin: 4px;
}

.polling-progress-info {
  margin-top: 4px;

  %info-item {
    box-sizing: border-box;
    display: inline-block;
    padding: 0;
    vertical-align: top;
  }

  > .polling-progress-title {
    @extend %info-item;

    font-weight: 700;
    margin-right: -4px;
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50%;

    &:only-child {
      width: 100%;
    }
  }

  > .polling-progress-definition {
    @extend %info-item;

    width: 50%;
  }
}
