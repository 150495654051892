@use 'sass:color';

.label-with-checkbox {
  .ui-state-error[type='checkbox'] + label::before {
    border-color: var(--rc-color-border-alert-error);
  }
}

.styled-dropdown.ui-state-error {
  border-color: var(--rc-color-border-alert-error);
  background-color: var(--rc-color-background-alert-error);

  &:before,
  &::after {
    border-left-color: var(--rc-color-border-alert-error);
    background-color: var(--rc-color-background-alert-error);
  }
}

.input-group.ui-state-error {
  .input-group__text {
    border-color: var(--rc-color-border-alert-error);
    background-color: var(--rc-color-background-alert-error);
  }
}

.button {
  &.wysihtml5-command-active,
  &.wysihtml5-action-active {
    background-color: var(--rc-color-palette-blue-600);
    color: var(--rc-color-palette-bw-white);
    border-color: var(--rc-color-palette-blue-600);
  }
}
