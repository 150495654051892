// Generally DEPRECATED, please use design system classes for anything here

// Deprecated - use opacity classes or color--lighten from design system

.muted                { color: var(--rc-color-text-secondary); }

// Deprecated - use cursor- and color- classes from design system

.clickable {
  cursor: pointer;
  color: var(--rc-color-palette-blue-600);
}

@mixin selected-anchor {
  &.selected {
    color: var(--rc-color-text-accent);
    cursor: default;
    font-weight: bold;
  }
}

// Deprecated - use f-left responsive set from design system.
// These are !important so removing them would probably do something bad somewhere

.pull-left          { float: left !important; }
.pull-right         { float: right !important; }

// Deprecated - use the original design system classes for all of these

.vertical-top {
  vertical-align: top;
}

.vertical-middle {
  vertical-align: middle;
}

.vertical-bottom {
  vertical-align: bottom;
}

// use .pos-relative
.position-relative  {
  position: relative;
}

// use .pos-absolute
.position-absolute  {
  position: absolute;
}

// use .d-inline-block
.inline-block {
  display: inline-block;
}

// use .d-block
.display-block {
  display: block;
}

// use .d-none
.hidden {
  display: none;
}

// use .ws-nowrap
.nowrap {
  white-space: nowrap;
}

// use .ws-normal
.wrap {
  white-space: normal;
}

// legacy class, but has an !important so removal will require refactoring
.hide               { display: none !important; } // the silly version of .hidden

.width-full, .wide  { box-sizing: border-box; width: 100%; }

// Truncation
.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Formatting

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.separator-line {
  border-top: 1px solid var(--rc-color-border-primary);
  margin: 1rem 0;
}

.divider {
  margin-bottom: 20px;
  border-top: 1px solid var(--rc-color-border-primary);

  &.bold { border-width: 2px; }
  &.divider-light { border-color: var(--rc-color-border-primary); }
}

.bordered { border: 1px solid var(--rc-color-border-primary); }

.shadowed {
  @include box-shadow;
  background: var(--rc-color-background-page);
  border: 1px solid var(--rc-color-border-primary);
}

.separator-with-words {
  text-align: center;
  margin: 1rem auto 0;

  &:before {
    content: "";
    display: block;
    height: 1px;
    border-top: 1px solid var(--rc-color-border-primary);
  }

  .separator-with-words__text {
    background-color: var(--rc-color-background-page);
    width: 3rem;
    height: 2rem;
    line-height: 2rem;
    margin: -1rem auto 0;
  }

  &.separator-with-words--add-tracking {
    margin: 2rem auto 0;

    .separator-with-words__text {
      height: 3rem;
      line-height: 3rem;
      margin: -1.5rem auto 0;
    }
  }
}

.word-wrap-break-word {
  word-break: break-word;
  overflow-wrap: break-word;
}
