.gift-card-preview__message {
  background-color: var(--rc-color-palette-gray-100);
  border: solid 1px var(--rc-color-border-primary);
  padding: 12px;
  border-radius: 4px;
  position: relative;
  margin-top: 18px;

  @include mobile {
    margin-top: 16px;
  }

  p {
    &:last-child {
      margin: 0;
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }

  &::before {
    // uses pixels since the precision is needed on desktop
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
    border-top: solid 10px var(--rc-color-border-primary);
    left: 14px;
    bottom: -10px;

    @include mobile {
      border-left-width: 10px;
      border-right-width: 10px;
      border-top-width: 10px;
      left: 14px;
      bottom: -10px;
    }
  }

  &::after {
    border-left: solid 9px transparent;
    border-right: solid 9px transparent;
    border-top: solid 9px var(--rc-color-palette-gray-100);
    left: 15px;
    bottom: -9px;

    @include mobile {
      border-left-width: 9px;
      border-right-width: 9px;
      border-top-width: 9px;
      left: 15px;
      bottom: -9px;
    }
  }
}
