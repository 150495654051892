@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

.collection-hero-image {
  box-sizing: border-box;
  @include breakpoint-tablet-up { height: 220px; }
  @include mobile { height: 120px; }

  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto; // for old IE
  background-size: cover;
  background-color: black;
}

.body-app-store {
  &.body-installed-app {
    .body-wrapper {
      background-color: white;
    }

    .app-store-footer { display: none; }
  }

  > .body-wrapper > .container {
    padding: 0;
  }
}

.app-store {
  .app-store-apps-list {
    border-bottom: 1px solid var(--rc-color-border-primary);
    margin-bottom: 20px;

    &:only-of-type,
    &:last-of-type {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.app-store-logo-col {
  min-height: 1px;
  @include grid-column(1);
}

.app-store-heading-col {
  min-height: 1px;
  @include grid-column(11);

  @include mobile {
    @include grid-column(12);
  }
}

.app-store-developer-app-icon {
  .heading-1 {
    line-height: 36px;
  }
}

.resizeable-iframe {
  box-sizing: border-box;
  border: 0;
  width: 100%;
  overflow: hidden;
  min-height: 500px;
}

.app-store-header {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.app-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &:after {
    content: '';
    // make items in row with only 2 of 3 items always align to the left
    width: percentage(((100 - 6)/3)/100);
  }

  @include mobile {
    display: block;
  }
}

.app-list__item {
  display: flex; // get all items to stretch to the same height
  flex-basis: percentage(((100 - 6)/3)/100); // subtract 6% total gutter to create a 3% gutter between items
  box-sizing: border-box;
  margin-bottom: $grid-gutter;

  @include mobile {
    display: block; // ensure items with short description go full width on mobile
  }
}

.app-card {
  @extend .card;
  @include shadow-on-hover;
  background-color: var(--rc-color-palette-bw-white);
  color: var(--rc-color-text-primary);
  display: block;
  position: relative;
  width: 100%;

  &:hover {
    color: var(--rc-color-text-primary);
  }

  @include mobile {
    height: auto;
  }

  a {
    display: block;
  }

  .heading-4,
  .app-description {
    color: var(--rc-color-text-primary);
  }

  .app-status-badge {
    @include box-shadow;
    border-radius: 4px 0 0 4px;
    color: white;
    font-weight: 700;
    padding: 5px 8px;
    position: absolute;
    right: 0;
    top: 20px;

    &.draft {
      background-color: var(--rc-color-palette-gray-900);
    }

    &.submitted {
      background-color: var(--rc-color-palette-blue-600);
    }
  }

  .dropdown {
    position: absolute;
    right: 5px;
    top: 105px;

    .dropdown-menu li > a {
      padding: 0.25rem;
    }
  }
}

.app-description {
  @include truncate-text.truncate-text(
    $font-size: 13px,
    $line-height: 1.4,
    $lines-to-show: 2
  );

  margin-bottom: 0;
}

.app-info {
  padding: 1rem;
  @include mobile {
    height: auto;
  }

  &.app-info--additional {
    padding: 30px 15px;
    text-align: center;
  }
}

.app-header {
  position: relative;
  padding-bottom: percentage(1/3);
  border-bottom: 1px solid var(--rc-color-palette-gray-200);

  img {
    width: 100%;
    height: auto;
    display: block;
    transition: opacity .1s;
    border-radius: 6px 6px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    &:hover {
      opacity: 0.85;
    }
  }

  &.app-header--additional {
    background-color: var(--rc-color-palette-vu-orange-500);
    border-radius: 6px 6px 0 0;
  }
}

.app-header__free-callout {
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 2px 4px;
  line-height: 1;
  background: var(--rc-color-sentiment-positive-dark);
  color: var(--rc-color-palette-bw-white);
  border-radius: 3px;
  font-size: 12px;
}

.app-header__additional__title__wrap {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-header__additional__title {
  font-size: 20px;
  font-weight: bold;
  color: var(--rc-color-palette-bw-white);
}

.app-store-app-icon {
  height: 28px;
  width: 28px;

  &.app-show-page-icon {
    height: 32px;
    width: 32px;
  }
}

.app-store-app-screenshots {
  img {
    width: 100%;
  }
}

.app-store-hero-header {
  @extend .collection-hero-image;
  height: 180px;
  background-color: var(--rc-color-palette-vu-orange-500);
}

.app-store-banner-overlap {
  margin: -60px auto 2rem;
}
