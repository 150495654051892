@use 'sass:map';
@use 'sass:meta';
@use '../../config/configuration';

@use 'sass:math';

// used by rc-listing-grid and rc-listing-* cards as needed
// because those breakpoints are different than our universal ones
@mixin rc-listing-grid-columns($num) {

  $columns: (
    2: 688px,
    3: 960px, // this is equal to `tablet`
    4: 1176px
  );

  @include max-width(map.get($columns, $num)) {
    @content;
  }
}

@mixin min-width($width) {
  @media screen and (min-width: #{$width + 1}) {
    @content;
  }
}

@mixin max-width($width) {
  @media screen and (max-width: #{$width}) {
    @content;
  }
}

@mixin tablet {
  @include max-width(configuration.$site-width-mid) {
    @content;
  }
}

@mixin mobile {
  @include max-width(configuration.$site-width-narrow) {
    @content;
  }
}

@mixin widescreen {
  @include min-width(configuration.$site-width-max + 1) {
    @content;
  }
}

@function vw-equivalent($vw, $viewport) {
  @return math.div($vw, 100) * $viewport;
}

@mixin max-vw($attr, $vw) {
  @include min-width(configuration.$site-width-max) {
    #{$attr}: vw-equivalent($vw, configuration.$site-width-max);
  }
}

@mixin responsive($attr, $desktop, $tablet: false, $mobile: false) {

  // if given multiple attributes,
  // condense multiple attribute calls into 3 total media queries

  @if (meta.type-of($attr) == list) {
    @each $a in $attr {
      #{$a}: #{$desktop};
    }
    @if $tablet {
      @include tablet {
        @each $a in $attr {
          #{$a}: #{$tablet};
        }
      }
    }
    @if $mobile {
      @include mobile {
        @each $a in $attr {
          #{$a}: #{$mobile};
        }
      }
    }
  } @else {

    // otherwise just output 1 attr per query

    #{$attr}: #{$desktop};
    @if $tablet {
      @include tablet {
        #{$attr}: #{$tablet};
      }
    }
    @if $mobile {
      @include mobile {
        #{$attr}: #{$mobile};
      }
    }
  }
}
