.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--rc-color-palette-vu-orange-500);
  opacity: 1;
  width: 0;
  transition: none;

  &.loading-bar--active {
    transition: width 5s, opacity .2s 5s;
    width: 70%;
    opacity: 1;
  }

  &.loading-bar--complete {
    width: 100%;
    transition: width .4s, opacity .2s .4s;
    opacity: 0;
  }
}
