.video-playlist-list {
  > li.video-item {
    cursor: pointer;
    margin-bottom: $grid-gutter;

    @include breakpoint-tablet-up {
      @include grid-column(3, $cycle: true);
    }

    @include mobile {
      @include grid-column(6, $cycle: true);
    }

    &.playing {
      opacity: 0.5;
      cursor: default;
    }
  }

  .video-card {
    box-sizing: border-box;

    &:hover {
      .video-img {
        opacity: 0.85;
      }
    }

    .video-card-img {
      overflow: hidden;
      border: none;
      width: 100%;
      height: auto;
      display: block;

      &:hover {
        opacity: 0.85;
      }
    }

    .video-card-title {
      padding-top: 4px;
      color: var(--rc-color-text-primary);

      @include mobile {
        font-size: 12px;
      }
    }
  }
}

