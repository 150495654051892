.seller-ads {
  display: flex;
  margin: 0 -1rem;

  @include mobile {
    flex-wrap: wrap;
  }
}

.seller-ad {
  flex: 0 0 25%;
  box-sizing: border-box;
  padding: 1rem;

  @include mobile {
    flex: 0 0 50%;
  }

  a {
    @include shadow-on-hover;

    height: 100%;
    width: 100%;
    background: var(--rc-color-background-page);
    font-weight: bold;
    color: var(--rc-color-text-primary);
    box-sizing: border-box;
    border-radius: var(--rc-border-radius-primary);
    padding: 1.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.2;
  }

  img {
    display: block;
    margin-bottom: 1rem;
    height: auto;
    width: 40%;
    max-height: 8rem;
    max-width: 12rem;
  }
}
