@mixin overlay-gradient($hoverable: false, $start: 0%) {
  &:before,
  &:after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &:before {
    opacity: 0.8;
    background: linear-gradient(to bottom, var(--rc-color-shadow-0) $start, var(--rc-color-palette-gray-900) 100%);
  }

  @if $hoverable == true {
    &:before {
      transition: opacity .4s ease-in-out;
    }

    &:hover:before {
      opacity: 0.6;
    }
  }
}
