.file-upload {
  position: relative;
  overflow: hidden;

  input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    width: 200px;
    height: 34px;
    cursor: pointer
  }
}
