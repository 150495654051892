@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.accepted-listings {
  .accepted-listing {
    border-bottom-width: 0;
    border-radius: 0;

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
      border-bottom-width: 1px;
    }

    &:only-child {
      border-radius: 4px;
    }
  }
}

.accepted-listing {
  border-radius: 4px;
  border: solid 1px var(--rc-color-border-primary);
  padding: 14px;
  background-color: var(--rc-color-palette-bw-white);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    display: block;
    padding: 10px;
  }
}

.accepted-listing__details {
  display: flex;
}

.accepted-listing__thumbnail {
  flex: 0 0 40px;
  margin-right: 14px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.accepted-listing__title {
  font-size: 16px;
}

.accepted-listing__description {
  color: var(--rc-color-text-secondary);
  margin-bottom: 0;
}

.accepted-listing__actions {
  display: flex;

  @include mobile {
    display: block;
    margin-top: 1rem;
  }
}
.accepted-listing__view {
  @include rc-button.muted;

  @include mobile {
    margin-bottom: 1rem;
    width: 100%;
  }
}

.accepted-listing__pay-now {
  margin-left: 1rem;

  @include mobile {
    margin-left: 0;
  }
}
