// Styles for marketing pages
.pages {
  font-size: 1.6rem;
}

.intro-text {
  border-style: solid;
  border-width: 1px;
  padding: 30px;
  margin: 30px 70px 30px 70px;
}

.marketing-header {
  height: auto;
  background: url('../images/promo/marketing-hero-background.png') no-repeat;
  background-size: cover;
  color: var(--rc-color-palette-bw-white);
}

.marketing-header-title {
  margin-top: 3rem;
}

.marketing-header-title > h1 {
  font-size: 2.5rem;
}

.marketing-header-title > p {
  font-size: 1.5rem;
}

.marketing-full-background {
  background: var(--rc-color-palette-gray-100);
  border-top: 1px solid var(--rc-color-palette-gray-200);
  border-bottom: 1px solid var(--rc-color-palette-gray-200);
  width: 100%;
}

.marketing-full-background > h2 {
  @extend %cms-h2;
}

.marketing-section {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
}

.marketing-section > h3 {
  @extend %cms-h3;
}

@include mobile {
  .marketing-header {
    width: 100%;
  }

  .marketing-header > h1 {
    font-size: 1.8rem;
  }
}

.paywall-page {
  background-color: var(--rc-color-palette-bw-white);
  padding: 3rem 0;
}

.paywall-page__frame {
  @include site-wrapper;
  @include clearfix;
}

.paywall-header {
  display: flex;
  justify-content: center;
  margin-left: -1.5%;
  margin-right: -1.5%;

  @include mobile {
    display: block;
  }
}

.paywall-header__must-purchase,
.paywall-header__sign-in {
  flex: 1 0 50%;
  padding-left: 1.5%;
  padding-right: 1.5%;
  text-align: center;
}

.paywall-header__must-purchase {
  @include mobile {
    margin-bottom: 2rem;
  }
}

.paywall-header__title {
  font-size: 130%;
  font-weight: bold;
  margin-bottom: 0.5rem;

  @include mobile {
    font-size: 110%;
  }
}

.paywall-product-preview {
  width: 60%;
  margin: 0 auto;

  @include mobile {
    width: 100%;
    padding-bottom: 2rem;
  }
}

.paywall-product-preview__title {
  font-size: 160%;
  font-weight: bold;
  margin-bottom: 1rem;

  @include mobile {
    font-size: 120%;
  }
}

.paywall-header__title__text {
  font-weight: bold;
  margin-bottom: 0.5em;
}

.paywall-product-preview__image {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border: 1px solid var(--rc-color-palette-gray-200);
}

.paywall-product-preview__details {
  @include grid-container;
  margin-bottom: 1rem;
}

.paywall-product-preview__details__price-col {
  @include grid-columns(8, $mobile: 12);

  @include mobile {
    margin-bottom: 1rem;
  }
}

.paywall-product-preview__price {
  font-size: 200%;
  font-weight: bold;
  color: var(--rc-color-text-primary);

  @include mobile {
    text-align: center;
    font-size: 160%;
  }
}

.paywall-product-preview__details__view-col {
  @include grid-columns(4, $mobile: 12);
}

.paywall-product-preview__details__description {
  font-size: 110%;

  @include mobile {
    font-size: 90%;
  }
}
