// please don't use these; they're here for legacy support only

.heading-1 {
  font-size: 2em;
  font-weight: bold;
}

.heading-2 {
  font-size: 1.6em;
  font-weight: bold;
}

.heading-3 {
  font-size: 1.2em;
  font-weight: bold;
}

.heading-4 {
  font-size: 1.1em;
  font-weight: bold;
}
