.condensed-order-summary__titleblock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.condensed-order-summary__title {
  font-size: 2.2rem;
  font-weight: bold;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.condensed-order-summary__subtotal-wrapper {
  display: block;
}

.condensed-order-summary__subtotal-amount {
  font-weight: bold;
}
