.speech-bubble {
  position: relative;
  min-width: 17rem;
  padding: 1rem;
  background: var(--rc-color-background-page);
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: $input-border-radius;

  &.speech-bubble--error {
    margin-top: 1rem;
    font-weight: bold;
    border-color: var(--rc-color-border-alert-error);
    color: var(--rc-color-text-danger);
  }

  li {
    &:first-child > a:not(.btn) { border-radius: $input-border-radius $input-border-radius 0 0; }
    &:last-child > a:not(.btn) { border-radius: 0 0 $input-border-radius $input-border-radius; }
    & a:not(.btn):hover {
      transition: all 300ms linear;
      background: var(--rc-color-palette-gray-200);
    }
  }
}

.speech-absolute {
  .speech-bubble--error {
    position: absolute;
  }
}
