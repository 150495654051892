@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

// Temporary spot for this experiment
.listing-toggle {
  border: 0.1rem solid var(--rc-color-border-primary);
  @include scaling(margin-bottom, 1.5rem);

  background: var(--rc-color-palette-bw-white);
  overflow: hidden;
  width: 100%;
}

.listing-toggle__inner {
  @include scaling(padding, 2rem);
  padding-top: 0;
  padding-right: 5rem;
  position: relative;
  overflow: hidden;
  font-size: 0.9em;

  .listing-toggle--collapsed & {
    display: none;
  }

  @include mobile {
    padding-right: 3rem;
  }
}

.listing-toggle__heading {
  @include button-reset;
  @include scaling(padding, 2rem);
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg.icon-bg(angle-up, scss-tokens.$rc-color-palette-gray-500, scss-tokens.$rc-color-palette-vu-orange-500);

  width: 100%;
  text-align: left;
  background-repeat: no-repeat;
  background-position: calc(100% - 2rem) center;
  background-size: 2.5rem auto;
  cursor: pointer;
  padding-right: 5rem;

  h2 {
    font-size: 1.2em;
  }

  @include mobile {
    background-position: calc(100% - 1rem) center;
    padding-right: 3rem;
  }

  .listing-toggle--collapsed & {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    @include svg.icon-bg(angle-down, scss-tokens.$rc-color-palette-gray-500, scss-tokens.$rc-color-palette-vu-orange-500);

    background-repeat: no-repeat;
    background-size: 2.5rem auto;
    background-position: calc(100% - 2rem) center;

    @include mobile {
      background-position: calc(100% - 1rem) center;
    }
  }

  .listing-toggle--locked & {
    background-image: none;
    cursor: default;
  }
}

.listing-collapsing-list {
  td {
    padding: 0.2rem 0;
    vertical-align: top;

    &:first-child {
      @include responsive(width, 30%, 40%, 50%);

      box-sizing: border-box;
      padding-right: 1rem;
      font-weight: bold;
    }
  }

  tr:nth-of-type(n+5) {
    display: none;
  }

  &.listing-collapsing-list--expanded {
    tr:nth-of-type(n+5) {
      display: table-row;
    }
  }
}

.listing-collapsing-list__toggle {
  @include button-as-link;

  font-size: 0.9em;
  font-weight: bold;
  padding: 0.2rem 0;

  .listing-collapsing-list--expanded & {
    display: none;
  }
}
