.seller-blocked-message {
  padding: 36px;
  box-sizing: border-box;
  text-align: center;
  max-width: 300px;
}

.product-status {
  line-height: 1.2;

  .status {
    &.draft {
      color: var(--rc-color-text-accent);
    }

    &.live {
      color: var(--rc-color-text-success);
    }

    &.suspended {
      color: var(--rc-color-text-danger);
    }
  }

  @include mobile {
    text-align: center;
  }
}

.product-status__item {
  display: inline-block;
  margin-right: 1em;
  font-size: 0.8em;

  @include mobile {
    display: block;
    margin: 0 auto 0.5rem;
  }
}
