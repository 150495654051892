.affirm-dialog {
  max-width: 620px;
  box-sizing: border-box;
}

.affirm-inline-logo {
  background: url('../images/icons/affirm-icon.svg') no-repeat;
  display: inline-block;
  background-size: 100% auto;
  width: 84px;
  height: 34px;
  background-position: bottom center;

  &.affirm-small {
    @include breakpoint-tablet-up {
      width: 42px;
      height: 17px;
    }
  }
}

.affirm-promo-icon {
  background: var(--rc-color-palette-bw-white);
  display: block;
  width: 100%;
  height: auto;

  @include mobile {
    width: 30%;
    margin: 0 auto;
  }
}

.affirm-low-interest-banner-description {
  padding-right: 1rem;

  @include mobile {
    padding: 0;
  }
}

.affirm-promo-banner-accent {
  right: 0;
  top: 0;
  bottom: 0;
  width: 18px;
  background: var(--rc-color-vendor-affirm);
}

.affirm-promo-banner-title {
  font-size: 1.7rem;
  font-weight: bold;

  @include mobile {
    font-size: 1.6rem;
  }
}
