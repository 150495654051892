%fa-icon-base {
  display: inline-block;
  font-family: 'FontAwesome';
  font-weight: normal;
  font-style: normal;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}

