/* ==========================================================================
   $BASE-PICKER
   ========================================================================== */
/**
 * Note: the root picker element should __NOT__ be styled
 * more than what’s here. Style the `.picker__holder` instead.
 */
.picker {
  font-size: 16px;
  text-align: left;
  line-height: 1.2;
  color: #000000;
  position: absolute;
  z-index: 10000;
  width: 100%;
}
/**
 * The picker input element.
 */
.picker__input {
  cursor: default;
}
/**
 * When the picker is opened, the input element is “activated”.
 */
.picker__input.picker__input--active {
  border-color: #0089ec;
}
/**
 * The holder is the only “scrollable” top-level container element.
 */
.picker__holder {
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/*!
 * Classic picker styling for pickadate.js
 * Demo: http://amsul.github.io/pickadate.js/themes.htm#classic
 */

/**
 * The holder is the base of the picker.
 */
.picker__holder {
  position: absolute;
  background: #ffffff;
  border: 1px solid #aaaaaa;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 176px;
  max-width: 466px;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  max-height: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
  -webkit-transform: translateY(-1em) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-1em) perspective(600px) rotateX(10deg);
  transform: translateY(-1em) perspective(600px) rotateX(10deg);
  -webkit-transition: all 0.15s ease-out, max-height 0 0.15s;
  -moz-transition: all 0.15s ease-out, max-height 0 0.15s;
  transition: all 0.15s ease-out, max-height 0 0.15s;
}
/**
 * When the picker opens...
 */
.picker--opened .picker__holder {
  max-height: 25em;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  -moz-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0);
  -webkit-transition: all 0.15s ease-out, max-height 0;
  -moz-transition: all 0.15s ease-out, max-height 0;
  transition: all 0.15s ease-out, max-height 0;
  -webkit-box-shadow: 0 6px 18px 1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 6px 18px 1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 6px 18px 1px rgba(0, 0, 0, 0.12);
}
