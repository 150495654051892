.dialog.payments-dialog {

  .payments-dialog-totals {
    @include clearfix;

    border-radius: $input-border-radius;
    background-color: var(--rc-color-palette-blue-100);
    color: var(--rc-color-palette-blue-700);
    margin: 9px auto 18px auto;
    max-width: 944px;
    padding: 18px;
    line-height: 1.5;

    p {
      margin-bottom: 0;
    }

    .btn {
      font-size: 1.4rem;
    }

    .subtext {
      font-weight: normal;
      font-size: 1.4rem;
      margin-top: 0.5em;
    }
  }

  .payments-dialog-list {
    dt, dd {
      box-sizing: border-box;

      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      width: 50%;
    }

    dd {
      text-align: right;
    }

    &.strong-dd {
      dd {
        font-weight: 700;
      }
    }

  }
}
