@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';
@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/tools/mixins/links';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.messages-show {
  border: none;
  padding: 0;

  .user-conversation {
    padding: var(--rc-space-10) 0;
  }
}

.messages-show__back-link {
  @include links.text-link;
}

.messages-show__heading {
  &.heading-3 {
    @include rc-text.title('900');
    display: flex;
    align-items: center;
    gap: var(--rc-space-3);
    margin: var(--rc-space-8) 0;
  }
}

.messages-show__product-summary {
  // the link around the thumbnail
  .g-col-2 > a {
    display: block;
    border-radius: var(--rc-border-radius-lg);
    overflow: hidden;
    background-color: var(--rc-color-palette-gray-100);

    @include mobile {
      border-radius: var(--rc-border-radius-sm);
    }
  }
}

.message-order-summary {
  @include mobile { margin: 2rem 0; }

  td {
    text-align: center;

    @include mobile {
      display: block;
      text-align: left;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.message {
  padding: 14px;
  font-size: 1.6rem;

  &.user {
    background: var(--rc-color-palette-gray-300);
  }

  p {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    @include hyphenate;
  }
}

.message-author-time {
  font-size: 1.2rem;
  color: var(--rc-color-palette-gray-500);
  font-weight: bold;

  li {
    display: inline-block;
    padding-right: 6px;
  }
}

.shop-icon-link {
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg.icon-before(shop, scss-tokens.$rc-color-palette-blue-600);

  &:before {
    margin-right: 0.2em;
  }

  // handle color change on link hover
  &:hover {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    @include svg.icon-before(shop, scss-tokens.$rc-color-text-accent);

    &:before {
      margin-right: 0.2em;
    }
  }
}

.message-attach-images {
  @include fa-icon-before(picture-o);

  display: inline-block;
}

.message-attach-listings {
  label {
    display: none;
  }
}

.message-attach-listings__link {
  @include scaling(margin-right, 2em);
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg.icon-before(listing-icon, scss-tokens.$rc-color-palette-blue-600);

  display: inline-block;

  @include mobile {
    margin-bottom: 0.5em;
  }

  &:hover {
    // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
    @include svg.icon-before(listing-icon, scss-tokens.$rc-color-text-accent);
  }
}

.message-form__listing-preview {
  padding: 15px;
  border: 1px solid var(--rc-color-palette-gray-200);
  margin-bottom: 10px;
}

.message-form__contents {
  background-color: var(--rc-color-palette-bw-white);
  border: 1px solid var(--rc-color-border-primary);
  border-radius: 4px;
  margin-bottom: 10px;

  textarea {
    display: block;
    border: none;
    box-shadow: none;
  }

  &.message-form__contents--focused {
    border-color: var(--rc-color-palette-blue-600);
  }
}

.message-form__contents__attachments {
  margin: 10px;
  margin-bottom: 0;
}
