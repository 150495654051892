.sale-card {
  @include shadow-on-hover;

  flex: 0 0 48.5%;
  box-sizing: border-box;
  margin-bottom: $grid-gutter;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  @include mobile {
    flex: 0 0 100%;
  }
}

.featured-sale-card {
  @include shadow-on-hover;

  display: block;

  + .featured-sale-card {
      @include scaling(margin-top, 2rem);
  }
}
