.dashboard-list {
  margin-bottom: 2rem;
  font-size: 1.4rem;

  .site-module & {
    margin-bottom: 0;
  }
}

.dashboard-list-item {
  border-radius: 4px;
  background-color: var(--rc-color-palette-gray-100);
  border: 2px solid var(--rc-color-palette-gray-200);
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;

    @include mobile {
      margin-bottom: 0;
    }
  }
}

.dashboard-list-item__type-header {
  background-color: var(--rc-color-palette-gray-200);
  padding: 0.5em;
}

.dashboard-list-item__type-label {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1.2;

  &.dashboard-list-item__type-label--seller-site {
    @include fa-icon-before(desktop);
  }
}

.dashboard-list-item__content {
  padding: 0.5em;
}
