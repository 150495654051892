@use '../../config/configuration';
@use '../mixins/links';
@use '../mixins/responsive';
@use '../mixins/scaling';

%cms-heading {
  @include responsive.responsive(margin-top, 1.5em, 1.2em, 1em);
  @include responsive.responsive(margin-bottom, 1em, 0.9em, 0.8em);

  font-weight: configuration.$cms-heading-font-weight;
}

%cms-h1 {
  @extend %cms-heading;

  font-size: 2em;
}

%cms-h2 {
  @extend %cms-heading;

  font-size: 1.6em;
}

%cms-h3 {
  @extend %cms-heading;

  font-size: 1.2em;
}

%cms-h4 {
  @extend %cms-heading;

  font-size: 1.1em;
  font-weight: bold;
}

%cms-h5 {
  @extend %cms-heading;

  font-weight: bold;
  text-transform: uppercase;
}

%cms-h6 {
  @extend %cms-heading;

  font-size: 0.9em;
  font-weight: bold;
  text-transform: uppercase;
}

%cms-hr {
  //  accounting for legacy markup here by resetting styles
  margin: 0 auto;
  padding: 0;
  border: none;

  // stylelint-disable-next-line order/order
  @include scaling.scaling((padding-top margin-bottom), 4rem);

  clear: both;
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
}

%cms-blockquote {
  padding: 0 1em;
  border-left: 0.4rem solid var( --rc-color-palette-vu-orange-500);
  margin-bottom: 1.5em;
}

%cms-ul {
  @include scaling.scaling((margin-left margin-right), 3em);

  margin-bottom: 1.5em;
}

%cms-ul-li {
  list-style-type: disc;

  + li {
    margin-top: 0.5em;
  }
}

%cms-ol {
  @include scaling.scaling(margin-left, 3em);
  @include scaling.scaling(margin-right, 3em);

  counter-reset: li;
  position: relative;
  margin-bottom: 1.5em;
}

%cms-ol-li {
  list-style-type: none;

  + li {
    margin-top: 0.5em;
  }

  &::before {
    position: absolute;
    display: inline-block;
    content: counter(li);
    counter-increment: li;
    font-weight: bold;
    text-align: right;
    right: calc(100% + 0.5em);
  }
}

%cms-dl {
  margin-bottom: 1.5em;
}

%cms-dt {
  font-weight: bold;
  font-size: 0.9em;
  margin-bottom: 0.3em;
}

%cms-dd {
  margin-left: 1em;

  + dd {
    margin-top: 0.2em;
  }

  + dt {
    margin-top: 1em;
  }
}

%cms-img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

%cms-base {
  > hr {
    @extend %cms-hr;
  }

  > h1 {
    @extend %cms-h1;
  }

  > h2 {
    @extend %cms-h2;
  }

  > h3 {
    @extend %cms-h3;
  }

  > h4 {
    @extend %cms-h4;
  }

  > h5 {
    @extend %cms-h5;
  }

  > h6 {
    @extend %cms-h6;
  }

  > dl {
    @extend %cms-dl;

    > dt {
      @extend %cms-dt;
    }

    > dd {
      @extend %cms-dd;
    }
  }

  > ul {
    @extend %cms-ul;

    > li {
      @extend %cms-ul-li;
    }
  }

  > ol {
    @extend %cms-ol;

    > li {
      @extend %cms-ol-li;
    }
  }

  > img {
    @extend %cms-img;
  }

  > p {
    > img {
      @extend %cms-img;
    }

    > a {
      // we need an (albeit) specific selector here to ensure we're not doubling up styles
      // on things that already have some assigned via `class` attr
      &:not([class]) {
        @include links.text-link;
      }
    }
  }

  > blockquote {
    @extend %cms-blockquote;
  }
}
