.shop-tiles {
  @include grid-container;

  @include mobile {
    padding: 0 10px;
  }
}

.shop-tile {
  @include grid-column(4, $cycle: true);

  @include min-width(1100px) {
    @include grid-column(3, $cycle: true);
  }

  @include mobile {
    @include grid-column(12, $cycle: true);
  }

  background: var(--rc-color-palette-bw-white);
  border-radius: var(--rc-border-radius-primary);
  border: 0.1rem solid var(--rc-color-border-primary);
  transition: box-shadow 0.2s;

  > a {
    display: block;
    color: var(--rc-color-text-primary);
  }

  &:hover {
    @include box-shadow;
  }
}

.shop-tile__details {
  padding: var(--rc-space-4);
}

.shop-tile__image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  border-top-left-radius: var(--rc-border-radius-primary);
  border-top-right-radius: var(--rc-border-radius-primary);

  @include mobile {
    padding-bottom: 33%;
  }
}

.shop-tile__achievements {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--rc-color-highlight-700);
  padding: var(--rc-space-2) var(--rc-space-4) 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--rc-space-2);

}

.shop-tile__rating {
  flex: 1;
  font-size: 0.9em;
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
}

.shop-tile__badges {
  display: flex;
  flex: 1;

  .tooltip {
    vertical-align: middle;
  }

  a {
    display: block;
  }
}

.shop-tile__listings {
  @include clearfix;
  background: var(--rc-color-palette-gray-100);
}

.shop-tile__listing {
  float: left;
  width: 50%;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @include mobile {
    width: percentage(1/3);
  }
}

.shop-tile__title {
  font-weight: bold;
  padding-bottom: 0.2em;
  height: 1.1em;
  overflow: hidden;
  font-size: 1.6rem;
  line-height: 1.1;
}

.shop-tile__location {
  font-size: 0.9em;
  font-weight: bold;
  opacity: .5;
}
