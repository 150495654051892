.payment-method-selector-condensed {
  @include radio;

  + .payment-method-selector-condensed {
    margin-top: 1rem;
  }

  label[for='payment_method_direct_checkout'] {
    &:before {
      margin-top: 0.1rem; // to center it relative to payment icon
    }
  }

  .payment-icon {
    width: 2.6em;
  }
}

.payment-method-selector-condensed__inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    display: block;
  }
}

.payment-method-selector-condensed__icons {
  flex: 0 0 auto;
  margin-left: 2rem;

  @include mobile {
    margin: 0.5rem 0 0 0;
  }
}

.payment-method-selector-condensed__bucks {
  display: none;
  border: 0.1rem solid var(--rc-color-sentiment-positive-light);
  margin: 0.5rem 0;
  float: left;
  align-items: center;

  input[checked] + label & {
    display: flex;
  }
}

.payment-method-selector-condensed__bucks__amount {
  background: white;
  padding: 0.5rem;
  padding-right: 0;
  flex: 0 0 auto;
  font-weight: bold;
}

.payment-method-selector-condensed__bucks__text {
  background: var(--rc-color-sentiment-positive-lighter);
  padding: 0.5rem;
}
