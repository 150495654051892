// Pointer Values
//
.tooltip {
  &:hover { text-decoration: none; }

  &.tooltip--underline {
    border-bottom: dashed 0.1rem currentColor;
  }
}

span.tt {
  font-size: 1.6rem;
}

#powerTip {
  font-size: 1.4rem;
  font-weight: bold;
  background-color: rgba(47, 47, 47, 0.9);
  padding: 6px 10px;
  border-radius: 4px;
}

.pointer-body {
  @include box-shadow;
  position: absolute;
  padding: 0.6rem 1rem;
  text-align: left;
  color: var(--rc-color-text-primary);
  background: var(--rc-color-background-module);
  border: 0.1rem solid var(--rc-color-border-primary);
  margin-top: 5px;
  border-radius: var(--rc-border-radius-primary);
  z-index: 1;

  &:before { display: none; }

  &.no-shadow {
    border: 1px solid var(--rc-color-border-primary);
    box-shadow: none;

    &:before { display: block; }
  }

  &.with-border:before { display: block; }
}

// Caret location/direction

$pointer-width: 8px;
$pointer-width-mobile: $pointer-width*2;

[class*=pointer-]:after,
[class*=pointer-]:before {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.pointer-top {
  &:after {
    bottom: 100%;
    border-color: rgba(244, 248, 249, 0);
    border-bottom-color: var(--rc-color-background-module);
    border-width: $pointer-width;
    @include mobile { border-width: $pointer-width-mobile; }
  }
  &:before {
    bottom: 100%;
    border-color: rgba(214, 225, 228, 0);
    border-bottom-color: var(--rc-color-border-primary);
    border-width: $pointer-width + 2px;
    @include mobile { border-width: $pointer-width-mobile + 2px; }
  }
  &.pointer-top-gray:after {
    border-bottom-color: var(--rc-color-background-module);
  }
}

.pointer-bottom {
  &:after {
    top: 100%;
    border-color: rgba(244, 248, 249, 0);
    border-top-color: var(--rc-color-background-module);
    border-width: $pointer-width;
    @include mobile { border-width: $pointer-width-mobile; }
  }
  &:before {
    top: 100%;
    border-color: rgba(214, 225, 228, 0);
    border-top-color: var(--rc-color-border-primary);
    border-width: $pointer-width + 2px;
    @include mobile { border-width: $pointer-width-mobile + 2px; }
  }
}

.pointer-left {
  &:after {
    left: 10%;
    margin-left: -($pointer-width / 2);
    @include mobile { margin-left: -($pointer-width-mobile / 2); }
  }
  &:before {
    left: 9.7%;
    margin-left: -(($pointer-width + 2) / 2);
    @include mobile { margin-left: -(($pointer-width-mobile + 2) / 2); }
  }
}

.pointer-right {
  &:after {
    right: 10%;
    margin-right: -($pointer-width / 2);
    @include mobile {
      margin-right: -($pointer-width-mobile / 2);
      margin-bottom: -1px;
    }
  }
  &:before {
    right: 9.7%;
    margin-right: -(($pointer-width + 2) / 2);
    @include mobile { margin-right: -(($pointer-width-mobile + 2) / 2); }
  }
}

.pointer-center {
  &:after {
    left: 50%;
    transform: translateX(-50%);
  }
  &:before {
    left: 50%;
    transform: translateX(-50%);
  }
}

[data-react-class="Reverb.Tooltip"] div {
  display: inline;
}
