@use '@reverbdotcom/cadence/styles/tools/mixins/links';

.feedback-list {
  .readmore-js-toggle { font-size: 12px; }

  .g-col-2.bd-1.bd--offwhite {
    border: none;

    a {
      display: block;
      background-color: var(--rc-color-palette-gray-100);
      border-radius: var(--rc-border-radius-lg);
      overflow: hidden;
    }

    img {
      display: block;
      border-radius: var(--rc-border-radius-lg);
    }
  }

  .g-col-10 {
    // target for the title in the block
    .strong > a {
      @include links.text-link;
    }
  }

  > .g-container {
    margin-top: var(--rc-space-4);
  }
}

.feedback-received-list {
  display: flex;

  @include mobile {
    flex-direction: column;
  }
}

.feedback-received-list__list-col {
  flex: 8;
  margin-right: 2rem;

  @include mobile {
    flex: 1;
    order: 2;
    margin-right: 0;
  }
}

.feedback-received-list__summary-col {
  flex: 4;

  @include mobile {
    flex: 1;
    order: 1;
    margin-bottom: 1rem;
  }
}
