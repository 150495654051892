@use '@reverbdotcom/cadence/styles/config/scss-tokens';
// stylelint-disable cadence/no-scss-tokens -- Needs scss var for legacy support
html.pdf-layout {
  font-family: scss-tokens.$rc-font-family-page;

  body {
    font-family: scss-tokens.$rc-font-family-page;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: scss-tokens.$rc-font-family-heading;
  }
}
