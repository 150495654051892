// Everything here uses pt units since they mirror dimensions in px better when printing
// ie 100px in Sketch == 100pt in a print

.packing-slip {
  font-size: 10pt;
  font-family: $lato-font-family;

  // to help mitigate additional blank pages printed
  > :last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.packing-slip__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 36pt;
}

.packing-slip__seller {
  display: flex;
}

.packing-slip__seller-avatar {
  img {
    display: block;
    height: auto;
    width: 40pt;
  }
}

.packing-slip__seller-info {
  margin-left: 12pt;
}

.packing-slip__seller-name {
  font-size: 16pt;
}

.packing-slip__seller-url {
  margin-bottom: 4pt;
}

.packing-slip__seller-address {
  margin-bottom: 0;
}

.packing-slip__tag {
  display: flex;
  align-items: center;

  img {
    display: block;
    width: 28pt;
    height: auto;
  }
}

.packing-slip__info-blocks {
  @include clearfix;
  margin: 0 0 36pt;
}

.packing-slip__info-block {
  display: flex;
  box-sizing: border-box;
  float: left;
  width: 50%;
  padding: 0 18pt;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.packing-slip__info-block-label {
  font-size: 16pt;
  font-weight: normal;
  margin: 0 24pt 0 0;
  flex-shrink: 0;
}

.packing-slip__items {
  width: 100%;
  margin-bottom: 36pt;
}

.packing-slip__items-header {
  th {
    font-size: 8pt;
    font-weight: 700;
    white-space: nowrap;

    &:first-child {
      text-align: left;
    }
  }
}

.packing-slip__item-col {
  padding: 8pt;
  border-bottom: solid 1pt var(--rc-color-palette-gray-200);
  text-align: right;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &.packing-slip__item-col--thumb {
    width: 30pt;
    vertical-align: top;
    text-align: left;
  }

  &.packing-slip__item-col--details {
    text-align: left;
  }
}

.packing-slip__item-col--shipping,
.packing-slip__item-col--storage-number,
.packing-slip__item-col--condition,
.packing-slip__item-col--detail,
.packing-slip__item-col--qty {
  font-size: 11pt;
  white-space: nowrap;
}

.packing-slip__item__thumb {
  display: block;
  width: 30pt;
  height: auto;
}

.packing-slip__item__title {
  font-size: 12pt;
  font-weight: 700;
}

.packing-slip__item__condition {
  display: block;
  white-space: nowrap;

  &::before {
    content: '';
    display: inline-block;
    height: 1em;
    width: 1em;
    background-size: 11pt auto;
    background-repeat: no-repeat;
    background-position: 0 center;
  }

  &.packing-slip__item__condition--media::before {
    background-image: svg-url(media, 10%);
  }

  &.packing-slip__item__condition--sleeve::before {
    background-image: svg-url(sleeve, 10%);
  }
}

.packing-slip__item__order-number,
.packing-slip__item__sku {
  margin-bottom: 0;
}

.packing-slip__footer-blocks {
  @include clearfix;
  margin: 0 0 36pt;
}

.packing-slip__footer-block {
  box-sizing: border-box;
  float: left;
  width: 50%;
  padding: 0 18pt;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.packing-slip__footer-block__title {
  font-size: 16pt;
  margin-bottom: 6pt;
  font-weight: normal;
  text-transform: none;
}

.packing-slip__footer-block__content {
  font-size: 12pt;
}

.packing-slip__payment-table {
  width: 100%;
  font-size: 12pt;
  margin: -3pt 0; // to offset the paddings on the tds

  td {
    padding: 3pt 0;
  }

  .top-bordered {
    border-top: solid 1pt var(--rc-color-palette-gray-200);
  }

  .invoice-total {
    font-weight: 700;

    .price-currency {
      font-weight: normal;
    }
  }
}

.packing-slip__thank-you {
  margin-bottom: 36pt;
  padding: 0;
  font-weight: normal;
  font-size: 16pt;
  text-align: center;
}

.packing-slip__footer-image {
  display: block;
  width: 100%;
  height: auto;
}
