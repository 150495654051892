.gift-card-callout {
  @include box-shadow;
  display: flex;
  align-items: center;
  background: var(--rc-color-palette-bw-white);
  padding: 10px 20px;
  margin-bottom: $grid-gutter;
  border-radius: 4px;

  &:before {
    display: block;
    flex: 0 1 auto;
    @include fa-icon(gift);
    color: var(--rc-color-text-price-nudge);
    font-size: 4em;
    padding-right: 20px;
  }

  @include mobile {
    padding: 10px;

    &:before {
      font-size: 2em;
      padding-right: 10px;
    }
  }
}

.gift-card-callout__info {
  h1 {
    color: var(--rc-color-text-price-nudge);
    font-size: 180%;

    @include mobile {
      font-size: 100%;
      line-height: 1.1;
      font-weight: bold;
    }
  }

  p {
    font-size: 130%;

    @include mobile {
      font-size: 80%;
      line-height: 1.1;
      font-weight: bold;
    }
  }
}

.gift-card-callout__action {
  flex: 1;
  text-align: right;
}
