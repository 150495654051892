$border-radius-sm: 0.4rem;
$border-radius-md: 0.8rem;
$border-radius-lg: 1.6rem;
$border-radius-xl: 2.4rem;
$border-radius-full: 100rem; // Overlay large to fully round edge cases (like buttons, etc)

$spacing-xs: 0.2rem;
$spacing-sm: 0.4rem;
$spacing-md: 0.8rem;
$spacing-lg: 1.6rem;
$spacing-xl: 2.4rem;
$spacing-xxl: 3.2rem;

$input-border-radius: $border-radius-sm !default;
$input-bottom-margin: 1.2rem !default;

$tag-a-hover-adjustment: 0% !default;

// Projects that import commons should override this to point to the root @reverbdotcom/commons dir
// so that url() statements resolve correctly
$commons-path: '../..' !default;

// these values should not change unless we are making major site changes

$body-font-family-fallback: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$mono-font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace; // same as Github
$lato-font-family: 'Lato', sans-serif; // For exclusive use with PDF generating styles since Hendrix can't parse CSS custom properties

$body-font-size: 1.6em;
// superseded by token line-height-default
$body-line-height: 1.4;

$tag-p-margin-bottom: 1.5em;
$tag-p-line-height: 1.6;

// superseded by token line-height-title
$tag-heading-line-height: 1.2;

$tag-label-font-size: 1.4rem;
$tag-label-font-weight: bold;

$input-font-size: 1.6rem;
$input-padding-vertical: 0.5rem;
$input-padding-horizontal: $spacing-md;
$input-line-height: 1.5;

$button-padding-vertical: 0.4rem;
$button-padding-horizontal: 1.8rem; // half of button height
$button-font-size: $input-font-size;
$button-font-size-large: 1.9rem;
$button-font-size-small: 1.3rem;
$button-font-size-mini: 1.2rem;
$button-border-width: 0.2rem;
$button-border-radius: $border-radius-full; // Overlay large to cover edge cases

$cms-heading-font-weight: 400;

// used by responsive.scss to set breakpoint sizes

$site-width-max: 1440px;
$site-width-mid: 960px;
$site-width-narrow: 640px;
$site-width-tiny: 374px;
$site-width-cms: 820px;

$spacing-base: $spacing-sm;
$spacing-space-amount: 0.25em;

$icon-inline-spacing: 0.5em;

$button-disabled-opacity: 0.5;

$grid-count: 12;
$grid-gutter: 2%;

$scaling-multiplier: 0.75;

$header-height: 11.2rem;

$font-weights: (
  light: 300,
  normal: 400,
  semibold: 600,
  bold: 700
);

$svg-rendering: 'geometricPrecision';
$svg-scale: 0.9em;
$svg-y-offset: 0.1em;

$checkbox-size: 20px;

$switch-size: 20px;

$radio-size: 20px;

$tiles-margin-bottom-one-wide: 1rem;

// Do NOT rename these unless Adyen renames them, or things will break
$adyen-card-brands: (
  amex,
  cup,
  diners,
  discover,
  ideal,
  jcb,
  klarna,
  maestro,
  mc,
  sepadirectdebit,
  sofort,
  visa
);

$non-adyen-payment-methods: (
  affirm,
  apple-pay,
  error,
  google-pay,
  paypal,
  placeholder,
  reverb-bucks,
  reverb-credits,
);

$payment-methods: (
  $adyen-card-brands,
  $non-adyen-payment-methods
);
