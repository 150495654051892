.shop-listing-count {
  box-sizing: border-box;

  background-color: var(--rc-color-background-page-secondary);
  display: block;
  padding: 21px 0;
  height: 86px;
  text-align: center;

  .number {
    font-size: 18px;
  }
}

