.lp-checkout-header {
  @include scaling(padding-top, 30px);
  @include scaling(padding-bottom, 30px);
  @include box-shadow;
  background: var(--rc-color-palette-bw-white);

  img {
    display: block;
    width: 260px;
    margin: 0 auto;
  }
}
