@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.blog-subpage-header {
  @include site-wrapper;
  background: var(--rc-color-background-page);
  margin-top: var(--rc-space-8);
  margin-bottom: var(--rc-space-8);

  h1 {
    @include rc-text.display;
    color: var(--rc-color-text-primary);
  }
}
