.order-tracking-link {
  display: block;
}

.digital-download-actions-wrapper {
  display: flex;
  float: right;

  @include mobile {
    float: none;
    width: 100%;
  }

  .loading-component {
    margin: 0;
  }
}

.order-actions {
  .digital-download-actions {
    .download-btn {
      margin-left: 0;
    }
  }
}

.payout-subtext {
  font-size: 12px;
}

// ensures consistent layout when a grid-col is empty
.column-spacer {
  min-height: 1px;
}
