.offer-confirmation {
  background-color: var(--rc-color-background-module-muted);
  border: solid 1px var(--rc-color-border-primary);
  border-radius: 4px;
  padding: 14px;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  &.offer-confirmation--accepted {
    border-color: var(--rc-color-border-alert-success);
  }

  &.offer-confirmation--declined {
    border-color: var(--rc-color-border-alert-error);
  }
}

.offer-confirmation__title {
  font-size: 18px;

  .offer-confirmation--accepted & {
    color: var(--rc-color-text-success);
  }

  .offer-confirmation--declined & {
    color: var(--rc-color-text-danger);
  }
}

.offer-confirmation__description {
  font-size: 16px;

  @include mobile {
    font-size: 14px;
  }
}

.offer-confirmation__items {
  margin-top: 14px;
}
