@keyframes popin {
  0%   { transform: scale(0); opacity: 0; }
  80%  { transform: scale(1.1); opacity: 1;}
  100% { transform: scale(1); }
}

@keyframes popout {
  0%   { transform: scale(1); }
  20%  { transform: scale(1.1); opacity: 1; }
  100% { transform: scale(0); opacity: 0; }
}

@keyframes rotate-popin {
  0%   { transform: rotate(-45deg) scale(0); }
  100% { transform: rotate(-45deg) scale(1); }
}

@keyframes bar-fill {
  0%   { width: 0; transform: translate3d(0, 0, 0); }
  100% { width: 100%; transform: translate3d(0, 0, 0); }
}

@keyframes stretchdelay {
  0%, 100% {
    transform: scaleY(0.4);
  }  15% {
    transform: scaleY(1.0);
  }
}

@keyframes slide-down {
  0%   { transform: translate3d(0, -100%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

@keyframes slide-up {
  0%   { transform: translate3d(0, 0, 0); }
  100% { transform: translate3d(0, -100%, 0); }
}

@keyframes rock-out {
  0%  { transform: rotate3d(0,0,1,0deg); }
  5%  { transform: rotate3d(0,0,1,-8deg); }
  15% { transform: rotate3d(0,0,1,8deg); }
  25% { transform: rotate3d(0,0,1,-8deg); }
  35% { transform: rotate3d(0,0,1,7deg); }
  45% { transform: rotate3d(0,0,1,-6deg); }
  55% { transform: rotate3d(0,0,1,6deg); }
  65% { transform: rotate3d(0,0,1,-5deg); }
  75% { transform: rotate3d(0,0,1,5deg); }
  85% { transform: rotate3d(0,0,1,0deg); }
}

@keyframes fade-in-up {
  0%   { opacity: 0; transform: translate3d(0, 100%, 0); }
  100% { opacity: 1; transform: none; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes loadingPlaceholderGradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.fadeIn { animation: fadeIn 0.5s linear both; }
