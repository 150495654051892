.panel-container {
  position: relative;
  display: inline-block;
}

.panel-popout {
  background-color: var(--rc-color-background-module);
  width: 350px;
  min-height: 40px;
  border-radius: var(--rc-border-radius-primary);
  position: absolute;
  border: 1px solid var(--rc-color-border-primary);
  text-align: left;
  top: 100%;
  margin-top: 10px;

  &.panel-align-left {
    left: 0;
  }

  &.panel-align-right {
    right: 0;
  }

  @include mobile {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--rc-color-background-module);
    box-sizing: border-box;
    overflow-y: auto;
    z-index: 99000000;
    border-radius: 0;
    margin-top: 0;

    &:before {
      display: none;
    }
  }
}

.panel-content {
  padding: 2rem;

  &.panel-content--bump {
    padding: 0;
  }
}

.panel-content__inner {
  padding: 2rem;
}

.panel-content__footer {
  padding: 1rem 2rem;
  background-color: var(--rc-color-background-module-secondary);
}

.panel-popout-list-menu {
  padding: 0.4rem 0;

  a {
    display: block;
    padding: 0.6rem 1.4rem;
    color: var(--rc-color-text-primary);

    @include mobile {
      line-height: 1.4rem;
      padding: 1.4rem 1.4rem;
    }

    @include breakpoint-tablet-up {
      &:hover { background: var(--rc-color-background-module-hovered); }
    }
  }
}

.seller-action-menu {
  @include breakpoint-tablet-up {
    width: 140px;
  }
}

.small-popout {
  width: 250px;
  @include mobile { width: 100%; }
}

.pg-transactions-panel {
  width: 340px;
  @include mobile { width: 100%; }
}

.panel-popout-mobile-header {
  display: none;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 1rem 4.4rem;
  background-color: var(--rc-color-palette-gray-200);
  border-bottom: 1px solid var(--rc-color-border-primary);
  position: relative;
  min-height: 4.4rem;

  @include mobile {
    display: flex;
  }
}

.panel-popout-mobile-header__title {
  font-size: 1.6rem;
  font-weight: 700;
}

.panel-popout-mobile-header__close {
  @include button-reset;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 4.4rem;
  height: 4.4rem;
  max-height: 100%;
  font-size: 1.6rem;

  &:hover {
    background-color: var(--rc-color-palette-gray-300);
  }

  &::after {
    @include fa-icon(close);
    display: block;
  }
}
