// Please don't use these classes;
// use the design system equivalents instead
// (all extended here for reference)

// use .clear-both
.clear {
  clear: both;
}

// use align-center
// for margin, use mlr-auto
.center {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

// these are all legacy and will be phased out
// don't use them, please and thank you

.margined-top {
  margin-top: 2rem;
}

.halfmargined-top {
  margin-top: 1rem;
}

.margined-bottom {
  margin-bottom: 2rem;
}

.halfmargined-bottom {
  margin-bottom: 1rem;
}

.tri-margined-top {
  margin-top: 3rem;
  clear: both;
}

.tri-margined-bottom {
  @include clearfix;
  margin-bottom: 3rem;
}

.extramargined-bottom {
  @include clearfix;
  margin-bottom: 2rem;
}

.extramargined-top,
.double-margined-top {
  @include clearfix;
  margin-top: 2rem;
}

.margined-right {
  margin-right: 1rem;
}

.double-margined-right {
  margin-right: 2rem;
}

.margined-left {
  margin-left: 2rem;
}

.tri-margined-left {
  margin-left: 3rem;
}

.halfmargined-left {
  margin-left: 0.5rem;
}

.halfmargined-right {
  margin-right: 0.5rem;
}

.well {
  @include clearfix;
  background: var(--rc-color-palette-bw-white);
  border: 0.1rem solid var(--rc-color-border-primary);
}

.unpadded {
  padding: 0;
}
