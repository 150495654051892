.card {
  @include clearfix;
  @include box-shadow;
  box-sizing: border-box;
  background: var(--rc-color-background-card);
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-primary);
}

.top-brands {
  .product-card {
    @include mobile { margin-bottom: 28px; }
  }
}

.panel {
  @include clearfix;

  background: var(--rc-color-background-module-secondary);
  border-radius: var(--rc-border-radius-md);
  padding: 0.5em;
  border: 1px solid var(--rc-color-border-primary);

  &.dark-panel {
    background: var(--rc-color-palette-gray-200);
  }
}

.share-panel {
  @include clearfix;
  border-radius: $input-border-radius;
  padding: 9px 18px;
  background: var(--rc-color-palette-gray-300);
  box-sizing: border-box;
}
