// Everything in here is deprecated, please use BEM or
// design system classes for your font sizes etc.

// Font Sizes
.gigantic {
  font-size: 36px;
  line-height: 42px;

  &.spaced {
    line-height: 56px;
  }
}

.huge {
  font-size: 2.4rem;
  font-weight: bold;
}

.bigger {
  font-size: 1.8rem;
  font-weight: bold;
}

.big {
  font-size: 1.6rem;
  font-weight: bold;
}

.base-font-size {
  font-size: 14px;
}

.unbold {
  font-weight: 400;
}

.medium-font {
  font-size: 1.6rem;
}

.small {
  font-size: 1.3rem;
  line-height: 1.4;
}

.font-tiny {
  font-size: 1.3rem;
}

.text-fine-print, .fine-print {
  font-size: 1.2rem;
  color: var(--rc-color-palette-gray-500);
}

.micro-copy {
  font-size: 10px;
}

// sylized elements
.btm-barred {
  padding-bottom: 18px;
  margin-bottom: 28px;
  position: relative;

  &:after {
    content: "";
    height: 4px;
    left: 50%;
    margin-left: -15%;
    position: absolute;
    bottom: -2px;
    width: 30%;
    background-color: var(--rc-color-palette-gray-500);
  }
}

blockquote {
  &.fancy {
    font-size: 2.4rem;
    position: relative;
    width: 70%;
    padding: 20px 5px 20px 40px;
    font-weight: normal;
    font-style: italic;
    line-height: 34px;
    border-top: 4px solid var(--rc-color-palette-vu-orange-500);
    border-bottom: 4px solid var(--rc-color-palette-vu-orange-500);

    transform: translate3d(0,0,0);
    box-sizing: border-box;

    &:before {
      content: "\201C";
      position: absolute;
      left: -40px;
      display: block;
      width: 100%;
      font-size: 250px;
      line-height: 0.875em;
      opacity: 0.15;
      box-sizing: border-box;
    }
  }

  &.pull-left {
    margin-left: -30px;
    margin-right: 20px;
  }

  &.pull-right {
    margin-left: 20px;
    margin-right: -30px;
  }

  @include mobile {
    &.pull-left,
    &.pull-right { margin: 0; }

    &.fancy {
      width: 100%;
      margin: 20px 0;

      &:before,
      &::before {
        left: -90px;
      }
    }
  }
}

.oblique {
  font-style: italic;
}

.normal {
  font-weight: normal
}

.strong {
  font-weight: bold;
}

legend {
  &.bright { color: var(--rc-color-text-accent); }

  &.underlined {
    border-bottom: 1px solid var(--rc-color-palette-gray-900);
    margin-bottom: 1em;
    width: 100%;
  }
}

.number {
  font-weight: bold;
  font-size: 1.6rem;
}

.mega-icon { font-size: 60px; }

.jumbo { font-size: 200px; }

.uppercase  { text-transform: uppercase; }
.capitalize { text-transform: capitalize; }
.sentence-capitalization:first-letter { text-transform: capitalize; }

.muted-title {
  color: var(--rc-color-text-secondary);
  font-weight: bold;
  font-size: 1.3rem;
  text-transform: uppercase;
}

.hint {
  font-weight: normal;
  color: var(--rc-color-text-secondary);
  font-size: 1.4rem;
  margin-bottom: 0;
}

.text-shadowed {
  text-shadow: 0 2px 8px var(--rc-color-shadow-600);
}

.gray, span.count { color: var(--rc-color-palette-gray-500); }

.dark-gray { color: var(--rc-color-text-primary); }

.break-word { word-wrap: break-word; }

.standard-layout {
  @extend %cms-base;
}

.tight-line-height {
  line-height: 1.2;
}

.extratight-line-height {
  line-height: 1;
}

// Center text with other elements
.center-with-textfields {
  font-size: 1.25rem;
  line-height: 2.5;
}

.center-with-buttons {
  margin-top: 0.5em;
}

.line-through { text-decoration: line-through; }

// Stats
.stat-col {
  padding-left: 0.75rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border-left: 1px solid var(--rc-color-border-primary);

  @include mobile {
    padding-left: 0.5rem;
  }

  .stat-title {
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  .stat-data {
    font-size: 2.4rem;

    &.stat-orange { color: var(--rc-color-palette-vu-orange-500); }
    &.stat-green { color: var(--rc-color-text-alert-success); }
    &.stat-large { font-size: 3.4rem; }

    @include mobile { font-size: 1.6rem; }
  }

  .stat-change {
    font-size: 1.2rem;

    &.stat-decrease { color: var(--rc-color-text-price-nudge); }
    &.stat-green { color: var(--rc-color-text-alert-success); }
  }

  .sub-stat {
    &:last-child {
      margin-bottom: 0;
    }
    .stat-title {
      font-size: 1.2rem;
      margin-top: 0.4rem;
      color: var(--rc-color-palette-gray-500);
      text-transform: none;
    }

    .stat-data { font-size: 1.4rem; }
  }

  a { color: var(--rc-color-text-primary); }
}

// Used to blur text
// !important used for overrides
.blur {
  color: transparent !important;
  text-shadow: 0 0 6px var(--rc-color-palette-gray-900) !important;
}

abbr {
  text-decoration: none;
}
