// these classes are generally pretty legacy;
// don't use them, or for bonus points, refactor them away

.inline-list {
  > li {
    display: inline-block;
  }
}

.padded-list {
  li {
    padding: 3px 0;
    padding: 3px 0;
  }
}

.horizontal-padded-list {
  li {
    padding: 0 0 10px 10px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}

.horizontal-mobile-padded-list {
  li {
    padding: 0 0 4px 4px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}

.padded-left-list {
  li {
    padding: 0 0 0 10px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}

dl {
  &.dl-horizontal {
    dt, dd {
      float: left;
      box-sizing: border-box;
    }

    dt {
      clear: left;
      width: (90/14)+rem;
    }

    dd {
      padding-left: 8px;

      @media screen and (max-width: 640px) {
        width: 364px;
      }
    }

    &.master-detail-col {
      @include mobile {
        dt, dd { width: 50%; }
      }
    }
  }

  &.lead dd {
    font-size: 1.8rem;
    line-height: 1.6;
  }
}

dl.inline-list {
  dt, dd { display: inline-block; }
}

dl.alt-row {
  dt, dd {
    display: inline-block;
    padding: 0.5rem 0.8rem;
    box-sizing: border-box;
  }

  dt:nth-child(4n+1),
  dt:nth-child(4n+1) + dd {
    background: var(--rc-color-palette-gray-100);
  }

  &.master-detail-col {
    dt { width: 60%; }
    dd { width: 40%; }

    @include mobile {
      dt { width: 100%; text-decoration: underline; margin: 0; }
      dd { width: 100%; margin: 0; }
    }
  }

  &.term-align-right dt { text-align: right; }
}

%bullets {
  li {
    list-style-type: disc;
    margin-left: 20px;
  }
}

.no-bullet {
  list-style-type: none !important;
}

.bullets {
  @extend %bullets;
}

ol.default {
  li { margin-left: 20px; }

  &.spaced li { padding-bottom: 6px; }
}

.default li { list-style: square; }

.image-grid {
  .thumb {
    img {
      border: 1px solid var(--rc-color-palette-gray-200);
    }
    float: left;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
    &.tiny img {
      width: 48px;
      height: 48px;
    }
  }
  &.small-grid {
    .thumb {
      width: 96px;
      height: 96px;
      margin-right: 14px;
    }
  }
}

// Font Awesome lists with icons override
.fa-ul {
  margin-left: 1.7em !important;

  > li + li {
    margin: 0.5em 0 0 0;
    padding: 0;
  }

  > li.hidden {
    display: none;
  }
}

ul.comma-separated-list {
  list-style: none;

  li { display: inline-block; }
  li:after { content: ", ";}
  li:last-child:after { content: ""; }
}
