@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.blog-post__frame {
  @include site-wrapper;
  @include site-wrapper--cms;
}

.blog-post__header {
  @include overlay-gradient($start: 40%);

  background: var(--rc-color-palette-gray-900) top center no-repeat;
  background-size: cover;
  position: relative;
}

.blog-post__header__title {
  @include rc-text.title('900');
  position: relative;
  padding: 30vh 0 0.5em 0;
  color: var(--rc-color-palette-bw-white);
  z-index: 2;
  text-shadow: 0 0 1rem var(--rc-color-palette-gray-900);

  a {
    color: var(--rc-color-palette-bw-white);

    &:hover {
      color: var(--rc-color-palette-bw-white);
    }
  }

  @include mobile {
    padding: 2.5em 0 0.5em 0;
  }
}

.blog-post__byline {
  @include rc-text.utility('350', 'semibold');
  background: var(--rc-color-background-page-secondary);
  padding: var(--rc-space-5) 0;
  color: var(--rc-color-text-primary);
  margin-bottom: var(--rc-space-8);

  a {
    @include text-link;
  }
}

.blog-post__byline__date {
  margin-bottom: var(--rc-space-1);
  text-align: left;
}

.blog-post__byline__categories {
  font-weight: bold;
  text-align: left;

  li {
    display: inline;

    + li:before {
      content: '•';
      margin: 0 .5em;
    }
  }

  a {
    color: inherit;

    &:hover {
      color: var(--rc-color-palette-vu-orange-500);
    }
  }
}

.blog-post__tags__header {
  margin-bottom: 0.5em;
  font-size: 90%;
}

.blog-post__tags {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: var(--rc-space-2);
  }
}

.blog-post__tag {
  display: inline-block;

  a {
    @include rc-button.muted;
    @include rc-button.size('small');
    display: inline-block;
  }

  small {
    opacity: 0.7;
  }

  @include mobile {
    font-size: 0.95em;
  }
}

.blog-post__content {
  @extend %cms-base;
}

.blog-post {
  background: var(--rc-color-background-page);

  // Find youtube & vimeo iframe embeds and make them fit mobile width
  iframe[src*='youtube'],
  iframe[src*='vimeo'] {
    @include mobile {
      width: 100%;
      height: 20rem;
    }
  }
}

// Don't hack height for the responsive embeds
.video-embed {
  iframe[src*='youtube'],
  iframe[src*='vimeo'] {
    @include mobile {
      height: 100%;
    }
  }
}

// Utility classes to pull article content outside of standard frame
// don't remove
.content-width-outset {
  width: 120%;
  margin-right: -10%;
  margin-left: -10%;
  max-width: none;

  @include tablet {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

.content-outset-left {
  margin-left: -10%;

  @include mobile {
    margin-left: auto;
  }
}

.content-outset-right {
  margin-right: -10%;

  @include mobile {
    margin-right: auto;
  }
}
