.refund-dialog {
  margin: 0 auto;
  max-width: 700px;

  html.in-mobile-app & {
    box-shadow: none;
    min-height: 100vh;
  }

  input {
    margin-bottom: 0;
  }

  .heading {
    html.in-mobile-app & {
      display: none !important;
    }
  }
}
