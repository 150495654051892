.email-offer {
  border-top: solid 1px var(--rc-color-palette-gray-200);
  padding-top: 1.5rem;
}

.email-offer__title {
  @include fa-icon-before(envelope);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
}

.email-offer__items {
  margin: 1rem 0 0;
}

.email-offer__item {
  padding: 16px;
  padding-right: 34px;
  position: relative;
  background-color: var(--rc-color-palette-bw-white);
  border: solid 1px var(--rc-color-border-primary);
  border-bottom-width: 0;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.email-offer__item-close {
  @include button-reset;
  position: absolute;
  top: 15px;
  right: 10px;
  color: var(--rc-color-text-primary);
  font-size: 16px;

  &:hover {
    color: var(--rc-color-text-danger);
  }

  &::before{
    @include fa-icon(close);
    display: block;
  }
}

.email-offer__item-fields {
  @include grid-container;
  margin-top: 12px;
}

.email-offer__item-field {
  @include grid-columns(6, 12);
  input {
    margin-bottom: 0;
  }
}

.email-offer__search {
  margin-bottom: 1rem;
  padding: 16px;
  border: solid 1px var(--rc-color-border-primary);
  border-radius: 0 0 4px 4px;
  background-color: var(--rc-color-palette-gray-100);

  .email-offer__items:empty + & {
    border-radius: 4px;
  }
}

.email-offer__search-label {
  @include fa-icon-before(plus);
}

.email-offer__totals {
  @include clearfix;
  margin-top: 1rem;

  table {
    float: right;
    width: 33%;
  }

  td {
    padding-left: 2rem;
  }

  tr:last-child td {
    padding-top: 10px;
  }

  td:last-child {
    text-align: right;
  }
}

.email-offer__message {
  margin-top: 1rem;

  textarea {
    margin-bottom: 0;
  }
}
