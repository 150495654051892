// Hero Image
.hero {
  overflow: hidden;
  position: relative;
  background-color: var(--rc-color-palette-gray-900);
  background-size: cover;

  &.hero--background {
    position: absolute;
    height: 315px;
    left: 0;
    width: 100%;
    background-color: var(--rc-color-palette-gray-900);
    background-position: center;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;

    @include background-gradient(rgba(0,0,0, 0.01), rgba(0,0,0, 0.6));
  }

  &.at-bottom {
    background-color: var(--rc-color-palette-gray-900);

    &.hero--background--fallback {
      height: auto;
      position: relative;
    }
  }

  @include mobile {
    background-position: top center;
  }

  &.hero--background--fallback {
    @include commons-svg-background('instruments.svg');

    position: absolute;
    height: 315px;
    width: 100%;
    content: '';
    background-color: var(--rc-color-palette-gray-900);
    background-size: auto;
  }
}

.hero-header {
  padding-top: 40px;

  @include mobile {
    padding-top: 20px;
  }
}

.hero-inset-header {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 12px;

  &.vertical-center { bottom: auto; }
}
