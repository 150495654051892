@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.homepage-sale-banner__wrapper {
  background-color: var(--rc-color-palette-bw-white);
}

.homepage-sale-banner {
  @include responsive(height, 40rem, 30rem, 20rem);
  background-color: var(--rc-color-palette-gray-900);
  background-position: center;
  background-size: cover;
  color: var(--rc-color-palette-bw-white);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: $site-width-max;
  margin: 0 auto;

  &:hover {
    opacity: 0.95;
    color: var(--rc-color-palette-bw-white);
  }
}

.homepage-sale-banner__content {
  @include scaling(padding, 2.5rem);

  background-color: var(--rc-color-palette-bw-white);
  max-width: 80%;
  position: relative;

  &:before {
    content: " ";
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    border: 0.2rem solid var(--rc-color-palette-vu-orange-500);
  }

  @include mobile {
    background: var(--rc-color-highlight-700);
  }
}

.homepage-sale-banner__headline {
  @include scaling(font-size, 3.6rem);

  box-sizing: border-box;
  font-weight: bold;
  color: var(--rc-color-text-primary);
}

.homepage-sale-banner__subtext {
  font-size: 16px;
  color: var(--rc-color-text-primary);
  margin-bottom: 0.5rem;

  @include mobile {
    font-size: 1rem;
  }
}

.homepage-sale-banner__cta {
  @include rc-button.filled;

  margin-top: 1rem;

  &.homepage-sale-banner__cta--lp {
    @include rc-button.filled;
  }

  @include tablet {
    font-size: 1.3rem;
  }
}
