@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.lp-core-landing {
  background-color: var(--rc-color-palette-bw-white);
  padding: 82px;
  text-align: center;

  @include mobile {
    padding: 32px;
  }
}

.lp-core-landing__title {
  font-size: 28px;
  margin: 0;
}

.lp-core-landing__image {
  width: 180px;
  margin: 32px auto;
}

.lp-core-landing__subtitle {
  font-size: 18px;
  margin: 0;
}

.lp-core-landing__learn-more {
  font-size: 18px;
  margin: 0;
}

.lp-core-landing__link {
  @include rc-button.filled;
  font-size: 18px;
  margin-top: 32px;
}
