.payment-method-icons {
  display: flex;
  margin-top: 1rem;
}

.payment-method-icon {
  flex: 1;
  text-align: center;
  padding: 0 0.5rem;

  &::before {
    content: '';
    display: inline-block;
    width: 3.5rem;
    height: 3.5rem;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &.payment-method-icon--credit-card::before {
    @include svg-background-image(payment-icon-placeholder);
  }

  &.payment-method-icon--gift-card::before {
    @include svg-background-image(payment-icon-reverb-bucks);
  }

  &.payment-method-icon--affirm::before {
    @include svg-background-image(payment-icon-affirm);
  }

  &.payment-method-icon--sofort::before {
    @include svg-background-image(payment-icon-sofort);
  }

  &.payment-method-icon--paypal::before,
  &.payment-method-icon--paypal-only::before {
    @include svg-background-image(payment-icon-paypal);
  }

  &.payment-method-icon--paypal-only {
    display: flex;
    flex: 0;
  }
}

.payment-method-icon__label {
  display: block;
  font-size: 1.2rem;
  color: var(--rc-color-palette-gray-500);
  margin-top: 0.2rem;

  .payment-method-icon--paypal-only & {
    font-size: 1.3rem;
    color: var(--rc-color-text-secondary);
    margin: 0 0 0 0.4rem;
  }
}
