.regional-listings-grid {
  padding-top: 0;
  padding-bottom: 0;
}

.flag-header {
  h2 {
    display: inline-block;
  }

  .flag-icon {
    font-size: 1.2em;
    margin-right: 0.4em;
    margin-top: -0.6em;
    vertical-align: middle;
  }
}
