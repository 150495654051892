$spinner-speed: 0.7s;

// This is deprecated, use Commons' LoadingBars
// if possible until  this can be fully removed
.loading-component {
  transform: translateZ(0);

  width: 100%;
  margin: 15px 0;
  text-align: center;

  .rect {
    background-color: var(--rc-color-palette-gray-900);
    display: inline-block;
    height: 25px;
    margin: 0 1px;
    width: 6px;

    animation: stretchdelay $spinner-speed infinite ease-in-out;
  }

  &.small {
    .rect {
      height: 12px;
      width: 2px;
    }
  }

  &.large {
    .rect {
      height: 40px;
      width: 8px;
    }
  }

  @for $i from 2 through 5 {
    .rect#{$i} {
      animation-delay: -($spinner-speed - $i * .1 + .1)
    }
  }
}

.loading-component-container {
  bottom: 0;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;

  &.loading-overlay {
    background-color: rgba(white, 0.5);
  }

  .loading-component {
    margin: 0;
  }
}
