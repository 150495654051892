@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

.product-row-card {
  @include box-shadow;
  background: var(--rc-color-palette-bw-white);
  box-sizing: border-box;
  position: relative;
  display: flex;
  padding: 5px;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1.4rem;

  > a {
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--rc-color-text-primary);
  }

  @include tablet {
    @include grid-column(4, $cycle: true);
    display: block;

    a {
      display: block;
    }
  }

  @include mobile {
    @include grid-column(6, $cycle: true);
  }

  &.product-row-card--placeholder {
    opacity: 0.7;

    @include tablet {
      &:nth-of-type(n+3) {
        display: none;
      }
    }
  }

  &.product-row-card--embedded {
    border: 1px solid var(--rc-color-palette-gray-200);
  }
}

.product-row-card__image {
  flex: 0 0 120px;
  height: 0;
  padding-bottom: 120px;
  position: relative;
  background: var(--rc-color-palette-gray-100);

  @include tablet {
    $imagepadding: 5px;
    padding-bottom: calc(100% + #{$imagepadding} * 2);
    margin: 0 - $imagepadding;
    margin-bottom: 0;
    border-bottom: 1px solid var(--rc-color-background-page-secondary);
  }

  a {
    display: block;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: auto;
  }
}

.product-row-card__title {
  flex: 1;
  margin: 5px 5px 5px 15px;

  @include tablet {
    margin: 5px 0 10px 0;
  }
}

.product-row-card__title__text {
  font-weight: bold;
  margin-bottom: 0.2em;
  overflow: hidden;
  max-height: 4.8em;
  font-size: 2rem;
  line-height: 1.2;

  @include tablet {
    margin-bottom: 0.3em;
    font-size: 1.4rem;
    height: 3.6em;
  }

  a {
    display: block;
    color: var(--rc-color-text-primary);
  }
}

.product-row-card__title__description {
  margin-bottom: 10px;
  font-size: 1.4rem;
  line-height: 1.4;
  overflow: hidden;
  max-height: 5.6em;

  p {
    font-size: inherit;
    line-height: inherit;
  }

  @include tablet {
    display: none;
  }
}

.product-row-card__location {
  flex: 0 0 160px;
  padding-left: 20px;

  .small {
    line-height: 1.2;
  }

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    bottom: 10px;
    margin-left: -20px;
    border-left: 2px solid var(--rc-color-background-page-secondary);
  }

  @include tablet {
    display: none;
  }
}

.product-row-card__location__shop {

  color: var(--rc-color-text-primary);
  font-weight: bold;

  @include truncate-text.truncate-text(
    $font-size: 13px,
    $line-height: 1.2
  );
}

.product-row-card__location__city {
  font-weight: bold;
  opacity: 0.6;
  font-size: 12px;
  line-height: 1.1;
  margin-bottom: 0.2em;
}

.product-row-card__location__detail {
  line-height: 1.2;
  font-weight: bold;

  .stars-with-count {
    display: block;
    white-space: nowrap;
    font-size: 13px;
    margin-bottom: 0.2em;
  }
}

.product-row-card__location__badges {
  white-space: nowrap;
}

.product-row-card__price {
  flex: 0 0 145px;
  text-align: right;
  font-weight: bold;
  line-height: 1.2;

  @include tablet {
    text-align: left;
    margin-bottom: 5px;

    &.product-row-card__price--free-two-day {
      color: var(--rc-color-palette-vu-orange-500);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .product-row-card--embedded & {
    @include breakpoint-tablet-up {
      padding-right: 20px;
    }
  }
}

.product-row-card__ribbon {
  box-sizing: border-box;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  padding: 0.25em 0.5em 0.3em;
  color: var(--rc-color-palette-bw-white);
  background-color: var(--rc-color-sentiment-negative-dark);
  opacity: 0.9;
  line-height: 1;
  margin-top: 0.4em;

  @include tablet {
    position: absolute;
    top: 0.25em;
    left: 0.25em;
    max-width: calc(100% - 0.5em);
    margin-top: 0;
  }
}

.product-row-card__price__shipping {
  color: var(--rc-color-text-secondary);

  .product-row-card__price--free-two-day & {
    color: var(--rc-color-text-primary);
  }

  @include tablet {
    display: inline-block;
    font-size: 0.9em;
    white-space: nowrap;
  }
}

.product-row-card__price__base {
  font-size: 2em;
  color: var(--rc-color-text-primary);
  white-space: nowrap;

  @include tablet {
    font-size: inherit;
    display: inline-block;
    margin-right: 0.3em;
  }
}
.product-row-card__price__base__on-sale {
  color: var(--rc-color-text-price-nudge);
}

.product-row-card__price__base__strike {
  font-size: 0.7em;
  text-decoration: line-through;
  color: var(--rc-color-text-secondary);
  font-weight: normal;
}

.product-row-card__actions {
  flex: 0 0 145px;
  padding-left: 30px;
  padding-right: 2px;
  font-size: 1.6rem;

  @include tablet {
    padding: 0;
  }
}

.product-row-card__actions__secondary {
  @include show-only-on-desktop;
}

.product-row-card__actions__secondary__action {
  padding-top: 5px;
}

.product-row-card__placeholder-button {
  @include rc-button.muted($fullwidth: true);

  &,
  &:hover {
    background: var(--rc-color-background-page-secondary);
    border-color: var(--rc-color-background-page-secondary);
    cursor: default;
  }
  + .product-row-card__placeholder-button {
    margin-top: 5px;
  }
}

.product-row-card__actions__report-mismatch {
  text-align: center;

  button {
    @include button-as-link;
    font-size: 0.9em;
    padding: 8px;
  }
}

.product-row-card__price__financing {
  margin-top: 0.4em;
}
