.new-contest {
  .weekly-deals-signup {
    display: none;
  }

  .container {
    padding-top: 0;
  }

  .giveaway-banner {
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.contest {
  img {
    width: 100%;
    height: auto;
  }
}

.contest-dialog-signup-container {
  .dialog {
    @include box-shadow;
  }
}

.contest-dialog {
  box-shadow: none;

  .body {
    background: var(--rc-color-palette-gray-100);
    border: .1rem solid var(--rc-color-palette-gray-200);
    padding: 2em;
    min-height: 28rem;
  }

  .btn-blue {
    font-size: 2.4rem;
  }
}
