.bottom-similar-products { background: var(--rc-color-palette-gray-300); }

.similar-product {
  @include box-shadow;
  display: inline-block;
  overflow: hidden;
  width: 140px;
  margin: 0 20px 0 0;
  background: var(--rc-color-background-module-secondary);
  &:last-child { margin-right: 0; }
  border-radius: 4px;
  @include mobile {
    float: left;
    width: 300px;
    &:nth-child(even) { margin-right: 0; }
  }

  font-weight: bold;

  img {
    display: block;
    width: 100%;
    height: auto;

    &:hover {
      opacity:0.85;
    }
  }

  &.similar-ended { margin: 0 20px 20px 0; }

  &.similar-bottom { float: left; }

  .similar-product-body {
    color: var(--rc-color-text-secondary);
    font-weight: bold;
    padding: 6px 9px;
    box-sizing: border-box;

    .similar-product-title {
      font-size: 1.4rem;
      height: 38px;
      overflow: hidden;
      a { color: var(--rc-color-text-primary); }
    }

    .similar-product-price {
      a { color: var(--rc-color-text-accent); }
    }
  }
}

.ended-listing {
  @include scaling(padding-top, 2rem);
  display: flex;
  align-items: center;
}

.ended-listing__image {
  flex: 1;
  margin-right: 1em;

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 3px;
    min-width: 50px;
  }
}

.ended-listing__title {
  flex: 14;
}

.ended-listing__title__text {
  font-weight: bold;
  font-size: 160%;

  &.ended-listing__title__text--csp {
    color: var(--rc-color-palette-bw-white);
  }
}

.featured-product {
  .product-title {
    height: 40px;
    overflow: hidden;
  }
  .shop-link {
    position: absolute;
    right: 18px;
    bottom: 18px;
  }
}
