@use './responsive-hover' as hover;

@mixin animated-text-underline {
  text-decoration: underline;
  text-underline-offset: 0.1em;
  transition:
    text-underline-offset 0.1s ease-in-out,
    text-decoration-color 0.1s ease-in-out;

  @include hover.responsive-hover {
    text-underline-offset: 0.2em;
  }
}

@mixin text-link {
  @include animated-text-underline;
  text-decoration-color: currentColor;
  color: currentColor;
  text-underline-offset: 0.1em;
  transition: color 0.1s ease-in-out;

  @include hover.responsive-hover {
    color: var(--rc-color-text-textlink-hovered);
  }
}

@mixin group-link {
  @include animated-text-underline;
  color: inherit;
  text-decoration-color: transparent;

  @include hover.responsive-hover {
    color: var(--rc-color-text-textlink-hovered);
    text-decoration-color: currentColor;
  }
}
