.dropdown-menu.previous-order-list {
  font-size: 1.3rem;
  line-height: 1.4;
  box-sizing: border-box;

  top: 24px;
  width: 300px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  // So that we don't show the dropdown on mobile
  @include mobile { display: none !important; }

  .previous-order-totals { border-bottom: 1px solid var(--rc-color-border-primary);}

  .previous-order {
    border-bottom: 1px solid var(--rc-color-border-primary);

    &:hover {
      background-color: var(--rc-color-background-module-hovered);
    }
  }

  li:last-child {
    border-bottom: 0;
  }

  .previous-order-pagination {
    display: inline-block;
    margin: 8px 8px 0;

    &.next { float: right; }

    &.previous { float: left; }
  }

  .loading-component { margin: 8px 0 0 0; }
}

$pointer-width: 8px;

.pointer-left.previous-order-list {
  &:after {
    left: 5%;
    margin-left: -($pointer-width / 2);
  }

  &:before {
    left: 4.7%;
    margin-left: -(($pointer-width + 2) / 2);
  }
}

.repeat-customer-badge {
  @extend .badge;
  @extend .blue-badge;

  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.2rem 0.5rem;
}

.previously-bought-from-seller {
  cursor: pointer;
  box-sizing: border-box;

  padding: 12px 18px;
  background-color: var(--rc-color-background-module-muted);
  border-bottom: 1px solid var(--rc-color-border-primary);

  .dropdown-menu.previous-order-list {
    top: 40px;
    left: 0;

    &.pointer-left {
      &:after {
        left: 8.5%;
        margin-left: -($pointer-width / 2);
      }

      &:before {
        left: 8.2%;
        margin-left: -(($pointer-width + 2) / 2);
      }
    }
  }
}

