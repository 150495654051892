.sidebar-link {
  margin-bottom: 2rem;

  &.trending {
    background: var(--rc-color-palette-gray-300);
  }

  .wide {
    margin-left: -18px;
    margin-right: -18px;
    padding: 0 18px;
    box-sizing: content-box;
  }

  h4 {
    font-size: 15px;
    line-height: 20px;
  }

  li {
    margin-bottom: 5px;
  }
}

.sidebar-link-block {
  position: relative;
  width: 184px;

  img {
    display: block;
  }

  &:hover {
    .sidebar-link-title {
      display: block;
    }
  }
}

.sidebar-link-title {
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  transition: background 0.5s;
  background: var(--rc-color-palette-gray-900);

  &:hover {
    background: var(--rc-color-palette-gray-900);
  }

  .btn-blue {
    margin-top: 56px;
  }
}

.blog-sidebar-ad {
  margin-bottom: 2rem;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  background: var(--rc-color-palette-gray-900) center;
  background-size: cover;

  > a {
    display: flex;
    width: 100%;
    background: var(--rc-color-shadow-400);
    height: 180px;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    transition: background .2s;

    &:hover {
      background: var(--rc-color-shadow-500);
    }
  }
}

.blog-sidebar-ad__content {
  box-sizing: border-box;
  color: var(--rc-color-palette-bw-white);
  padding: 18px;
  text-align: center;
}
