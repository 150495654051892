.without-recommendation-icon {
  .recommendation-icon {
    display: none;
  }
}

.bump-recommendation {
  .recommendation-icon {
    margin-right: 23px;
    margin-left: 5px;

    .bump-icon-arrows {
      width: 36px;
    }
  }
}
