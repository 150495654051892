@use 'sass:list';
@use 'sass:map';
@use '../../config/configuration';
@use '../../config/scss-tokens';
@use '../functions/svg';
@use 'responsive-hover';

// Replacement for 'svg-background-image' that uses svg file resources instead of
// generating inline svg paths

@mixin commons-svg-background($image) {
  background-image: url('#{configuration.$commons-path}/images/#{$image}');
}

// An alternative to svg-background-image that uses a mask to color svg's using the background-color property.
// Can be used with css variables.

@mixin icon-mask-image($icon, $fill: var(--rc-color-text-primary), $size: contain) {
  background-color: $fill;
  mask-image: svg.svg-url($icon, scss-tokens.$rc-color-palette-bw-black); // stylelint-disable-line cadence/no-scss-tokens
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: $size;
}

// Deprecated below

// stylelint-disable cadence/no-scss-tokens -- Needs scss var for legacy support
@mixin icon-bg($icon, $fill: scss-tokens.$rc-color-text-primary, $hover-fill: false, $as-link: false) {
  @if ($as-link != false) {
    @include svg.svg-background-image($icon, scss-tokens.$rc-color-text-link);

    @include responsive-hover.responsive-hover {
      background-image: svg.svg-url($icon, scss-tokens.$rc-color-text-link-hovered);
    }
  } @else {
    @include svg.svg-background-image($icon, $fill);

    @if ($hover-fill != false) {
      // this can't use our optimized extend because it's inside a media query
      @include responsive-hover.responsive-hover {
        background-image: svg.svg-url($icon, $hover-fill);
      }
    }
  }

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
// stylelint-enable cadence/no-scss-tokens

// stylelint-disable cadence/no-scss-tokens -- Needs scss var for legacy support
@mixin icon-before($icon, $fill: scss-tokens.$rc-color-text-primary, $hover-fill: false, $scale: configuration.$svg-scale, $as-link: false) {
  @if ($as-link != false) {
    &::before {
      @include icon-bg($icon, scss-tokens.$rc-color-text-link);
    }

    @include responsive-hover.responsive-hover {
      &::before {
        background-image: svg.svg-url($icon, scss-tokens.$rc-color-text-link-hovered);
      }
    }
  } @else {
    &::before {
      @include icon-bg($icon, $fill);
    }

    @if ($hover-fill != false) {
      @include responsive-hover.responsive-hover {
        &::before {
          background-image: svg.svg-url($icon, $hover-fill);
        }
      }
    }
  }

  &::before {
    content: '';
    display: inline-block;
    vertical-align: baseline;
    height: $scale;
    width: $scale * svg.icon-width-ratio($icon);
    position: relative;
    margin-right: configuration.$spacing-space-amount;
    top: configuration.$svg-y-offset;
  }
}
// stylelint-enable cadence/no-scss-tokens

// stylelint-disable cadence/no-scss-tokens -- Needs scss var for legacy support
@mixin icon-after($icon, $fill: scss-tokens.$rc-color-text-primary, $hover-fill: false, $scale: configuration.$svg-scale, $as-link: false) {
  @if ($as-link != false) {
    &::after {
      @include icon-bg($icon, scss-tokens.$rc-color-text-link);
    }

    @include responsive-hover.responsive-hover {
      &::after {
        background-image: svg.svg-url($icon, scss-tokens.$rc-color-text-link-hovered);
      }
    }
  } @else {
    &::after {
      @include icon-bg($icon, $fill);
    }

    @if ($hover-fill != false) {
      @include responsive-hover.responsive-hover {
        &::after {
          background-image: svg.svg-url($icon, $hover-fill);
        }
      }
    }
  }

  &::after {
    content: '';
    display: inline-block;
    vertical-align: baseline;
    height: $scale;
    width: $scale * svg.icon-width-ratio($icon);
    position: relative;
    margin-left: configuration.$spacing-space-amount;
    top: configuration.$svg-y-offset;
  }
}
// stylelint-enable cadence/no-scss-tokens

// An updated version of `icon-before` that uses a mask-image to instead of background image.
// Mask-image has better support for css variables (i.e. dark mode).
@mixin mask-icon-before($icon, $fill: currentColor, $scale: configuration.$svg-scale) {
  &::before {
    @include icon-mask-image($icon, $fill: $fill);
    content: '';
    display: inline-block;
    vertical-align: baseline;
    height: $scale;
    width: $scale * svg.icon-width-ratio($icon);
    position: relative;
    margin-right: configuration.$spacing-space-amount;
    top: configuration.$svg-y-offset;
  }
}
