@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.tone-report-header {
  @include scaling(margin-bottom, 40px);

  text-align: center;
  background: url('../images/promo/tone-report-header-bg.jpg') center no-repeat;
  background-size: cover;

  img {
    width: 400px;
    padding: 120px 0;

    @include mobile {
      width: 90%;
      padding: 50px 0;
    }
  }
}

.tone-report-callout {
  margin-bottom: 2rem;
  max-width: 720px;
  margin: 0 auto 1.5rem auto;

  @include mobile {
    margin-left: 10px;
  }

  a {
    @include rc-button.filled;
    border-radius: 0;

    img {
      display: block;
      height: 1.5em;
    }
  }
}
