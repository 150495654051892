.facets input {
  margin-bottom: 0;
}

.product-module {
  padding: 0 9px;
  width: 100%;
  max-width: 220px;
  float: left;
  box-sizing: border-box;

  .mark-as-paid & {
    max-width: none;
  }

  .thumb img {
    width: 100%;
    height: auto;
  }

  input[type='text'] {
    display: none;
  }
}

div.module h2.spellcheck {
  color: var(--rc-color-text-danger);

  a {
    font-weight: normal;
    font-style: italic;
    text-decoration: underline;
  }
}

