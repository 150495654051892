@use 'responsive';

@mixin viewport-bleed {
  position: relative;
  left: calc(-50vw + 50%);
  width: 100vw;
  max-width: none;
  display: block;

  .dynamic-page__two-col & {
    width: auto;
    left: auto;

    @include responsive.mobile {
      left: calc(-50vw + 50%);
      width: 100vw;
    }
  }
}
