@use '../../../config/configuration';
@use '../../functions/vendor/fontawesome';

@mixin fa-icon($icon) {
  @extend %fa-icon-base;
  content: fontawesome.fa-icon-content($icon);
}

@mixin fa-icon-before($icon, $margin-right: configuration.$icon-inline-spacing, $spin: false) {
  &::before {
    @include fa-icon($icon);
    margin-right: $margin-right;

    @if ($spin == true) {
      display: inline-block;
      animation: fa-spin 2s infinite linear;
    }
  }
}

@mixin fa-icon-after($icon, $margin-left: configuration.$icon-inline-spacing, $spin: false) {
  &::after {
    @include fa-icon($icon);
    margin-left: $margin-left;

    @if ($spin == true) {
      display: inline-block;
      animation: fa-spin 2s infinite linear;
    }
  }
}
