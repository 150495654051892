@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.guest-lock {
  background: transparent url('../images/guests/dashboard-blur.jpg') center top no-repeat;
  min-height: 40vh;
  padding: 100px 0;
}

.guest-lock__modal {
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 0 50px var(--rc-color-shadow-600);

  @include mobile {
    max-width: 90%;
  }
}

.guest-lock__header {
  padding: 10px 18px;
  color: var(--rc-color-palette-bw-white);
  background-color: var(--rc-color-palette-gray-900);
}

.guest-lock__title {
  color: var(--rc-color-palette-bw-white);
  font-size: 18px;
}

.guest-lock__body {
  padding: 18px;
  background-color: var(--rc-color-palette-bw-white);
}

.guest-lock__message-email {
  font-weight: 700;
}

.guest-lock__action {
  @include rc-button.muted;
  margin: 12px 12px 0 0;

  &.guest-lock__action--primary {
    @include rc-button.filled;
  }
}
