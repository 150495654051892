.paywall-body {
  @include breakpoint-tablet-up { max-width: 800px; }

  .tabbable .tab-content .tab-pane {
    padding-left: 0;
    padding-bottom: 0;
  }
}

.choose-billing-card__list {

  .choose-billing-card__option {
    label {
      display: block;
    }
  }
}


.billing-card {
  width: 100%;

  @include breakpoint-tablet-up {
    max-width: 42rem;
  }
}

.card-type {
  border-radius: 6px;
  border: 1px solid var(--rc-color-palette-gray-200);
  box-sizing: border-box;
  position: relative;
  padding: 1.3rem;

  &.jcb,
  &.generic-cc { background: #EAEAEA;}

  &.mastercard {
    @include background-gradient(#fed65f, #f8a929);
  }

  &.amex {
    background-color: #72bca7;
    background: radial-gradient(ellipse at center, #72b6a7 0%, #448b6d 100%);
    color: var(--rc-color-palette-bw-white);
    label { color: var(--rc-color-palette-bw-white); }
  }

  &.discover {
    @include background-gradient(#e6ebec, #9a9b9e);
  }

  &.visa {
    @include background-gradient(#1cb5e1, #0d7bca);
    color: var(--rc-color-palette-bw-white);
    label { color: var(--rc-color-palette-bw-white); }
  }

  .styled-dropdown select {
    color: var(--rc-color-text-primary);
  }
}

.cc-field-lock-icon {
  position: absolute;
  top: 9px;
  right: 7px;
  @include mobile { right: 4px; }
}

.cc-type-icons {
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
}

.payment-method {
  width: 35px;
  height: 0;
  padding-bottom: percentage(2/3); // ratio of card svg
  background: center no-repeat;
  background-size: contain;
  transition: opacity .2s, background .2s;

  &.discover {
    background-image: url('../images/checkout/discover.svg');
  }

  &.visa {
    background-image: url('../images/checkout/visa.svg');
  }

  &.master-card,
  &.mastercard {
    background-image: url('../images/checkout/mastercard.svg');
  }

  &.american-express,
  &.amex {
    background-image: url('../images/checkout/amex.svg');
  }

  &.maestro {
    background-image: url('../images/checkout/maestro.svg');
  }

  &.jcb {
    background-image: url('../images/checkout/jcb.svg');
  }

  &.diners_club_international,
  &.diners-club {
    background-image: url('../images/checkout/diners-club.svg');
  }

  &.unionpay {
    background-image: url('../images/checkout/unionpay.svg');
  }

  &.generic { // This is here to make transitions work correctly
    opacity: .8;
    background-image: url('../images/checkout/generic.svg');
  }
}

@include mobile {
  form.billing_profile {
    input[type=email] {
      width: 100%;
      box-sizing: border-box;
    }
  }
}

.cc-details {
  @include clearfix;
}

.cc-details__card {
  display: flex;
  align-items: center;

  .cc-details-list__item & {
    margin: 0 1rem 0 0;
    align-items: flex-start;

    @include mobile {
      margin: 0 0 1rem;
    }
  }
}

.cc-details__icon {
  width: 6rem;
  flex-shrink: 0;
  margin-right: 1rem;
}

.cc-details__card-type {
  color: var(--rc-color-text-primary);
  font-weight: bold;
}

.cc-details__address {
  font-size: 12px;
}

.cc-details__expiration {
  font-size: 12px;
}

.cc-payment-unsupported {
  width: 85%;
  display: block;
  margin: 1em 0 0 0;
  box-sizing: border-box;
}

.cc-verify-cvv {
  width: 85%;
  margin-top: 1em;
  padding: 1em;
  border-radius: 6px;
  border: 1px solid var(--rc-color-border-primary);
  background: var(--rc-color-background-module-secondary);
  box-sizing: border-box;
}

.cc-details-list {
  @include clearfix;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;

  @include mobile {
    flex-direction: column;
  }

  &.cc-details-list--actions {
    margin-top: 1rem;
  }
}

.cc-details-list__item {
  flex: 1;
}

.cc-details__edit {
  font-weight: bold;
  text-align: right;
}
