.announcement-module {
  background-color: var(--rc-color-background-module);
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-primary);
  display: flex;
}

.announcement-module__icon {
  padding: 2rem;
  padding-left: 4rem;
  width: 6rem;
  display: flex;
  align-items: center;

  @include mobile {
    display: none;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.announcement-module__main {
  flex: 1;
  display: flex;

  @include mobile {
    display: block;
    position: relative;
  }
}

.announcement-module__content {
  @include scaling(padding, 2rem);
  flex: 1;

  @include mobile {
    padding-right: 4.2rem;
  }
}

.announcement-module__controls {
  @include mobile {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.announcement-module__dismiss {
  @include button-reset;
  @include scaling(padding, 2rem);
  color: var(--rc-color-text-secondary);

  &:hover {
    color: var(--rc-color-text-primary);
  }

  &::after {
    @include fa-icon(close);
    font-size: 2rem;
  }
}
