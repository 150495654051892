.offer-breakdown {
  width: 100%;

  td {
    border-bottom: solid 1px var(--rc-color-border-primary);
    padding: 4px 0;

    &:last-child {
      text-align: right;
    }
  }
}

.offer-breakdown__listing-price {
  text-decoration: line-through;
  color: var(--rc-color-text-secondary);
}

.offer-breakdown__offered-price {
  color: var(--rc-color-text-primary);
  margin-left: 5px;
}

.offer-breakdown__total {

  td {
    border-bottom: none;
    font-weight: 700;
  }
}
