.dropbox {
  @include clearfix;

  margin-bottom: 2rem;
  border-radius: var(--rc-border-radius-input);
  background: var(--rc-color-background-input);
  border: 2px dashed var(--rc-color-border-input-primary);
  cursor: pointer;
  min-height: 125px;
  margin-bottom: 12px;
  position: relative;
  text-align: center;

  &:hover,
  &.hover {
    border: 2px solid var(--rc-color-border-accent);
  }

  label { display: none; }

  label.message {
    font-size: 2.4rem !important;
    font-weight: normal !important;
    display: block;
    color: var(--rc-color-text-primary);
    cursor: pointer;
    line-height: normal;

    .font-normal {
      font-size: 1.4rem !important;
    }
  }

  table {
    min-height: 125px;
    width: 100%;
    td {
      vertical-align: top;
    }
    td.dropzone {
      vertical-align: middle;
      padding: 1em;
    }
  }

  &.auto-width-images {
    div.attachinary_container {
      ul li {
        height: auto;
        width: auto;
      }
    }
  }

  div.attachinary_container {
    ul  {
      padding: 9px 0;
      @include clearfix;

      li {
        margin-bottom: 2rem;
        list-style-type: none;
        float: left;
        margin-left: 8px;
        width: 96px;
        margin-bottom: 30px;

        img {
          cursor: move;
          border: 1px solid var(--rc-color-border-primary);
          background: var(--rc-color-background-page);
        }

        &.error img {
          border: 1px solid var(--rc-color-border-alert-error);
        }

        .overlay {
          cursor: move;
          opacity: 0.8;
          font-size: 1.3rem;
          width: 97px;
          line-height: 1.2;
          margin-top: -100px;
          background: black;
          color:white;

          &.small-image-warning {
            width: auto;
            margin-top: -45px;
            margin-left: 72px;
            padding: 4px 4px 2px;
            background: white;
            border-radius: 2px;
            text-align: right;
            font-size: 1.6rem;
            color: var(--rc-color-palette-vu-orange-500);
            opacity: 1;
          }
        }

        a {
          font-weight: bold;
          color: var(--rc-color-text-danger);
          cursor: pointer;
          display: block;
          margin-top: -5px;
        }

        @include mobile {
          height: auto;
          margin-bottom: 20px;
        }
      }
    }
  }


  input.attachinary-input {
    cursor: pointer;
    height: 100%;
    right: 0;
    opacity: 0;
    filter:alpha(opacity=0);
    position: absolute;
    top: 0;
    width: 100%;
  }


  .progressbar {
    border-top: 1px solid var(--rc-color-palette-gray-200);
  }

  .drag-message {
    display: block;
    margin: 0.5em;
  }

  &.has-files {
    border: 1px solid var(--rc-color-border-primary);

    td.dropzone {
      width: 205px;
      border-left: 1px dashed var(--rc-color-border-primary);

      input[type='file'] {
        width: 235px;
      }
    }
  }

  &.single-file {
    div.attachinary_container ul li img {
      cursor: pointer; //override drag cursor
    }
    &.has-files {
      td.dropzone {
        border-left: 0;

        input[type='file'] {
          width: 0;
        }
      }
    }
  }
}

.shop-banner-dropbox .dropbox {
  div.attachinary_container ul li {
    margin-left: 0;
    width: 100%;
  }

  &.has-files {
    td.dropzone {
      border-left: 0;
      img {
        width: 100%;
        height: auto;
      }
    }
    .attachinary-input {
      height: auto;
    }
  }
}

.total-progress {
  @include clearfix;
  margin: 8px 16px;
}

.upload {
  @include box-shadow;
  background: var(--rc-color-background-page);
  border: 0.1rem solid var(--rc-color-border-primary);
  margin-bottom: 2rem;
  display: block;
  font-weight: bold;
}

.dropbox-progress-bar {
  @include box-shadow;
  border: 0.1rem solid var(--rc-border-radius-primary);
  border-radius: 0 0 var(--rc-border-radius-input) var(--rc-border-radius-input);
  text-align: center;
  padding: 0.5em 0;
  height: 1em;
  font-weight: bold;
  background: var(--rc-color-background-progressbar-indicator-success);
  border: none;
}
