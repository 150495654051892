.brands-index__header__wrapper {
  background-color: var(--rc-color-palette-gray-200);
}

.brands-index__header {
  @include site-wrapper;
  background: var(--rc-color-palette-gray-900) url('../images/promo/brands-index-header.jpg') no-repeat center;
  background-size: cover;
}

.brands-index__all-brands {
  @include site-wrapper;
  background: var(--rc-color-palette-bw-white);
  margin-top: 4rem;

  @include mobile {
    text-align: center;
  }
}

.brands-index__title {
  @include responsive(padding-top, 100px, 80px, 60px);
  @include responsive(padding-bottom, 100px, 80px, 60px);
  @include scaling(font-size, 36px);
  font-weight: bold;
  color: var(--rc-color-palette-bw-white);
  text-align: center;
}

.brands-index__top-brands {
  @include site-wrapper;
  @include scaling(padding-top, 30px);
  @include scaling(padding-bottom, 30px);
}

.brands-index__top-brands__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

.brands-index__top-brands__subtitle {
  margin-bottom: 20px;
  text-align: center;
}

.brands-index__all-brands {
  @include scaling(padding-top, 30px);
  @include scaling(padding-bottom, 30px);
}

.brands-index__all-brands__heading {
  @include scaling(font-size, 36px);
  font-weight: bold;
  margin-bottom: 15px;
}

.brands-index__all-brands__section {
  @include scaling(margin-bottom, 6rem);
  @include grid-container;
}

.brands-index__all-brands__section__column {
  @include grid-columns(2, 12);

  li {
    margin-bottom: 10px;
  }
}
