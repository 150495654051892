.global-slidedown--open {
  transition: max-height .4s ease-in-out;
  max-height: 1000px;
  overflow: hidden;

  @include mobile {
    max-height: 2500px;
  }
}

.global-slidedown--closed {
  transition: max-height .4s ease-in-out;
  max-height: 0;
  overflow: hidden;
}
