.measurement-examples-container {
  background-color: var(--rc-color-background-page);
  padding: 0;
}

.measurement-example__link {
  @include button-reset;
  padding: 0.5rem 1rem;

  display: block;
  width: 100%;
  text-align: left;
  border-top: 1px solid var(--rc-color-border-primary);
  color: var(--rc-color-text-primary);

  &:hover {
    background-color: var(--rc-color-background-module-hovered);
  }
}

.measurement-example__image {
  width: 32px;
  margin-top: 2px;
  float: left;
}

.measurement-example__details {
  padding-left: 32px + 8px; // image width + margin
  font-size: 1.3rem;
  line-height: 1.4rem;
}

.measurement-example__label {
  margin: 0;
  font-weight: bold;
}

.measurement-example__description {
  margin: 0;
  color: var(--rc-color-text-secondary);
}
