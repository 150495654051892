@mixin loading-pulse {
  animation: loading-pulse infinite 1.2s ease-out;

  @keyframes loading-pulse {
    0% {
      opacity: 0.7;
    }

    50% {
      opacity: 0.2;
    }

    100% {
      opacity: 0.7;
    }
  }
}
