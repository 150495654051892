@mixin phone-verification($image-name) {
  background: url('../images/icons/#{$image-name}.png') center no-repeat;

  &.run-animation {
    animation: popin 0.8s ease-in-out 1;
  }
}

.phone-verification,
.phone-enter-code,
.phone-success {
  width: 100px;
  height: 100px;
}

.phone-verification {
  @include phone-verification('phone-get-verified');
}

.phone-enter-code {
  @include phone-verification('phone-enter-code');
}

.phone-success {
  @include phone-verification('phone-verified');
}
