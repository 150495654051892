.shipping-estimator {

  #shipping-estimate-result.with-spinner {
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-bottom: 1rem;
  }

  &.tabbable .nav-tabs {
    border-bottom-color: var(--rc-color-border-primary);
  }
}

.estimator-panel {
  margin: 1em 0;
  background-color: var(--rc-color-background-module-muted);
  border: 0.1rem solid var(--rc-color-border-primary);
  border-radius: var(--rc-border-radius-md);

  &.estimator-panel--standalone {
    background-color: var(--rc-color-background-page);
  }
}

.estimator-panel__toggler {
  @include clearfix;
  @include button-reset;

  font-size: 110%;
  padding: 1em;
  display: block;
  width: 100%;
  text-align: left;

  @include responsive-hover {
    background-color: var(--rc-color-background-module-hovered);
  }
}

.estimator-panel__estimator {
  padding: 0 1em 1em;
}
