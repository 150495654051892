@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.order-notes {
  padding: 9px 0;
}

.order-notes__label-button {
  @include button-reset;
  color: var(--rc-color-text-primary);
  position: relative;
  padding-left: 18px;

  &:hover {
    color: var(--rc-color-text-accent);
  }

  &::before {
    content: '';
    border-color: transparent currentColor;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: 4px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);

    .order-notes--open & {
      border-color: currentColor transparent;
      border-width: 0.45em 0.35em 0 0.35em;
    }
  }
}

.order-notes__loader {
  padding: 7px 5px 0;
}

.order-notes__container {
  padding: 7px 5px 5px;
}

.order-note {
  margin: 0 0 14px;
}

.order-note__header {
  color: var(--rc-color-text-secondary);
  margin-bottom: 3px;
}

.order-note__timestamp {
  font-size: 12px;
}

.order-note__delete {
  @include button-reset;
  @include fa-icon-before(close, $margin-right: 0);
  color: var(--rc-color-text-secondary);
  margin-left: 6px;

  &:hover {
    color: var(--rc-color-palette-vu-orange-500);
  }
}

.order-note__body {
  line-height: 1.35;
}

.order-notes__form {
  margin-top: 0.5rem;
}

.order-notes__textarea {
  display: block;
  width: 100%;
  height: 70px;
  margin: 0 0 1.2rem;
}

.order-notes__submit {
  @include rc-button.filled;
  font-size: 1.4rem;
  margin: 0;

  @include mobile {
    width: 100%;
  }
}

.order-notes__hint {
  @include scaling(margin-top, 1rem);
  display: inline-block;
  margin-left: 1em;
  color: var(--rc-color-text-secondary);

  @include mobile {
    display: block;
    text-align: center;
    margin-top: 1em;
    margin-left: 0;
  }
}
