$search-context-bar: (
  height: 28px,
  line-height: 28px,
  border-color: var(--rc-color-border-primary),
);

$filter-term: (
  color: var(--rc-color-text-primary),
  color-hover: var(--rc-color-text-link-hovered),
  background-color: var(--rc-color-palette-gray-100),
  border: 1px solid var(--rc-color-border-primary),
  font-size: 1.4rem,
  x-out-border: 1px solid var(--rc-color-palette-bw-white),
  x-out-color: var(--rc-color-palette-bw-white),
  spacing: 8px
);

%search-context-bar-vertical-height {
  height: map-get($search-context-bar, height);
  line-height: map-get($search-context-bar, line-height);
  vertical-align: top;
}

@mixin search-context-bar-category-connector($color, $left, $z-index) {
  $dimen: map-get($search-context-bar, height)/2;
  @include triangle($dimen, $dimen/2, $color, right);

  left: $left;
  top: 0;
  z-index: $z-index;

  @include mobile {
    display: none;
  }
}

@mixin search-context-bar-category-sibling {
  $spacing: map-get($filter-term, spacing);

  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -$spacing;
  padding-left: $spacing*1.5;
  position: relative;

  &::before {
    @include search-context-bar-category-connector(rgba(map-get($filter-term, color), 0.25), -1px, 1);
  }

  &::after {
    @include search-context-bar-category-connector(map-get($filter-term, background-color), -2px, 2);
  }

  @include mobile {
    border-radius: 4px;
    margin-left: 0;
    padding-left: $spacing;
  }
}

@mixin search-context-bar-filter-term {
  $spacing: map-get($filter-term, spacing);

  @extend %search-context-bar-vertical-height;

  background-color: map-get($filter-term, background-color);
  border-radius: 3px;
  border: map-get($filter-term, border);
  box-sizing: border-box;
  display: inline-block;
  font-size: map-get($filter-term, font-size);
  font-weight: normal;
  margin: 0 $spacing $spacing 0;
  padding-left: $spacing;
  white-space: nowrap;
}
