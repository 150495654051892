.credit-card-embed {
  background-color: var(--rc-color-palette-bw-white);
  border: solid 1px var(--rc-color-border-primary);
  box-shadow: inset 2px 2px 5px #f5f5f5;
  border-radius: 4px;
  padding: 6px;
}

.credit-card-embed--invalid {
  border-color: var(--rc-color-sentiment-negative-dark);
  background-color: var(--rc-color-sentiment-negative-lighter);
}
