@use 'sass:map';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.admin-flag-button {
  @include rc-button.muted;
  @include fa-icon-before(exclamation-triangle);
  width: 100%;
}

.btn {
  cursor: pointer;
  display: inline-block;
  padding: 0.65em 1em;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  border: 0.1rem solid var(--rc-color-border-primary);
  color: var(--rc-color-text-primary);
  background-color: var(--rc-color-palette-bw-white);
  appearance: none;
  border-radius: $button-border-radius;
  box-sizing: border-box;
  font-size: 1.6rem;

  span[class*="fa-"] {
    margin-right: 0.25em;
  }

  @include breakpoint-tablet-up {
    &:hover {
      transition: all .15s ease;
      text-decoration: none;
    }
  }

  &.btn-transparent {
    border: none;
    color: var(--rc-color-palette-blue-600);
    background: none;
  }

  &.btn-active,
  &.wysihtml5-command-active {
    color: var(--rc-color-text-accent);
  }

  &.btn-admin {
    background: url('../images/icons/stripes-texture.png') var(--rc-color-palette-bw-white);
  }

  &:disabled,
  &.btn-disabled,
  &.btn-loading {
    opacity: 0.4;
    color: var(--rc-color-text-primary);
    cursor: default;
  }

  // Social Media
  &.btn-facebook {
    @include social-button(var(--rc-color-vendor-facebook));
  }

  &.btn-twitter {
    @include social-button(var(--rc-color-vendor-twitter));
  }

  &.btn-google-plus {
    @include social-button(#DD4B39);
  }

  &.btn-pinterest {
    @include social-button(#C42723);
  }

  &.btn-email {
    @include social-button(var(--rc-color-palette-gray-700));
  }

  // Colors
  $legacy-btn-colors: (
    'orange': (
      'bg': var(--rc-color-palette-vu-orange-500),
      'bg-hover': var(--rc-color-palette-vu-orange-400),
      'text': var(--rc-color-palette-bw-white),
      'bd': var(--rc-color-palette-vu-orange-600),
    ),
    'red': (
      'bg': var(--rc-color-sentiment-negative-dark),
      'bg-hover': var(--rc-color-sentiment-negative-dark),
      'text': var(--rc-color-palette-bw-white),
      'bd': var(--rc-color-sentiment-negative-dark),
    ),
    'warning': ( // Very old non-orangey red. One-off hex is okay here bc very legacy
      'bg': #F6283D,
      'bg-hover': #F85969,
      'text': var(--rc-color-palette-bw-white),
      'bd': #E60A20,
    ),
    'inverse': (
      'bg': var(--rc-color-palette-gray-900),
      'bg-hover': var(--rc-color-palette-gray-800),
      'text': var(--rc-color-palette-bw-white),
      'bd': var(--rc-color-palette-gray-900),
    ),
    'primary': ( // same as orange
      'bg': var(--rc-color-palette-vu-orange-500),
      'bg-hover': var(--rc-color-palette-vu-orange-400),
      'text': var(--rc-color-palette-bw-white),
      'bd': var(--rc-color-palette-vu-orange-600),
    ),
    'success': (
      'bg': var(--rc-color-sentiment-positive-dark),
      'bg-hover': var(--rc-color-sentiment-positive-dark),
      'text': var(--rc-color-palette-bw-white),
      'bd': var(--rc-color-sentiment-positive-dark),
    ),
    'blue': (
      'bg': var(--rc-color-palette-blue-600),
      'bg-hover': var(--rc-color-palette-blue-500),
      'text': var(--rc-color-palette-bw-white),
      'bd': var(--rc-color-palette-blue-700), // blue/500 doesn't yet exist
    ),
    'secondary': (
      'bg': var(--rc-color-palette-gray-200),
      'bg-hover': var(--rc-color-palette-gray-100),
      'text': var(--rc-color-palette-gray-900),
      'bd': var(--rc-color-palette-gray-300),
    ),
    'grey': (
      'bg': var(--rc-color-palette-gray-700),
      'bg-hover': var(--rc-color-palette-gray-600),
      'text': var(--rc-color-palette-bw-white),
      'bd': var(--rc-color-palette-gray-800),
    ),
    'bump': ( // same as "inverse"
      'bg': var(--rc-color-palette-gray-900),
      'bg-hover': var(--rc-color-palette-gray-800),
      'text': var(--rc-color-palette-bw-white),
      'bd': var(--rc-color-palette-gray-900),
    ),
    'white': (
      'bg': var(--rc-color-palette-bw-white),
      'bg-hover': var(--rc-color-palette-bw-white),
      'text': var(--rc-color-palette-gray-900),
      'bd': var(--rc-color-palette-gray-200),
    ),
    'flat': (
      'bg': var(--rc-color-palette-gray-300),
      'bg-hover': var(--rc-color-palette-gray-200),
      'text': var(--rc-color-palette-gray-900),
      'bd': var(--rc-color-palette-gray-300),
    ),
  );

  @each $name, $defs in $legacy-btn-colors {
    &.btn-#{$name} {
      @include legacy-button(
        $background-color: map.get($defs, 'bg'),
        $background-color-hover: map.get($defs, 'bg-hover'),
        $text-color: map.get($defs, 'text'),
        $border-color: map.get($defs, 'bd'),
      );
    }
  }

  // Simple Themes
  &.btn-inverse-simple {
    @include button-simple(var(--rc-color-palette-bw-white));

    @include breakpoint-tablet-up {
      &:hover {
        background: var(--rc-color-palette-gray-900);
        border-color: var(--rc-color-palette-gray-900);
      }
    }
  }

  &.btn-white-simple {
    @include button-simple(var(--rc-color-palette-bw-white));

    @include breakpoint-tablet-up {
      &:hover {
        color: var(--rc-color-text-primary);
      }
    }
  }

  &.btn-blue-simple {
    @include button-simple(var(--rc-color-palette-blue-600));
  }

  &.btn-orange-simple {
    @include button-simple(var(--rc-color-palette-vu-orange-500));
  }

  &.btn-grey-simple {
    @include button-simple(var(--rc-color-palette-gray-900));
  }

  &.btn-black-simple {
    @include button-simple(var(--rc-color-palette-gray-900));
  }

  &.btn-green-simple {
    @include button-simple(var(--rc-color-sentiment-positive-dark));
  }

  // Sizes
  &.btn-mini {
    padding: 3px 6px;
    line-height: 1.2rem;
    font-weight: normal;
    font-size: 1.2rem;
  }

  &.btn-inline-edit {
    padding: 4px 8px;
    line-height: 1.2rem;
    font-size: 1.2rem;
  }

  &.btn-tiny {
    padding: 5px 6px;
    font-weight: bold;
    line-height: 1.3rem;
    font-size: 1.3rem;
  }

  &.btn-small {
    padding: 7px 8px;
    font-weight: bold;
    line-height: 1.4rem;
    font-size: 1.4rem;
  }

  &.btn-large {
    padding: 13px 16px;
    line-height: 1.6rem;
    font-size: 1.6rem;
  }

  &.btn-huge {
    padding: 13px 16px;
    font-size: 2.4rem;
  }

  &.btn-link {
    padding: 0;
    font-weight: normal;
    text-decoration: none;
    color: var(--rc-color-palette-blue-600);
    background: none;
    border: none;

    @include breakpoint-tablet-up {
      &:hover {
        color: var(--rc-color-palette-vu-orange-500);
      }
    }
  }

  &.btn-dashboard-search {
    @include breakpoint-tablet-up {
      width: 60px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.hidden {
    display: none;
  }

  .btn-group & {
    display: block;
    float: left;
    border-width: 1px 0 1px 1px;
    border-radius: 0;

    &.btn-active,
    &.wysihtml5-command-active {
      background: var(--rc-color-palette-gray-200);
      box-shadow: inset 0 1px 5px #BEBEBE;
    }

    &.btn-small {
      padding: (7/14)+rem (8/14)+rem;
    }
  }

  .product-module & {
    width: 190px;
    margin-top: 10px;
    text-transform: uppercase;
  }
}

// Button groups
.btn-group {
  display: inline-block;

  &.btn-group--radios {
    input[type='radio'] {
      display: none;

      &:checked + .btn-group--radios__btn {
        background: var(--rc-color-palette-gray-200);
        box-shadow: inset 0 1px 5px #BEBEBE;
        cursor: default;
      }

      &:disabled + .btn-group--radios__btn {
        cursor: default;
      }
    }
  }

  > :first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    &.btn-active {
      border-right: none;
    }
  }

  > :last-of-type {
    border-width: 1px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

// Social Buttons
.social-trackable {
  display: flex;
  align-items: center;
  margin: 0 var(--rc-space-1);
  justify-content: center;

  a {
    display: block;
    padding: var(--rc-space-2) var(--rc-space-half);
  }
}

@mixin btn-clean {
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  padding: 0;
}

.btn-clean {
  @include btn-clean;
  @include selected-anchor;

  &.fine-print {
    font-size: 1.2rem;
    color: var(--rc-color-palette-gray-500);
  }
}

.btn-anchor {
  @include btn-clean;
  @include selected-anchor;

  color: var(--rc-color-palette-blue-600);
}

.btn-link {
  @include btn-clean;

  color: var(--rc-color-palette-blue-600);

  &:disabled {
    color: var(--rc-color-text-primary);
  }
}
