.product-card__pg-price {
  position: absolute;
  right: 10px;
  top: 10px;
  background: var(--rc-color-shadow-600);
  color: var(--rc-color-palette-bw-white);
  font-size: 12px;
  padding: 0.35em 0.5em 0.3em 0.5em;
  font-weight: bold;
  border-radius: 4px;
}

.pg-header {
  @include scaling(padding-top, 2em);
  @include scaling(padding-bottom, 2em);

  background: var(--rc-color-palette-gray-900) url('../images/price-guides/pg-header.jpg') center no-repeat;
  background-size: cover;
  color: var(--rc-color-palette-bw-white);
  margin-bottom: 20px;

  &.pg-header--homepage {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 0;
  }
}

.pg-header__frame {
  display: flex;
  align-items: center;

  @include mobile {
    display: block;
  }
}

.pg-header__frame__title-col {
  flex: 1 1 auto;
  max-width: 65%;

  @include mobile {
    text-align: center;
    max-width: initial;
    margin-bottom: 1rem;
  }
}

.pg-header__frame__search-col {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;

  @include mobile {
    justify-content: center;
  }
}

.pg-header__title {
  font-size: 36px;
  font-weight: bold;

  a {
    color: var(--rc-color-palette-bw-white);

    &:hover {
      color: var(--rc-color-palette-vu-orange-500);
    }
  }

  small {
    font-size: 40%;
    display: block;
  }
}

// have to nest this selector here out of paranoia re: legacy scoping
table.transactions {
  @include responsive(font-size, 1.5rem, 1.4rem, 1.3rem);

  .pg-transactions-panel & {
    font-size: 13px;
  }

  td,
  th {
    border: none;
    padding: 6px 10px;

    &.asking {
      text-align: right;
    }

    &.final  {
      text-align: right;
      padding-right: 0;
    }

    .transaction-history & {
      &:first-child {
        padding-left: 0;
      }
    }
  }

  td {
    background: var(--rc-color-palette-bw-white);
  }
}

.pg-match-container {
  .match-results {
    padding: 8px;
    max-height: 90vh;
  }
}

.price-guide-match {
  width: 365px;
  display: block;
  overflow: hidden;
}

.pg-transactions-blur {
  filter: blur(4px);
}
