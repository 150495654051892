.dashboard-selling-table {
  thead {
    background-color: var(--rc-color-background-module-muted);
  }

  .table-sale-title {
    width: 25%;
  }

  .table-sale-duration {
    width: 22%;
  }
}
