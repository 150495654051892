.mobile-app-article {
  padding-top: 3vw;
  padding-bottom: 3vw;
  height: auto !important;
  background-color: var(--rc-color-palette-bw-white);

  .blog-navigation,
  .blog-post__header,
  .blog-post__byline {
    display: none;
  }

  /*FIX FOR VIDEOS*/
  iframe[src^='https://www.youtube'] {
    width: 100% !important;
    height: 200px;
  }

  // Don't hack height for the responsive embeds
  .video-embed {
    iframe[src^='https://www.youtube'] {
      height: 100%;
    }
  }

  /*HIDE SPOTIFY EMBED*/
  iframe[src^='https://embed.spotify'] {
    display: none !important;
  }

  /*HIDE IFRAMED YT SUBSCRIBE BUTTON*/
  div[id^='___ytsubscribe'] {
    display: none !important;
  }

  .article-image-header {
    display: none !important;
  }

  /*SPECIAL CASES*/
  .introsection {
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-bottom: 20px !important;
    margin-bottom: 40px !important;
    border-bottom: 1px solid var(--rc-color-palette-gray-200) !important;
  }

  .hide-in-mobile-app-article {
    display: none !important;
  }

  .video-icon-img {
    height: auto !important;
  }

  /*IFRAME REPLACEMENT LINKS*/
  .iframe-replacement-link {
    border: 1px solid var(--rc-color-border-primary);
    border-radius: 5px;
    height: 60px;

    .iframe-view-text {
      margin-top: 12px;
      font-size: 0.9em !important;
      color: var(--rc-color-text-secondary) !important;
      line-height: 1 !important;
      margin-bottom: 6px !important;
    }

    .iframe-logo {
      height: 60px !important;
      width: 160px !important;
    }
  }

  .iframe-view-frame {
    height: 60px;
    border-left: 1px solid var(--rc-color-palette-gray-200);
  }
}
