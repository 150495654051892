#share {
  transition: all 0.3s ease-in;
  @include mobile {
    margin-top: 28px;
  }

  &.active {
    border: transparent;
    background: transparent;
  }
}

.shareinput {
  position: relative;
}

.shareinput__popover {
  @include box-shadow;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
  background: var(--rc-color-background-module-secondary);
  border: 1px solid var(--rc-color-border-primary);
  width: 22rem;
  padding: 10px;
  display: none;
  z-index: 2;

  @include mobile {
    left: unset;
    right: 0;
  }

  input[type='text'] {
    box-sizing: border-box;
    width: 100%;
    padding: 6px;
    border: 1px solid var(--rc-color-border-primary);
    color: var(--rc-color-text-primary);
    border-radius: 4px;
  }

  p {
    font-size: 0.9em;
    margin-bottom: 0.5em;
    line-height: 1.4;
  }

  .shareinput--active & {
    display: block;
  }
}

.shortlink-url,
.shareinput__trigger {
  @include button-reset;

  color: var(--rc-color-text-secondary);
  font-weight: bold;
  font-size: 13px;

  .fa {
    font-size: 1.4em;
    vertical-align: middle;
    margin-right: 0.25em;
  }

  &:hover {
    color: var(--rc-color-palette-vu-orange-500);
  }

  .shareinput__trigger__watch & {
    @include button-as-link;
    font-size: 90%;
  }
}

.shortlink-input {
  border-radius: 4px;
  box-shadow: inset 2px 2px 5px #f5f5f5;
  font-size: 1.4rem;
  padding: 4px 6px;
  border: 1px solid var(--rc-color-border-primary);

  &.seller-listing-share {
    max-width: 180px;
  }
}

.share {
  @include clearfix;

  .icons {
    float: left;

    a { color: var(--rc-color-palette-blue-300); }
  }

  .fb-like {
    float: right;
    margin-top: 8px;
    height: 20px;
    width: 75px;
  }
}

.social {
  span[class*="share-"] {
    margin-right: 4px;
  }
}

.social {
  .btn span[class*="fa-"] {
    margin-right: 0;
  }
}

.product-social {
  span[class*="share-"] {
    margin-right: 4px;
  }

  .btn span[class*="fa-"] {
    margin-right: 0;
  }
}

.big-social {
  .btn {
    opacity: 0.4;
    &:hover{opacity: 1;}
    margin-right: 4px;
  }
}

// this has been refactored to remove extends
// but still needs a full rewrite

.share-linkedin,
.share-facebook,
.share-twitter,
.share-google-plus,
.share-pinterest,
.share-email {
  display: block;
  color: var(--rc-color-palette-bw-white);
  float: left;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  font-size: 1rem;
  padding-top: 5px;
  width: 27px;
  height: 27px;
  &.no-icon-bg {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    font-size: 1.4rem;
    min-width: 2rem;
  }
}

.share-linkedin {
  @include social-button(var(--rc-color-palette-gray-700));
}

.share-facebook {
  @include social-button(var(--rc-color-vendor-facebook));
}

.share-twitter {
  @include social-button(var(--rc-color-vendor-twitter));
}

.share-google-plus {
  @include social-button(#DD4B39);
}

.share-pinterest {
  @include social-button(#C42723);
}

.share-email {
  @include social-button(var(--rc-color-palette-gray-700));
}

.share-x-social {
  @include social-button(var(--rc-color-vendor-twitter));
  font-size: 1.6rem;
  fill: currentColor;

  svg.x-social-svg {
    display: flex;
    width: 100%;
    height: 1em;
  }
}

.social {
  .btn span[class*="fa-"] {
    margin-right: 0;
  }
}

.product-social {
  span[class*="share-"] {
    margin-right: 4px;
  }

  .btn span[class*="fa-"] {
    margin-right: 0;
  }
}

.social-block {
  margin-top: 4px;
}

.fb-contain {
  position: relative;
  width: 76px;
}

.fb-social {
  margin-top: 4px;
  width: 76px;
  overflow: hidden;
  position: absolute;
  @media screen and (max-width: 640px) {
    overflow: visible;
    margin-top: -10px;
  }
}

.social-block {
  @media screen and (max-width: 640px) {
    margin-top: 1rem;
    .product-social {
      float: left;
    }
  }

  @media screen and (max-width: 640px) {
    .social {
      float: left;
    }
  }
}
