.module-label {
  padding: 10px 18px;
  color: var(--rc-color-palette-bw-white);
  background: var(--rc-color-palette-gray-900);
  border-radius: 3px 3px 0 0;
  @include mobile { padding: 10px; }

  h3 {
    font-weight: normal;
    color: var(--rc-color-palette-bw-white);
  }

  &.module-label-error { background-color: var(--rc-color-text-danger) !important; }
}

.module-header {
  padding: 14px 18px 12px;
  border-bottom: 1px solid var(--rc-color-background-page-secondary);
  background: var(--rc-color-palette-gray-100);
  border-radius: 3px 3px 0 0;
}

.module {
  @include clearfix;
  background: var(--rc-color-background-module);
  box-sizing: border-box;
  padding: 18px;
  border: 0.1rem solid var(--rc-color-border-primary);

  .gray, .count {
    color: var(--rc-color-text-primary);
  }

  &.extra-padded { padding: 28px; }

  &.lg-extra-padded {
    padding: 28px;
    @include mobile { padding: 18px; }
  }

  &.huge-extra-padded {
    padding: 5rem 18rem;
  }

  &.module-background-img {
    background: #477492 url('../images/sidebar/sidebar-module-background.png');
    background-size: cover;
    color: var(--rc-color-palette-bw-white);

    a { color: var(--rc-color-palette-bw-white); }
  }

  &.module-gray {
    background-color: #f8f8f8;
  }

  &.module-muted {
    background-color: var(--rc-color-background-module-muted);
  }

  &.module--bordered {
    border: dashed 2px var(--rc-color-border-primary);
  }
}

.module-shadow {
  @include box-shadow;
}

.module.purchase-module-header {
  background-color: var(--rc-color-palette-gray-100);
  border-bottom: 1px solid var(--rc-color-border-primary);
  text-align: center;
}

.module.module-footer {
  padding: 14px;
  border-top: 1px solid var(--rc-color-border-primary);
  background-color: var(--rc-color-background-module-secondary);

  &.footer-dark { background: var(--rc-color-palette-gray-200); }

  &.payment-methods-footer {
    padding: 16px;
    ul li {
      float: left;
      margin-right: 12px;
      &:last-of-type { margin-right: 0; }

      & p { margin-top: 4px; }
    }
  }
}

.btm-round-module {
  border-radius: 0 0 $input-border-radius $input-border-radius;
}

.btm-square-module {
  border-radius: $input-border-radius $input-border-radius 0 0;
}

.mini-module-heading {
  background: var(--rc-color-palette-gray-900);
  padding: 6px 10px;
  border-radius: $input-border-radius $input-border-radius 0 0;
  box-sizing: border-box;

  h3, h5 {
    color: var(--rc-color-palette-bw-white);
    text-transform: none;
  }
}

.mini-module {
  padding: 8px 10px;
  @include clearfix;
  background: var(--rc-color-palette-bw-white);
  box-sizing: border-box;

  // This is part of a hack to make selected categories bold
  // b/c there's not active class on selected facets that aren't brands
  &.sidebar-facet {
    @include box-shadow;
    font-size: 15px;
    color: var(--rc-color-text-accent);
    font-weight: bold;
    border-radius: 0 0 4px 4px;
    margin-bottom: 2rem;

    .ships-to-me { color: var(--rc-color-text-primary); }
  }
}


.categories-sidebar .mini-module {
  // This is a hack b/c we don't have an active class
  // for selected facets that aren't brands
  a { font-weight: normal; }

  ul { padding-left: 12px; }

  li {
    line-height: 1em;
    margin: 9px 0;
  }

  .ships-to-me li {
    margin: 0;
    font-weight: normal;
  }

  .count {
    color: var(--rc-color-text-secondary);
    font-weight: normal;
    float: right;
  }

  .category-name {
    display: inline-block;
    max-width: 150px;
  }

  .subcategory-name {
    display: inline-block;
    max-width: 136px;
  }
}
