
$offer-message-avatar-width: 5.4rem;
$offer-message-avatar-margin: 1.4rem;

.offer-messages {
  display: flex;
  flex-direction: column;
  gap: var(--rc-space-4);

  // set min width inside fancybox to avoid layout breakage
  // due to fancybox's auto-width js "magic"
  .fancybox-inner & {
    min-width: 55rem;

    @include mobile {
      min-width: 0;
    }
  }
}

.offer-message {
  display: flex;
  justify-content: space-between;
  margin: 0 ($offer-message-avatar-width + $offer-message-avatar-margin);
  width: 60%;
  max-width: 100%;

  @include tablet {
    width: 80%;
  }

  @include mobile {
    margin: 0;
    width: 90%;
  }

  &.offer-message--me {
    margin-right: 0;
    align-self: flex-end;
  }

  &.offer-message--them {
    margin-left: 0;
  }

  &.offer-message--system {
    align-self: center;
  }

  // override for embedded listings
  .product-row-card--embedded {
    @include mobile {
      float: none;
      width: 100%;
    }
  }
}

.offer-message__avatar {
  flex: 0 0 $offer-message-avatar-width;
  margin: 0 $offer-message-avatar-margin 0 0;

  @include mobile {
    display: none;
  }

  .offer-message--me & {
    order: 2;
    margin: 0 0 0 $offer-message-avatar-margin;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 50%;

    @include mobile {
      transform: translateY(1.3rem);
    }
  }

  a {
    display: block;
    margin-top: var(--rc-space-2);

    @include mobile {
      margin: 0;
    }
  }

}

.offer-message__bubble {
  flex: 1;
  background-color: var(--rc-color-background-alert-plain);
  border-radius: var(--rc-border-radius-lg);
  border: var(--rc-color-border-primary);
  padding: var(--rc-space-5);
  position: relative;
  border: none;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  .offer-message--me & {
    order: 1;
    background-color: var(--rc-color-background-alert-info);
    border-color: var(--rc-color-border-alert-info);
  }

  .offer-message--system & {
    background-color: var(--rc-color-background-module);
    border: dashed 0.2rem var(--rc-color-border-primary);
  }
}

.offer-message__header {
  display: flex;
  justify-content: space-between;

  @include mobile {
    display: block;
  }
}

.offer-message__title {
  font-weight: 700;
  font-size: 1.6rem;
}

.offer-message__title__qty {
  font-weight: normal;
  font-size: 90%;
}

.offer-message__created-at {
  font-size: 1.2rem;
  color: var(--rc-color-text-secondary);
  margin: 0;
}

.offer-message__message {
  margin: 0.6rem 0 0;
  font-size: 1.6rem;
  @include hyphenate;

  p {
    margin: 1rem 0;
  }

  > :first-child {margin-top: 0;}
  > :last-child {margin-bottom: 0;}
}

.offer-message__recipient-info {
  margin: 0 0 0.6rem;
}

.offer-message__user-and-location {
  font-size: 1.2rem;
  color: var(--rc-color-text-secondary);
}

.offer-message__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.6rem 0 0;
}

.offer-message__expiration-date {
  font-size: 1.2rem;
  color: var(--rc-color-text-secondary);
  margin-bottom: 0;
}

.offer-message__breakdown-toggle {
  @include fa-icon-after(chevron-down, $margin-left: 0.25em);

  &[data-toggle-container-state='open'] {
    @include fa-icon-after(chevron-up, $margin-left: 0.25em);
  }
}

.offer-message__breakdown-wrapper {
  display: none;
  margin-top: 0.8rem;
}

.offer-message__actions-wrapper {
  margin-top: 1.4rem;

  .offer-action__wrapper {
    margin-top: 0.5rem;

    @include mobile {
      display: block;

      .offer-action {
        width: 100%;
        margin: 0 0 0.4rem;
      }

      &:last-child {
        .offer-action {
          margin-bottom: 0;
        }
      }
    }
  }
}

.update-offer-actions {
  .offer-action__wrapper {
    display: inline-block;
    margin-right: 0.5rem;
  }
}
