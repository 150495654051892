.owl-theme {
  &:hover {
    .owl-controls .owl-buttons {
      opacity: 1.0;
    }
  }

  .owl-controls {
    margin: 0 0 10px;

    // Styling Next and Prev buttons
    .owl-buttons {
      position: absolute;
      top: 50%;
      width: 100%;
      margin-top: -48px;
      text-align: left;
      opacity: 0;
      transition: opacity 0.2s linear;

      [class^=owl] {
        width: 48px;
        height: 48px;
        padding: 0;
        color: transparent;
        background: url('../images/top-level/fotorama-custom.png') no-repeat;
        @include image-2x("../images/top-level/fotorama-custom@2x.png", 274px, 90px);
        box-sizing: border-box;
      }

      .owl-next {
        float: right;
        background-position: -48px 0;
      }
    }
  }
}
