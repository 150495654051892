@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.locale-modal__title {
  margin-bottom: 7px;
}

.local-modal__select {
  @include styled-dropdown;
  display: block;

  select {
    display: block;
    width: 100%;
  }
}

.locale-modal__footer {
  text-align: center;
  margin-top: 2rem;
}

.locale-modal__submit {
  @include rc-button.filled;
  margin: 0;
}
