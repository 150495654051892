.alternate-checkout__header {
  background-color: var(--rc-color-palette-bw-white);
  padding: 1.25rem 1rem;
  width: 100%;

  .body-wrapper {
    min-height: 100vh;
  }

  h1 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: 0.5rem;
    @include breakpoint-tablet-up {
      flex-basis: auto;
      text-align: right;
      font-size: 1.5rem;
      margin-top: 0;
    }
  }
}

.alternate-checkout__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  // Max width to match .site-wrapper--cms
  box-sizing: content-box;
  max-width: $site-width-cms;

  @include breakpoint-tablet-up {
    flex-direction: row;
  }
}

.alternate-checkout__shop {
  flex: 1;
  img {
    max-height: 5rem;
    width: 100%;
    max-width: 10rem;
  }
  @include breakpoint-tablet-up {
    flex-basis: auto;
  }
}

.alternate-checkout__shop-name {
  flex: 1;
  flex-basis: 100%;
  width: 100%;
  @include breakpoint-tablet-up {
    flex-basis: auto;
    margin-top: 0;
  }
}

.alternate-checkout__footer {
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  img {
    width: 100%;
    max-width: 7rem;
    height: auto;
    margin: auto;
    margin-bottom: 0.5rem;
  }
  h2 {
    font-size: 1rem;
  }
}
