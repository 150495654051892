.ui-datepicker {
  display: none;
  border: 1px solid var(--rc-color-border-primary);
  background: white;

  [class*="fa-"] {
    background: none;
  }

  .ui-datepicker-header {
    background: var(--rc-color-palette-gray-200);
    border-bottom: 1px solid var(--rc-color-border-primary);
    padding: 0.5em;
  }
  .ui-datepicker-title { text-align: center; font-weight: bold; }
  .ui-datepicker-next { cursor: pointer; float: right; }
  .ui-datepicker-prev { cursor: pointer; float: left; }
  .ui-datepicker-calendar { padding: 1em; }

  .ui-datepicker-today { background: #fcf8e3; }
  th { font-weight: bold; }
  td, th { padding: 0.5em; }
  td { border: 1px solid #ddd; text-align: center; }
  thead { background: var(--rc-color-palette-gray-100); border-bottom: 1px solid #ddd }
}
