.in-mobile-app {
  .gift_cards .page-hero--photo-fill {
    display: none;
  }

  .body--gift-card-page {
    .page {
      padding: 0 1rem;
    }
  }
}

// show animated success in hero on redeem page
.gift-card-redeeem-success {
  width: 218px;
  height: 135px;
  background: url('../images/referrals/reverb-bucks-icon-white.png') center no-repeat;
  margin: 0 auto;

  &.run-animation {
    animation: popin 0.8s ease-in-out 1;
  }
}

