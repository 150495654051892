@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
@use '@reverbdotcom/cadence/styles/tools/mixins/truncate-text';

.digital-listing-gallery {
  position: relative;
}

.digital-listing-gallery .fotorama__nav__frame .fotorama__video-play {
  background-size: 405%;
  background-position: -164px 0;
  width: 54px;
  height: 54px;
  margin: -27px 0 0 -27px;
}

.digital-product-card {
  @include box-shadow;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 18px;
  background: var(--rc-color-palette-bw-white);
  position: relative;
  margin-bottom: 18px;

  @include mobile {
    display: block;
    padding: 0;
    margin-bottom: 3%;
    @include grid-column(6, $cycle: true);
  }
}

.digital-product-card__link {
  display: table;
  width: 100%;

  @include mobile {
    display: block;
  }
}

.digital-product-card__image {
  display: table-cell;
  vertical-align: top;
  width: 100px;

  @include mobile {
    display: block;
    width: 100%;

    img {
      border-radius: 3px 3px 0 0;
    }
  }
}

.digital-product-card__body {
  display: table-cell;
  vertical-align: top;
  padding: 0 3%;

  @include mobile {
    display: block;
    padding: 8px 8px 0 8px;
    border-top: 1px solid var(--rc-color-palette-gray-200);
  }
}

.digital-product-card__body-title {
  color: var(--rc-color-text-primary);
  font-size: 1.8rem;
  font-weight: 700;

  @include mobile {
    @include truncate-text.truncate-text($font-size: 1.3rem,
      $line-height: 1.4,
      $lines-to-show: 2,
      $fixed-height: true);
  }
}

.digital-product-card__body-brand {
  @include show-only-on-desktop;
  font-size: 1.2rem;
  color: var(--rc-color-text-secondary);
  line-height: 1.2rem;
  margin: 0;
}

.digital-product-card__body-summary {
  @include show-only-on-desktop;
  @include truncate-text.truncate-text($font-size: 13px,
    $line-height: 1.4,
    $lines-to-show: 2);
  color: var(--rc-color-text-primary);
  padding: 8px 20% 0 0;
  position: relative;
  margin: 8px 0 0 0;

  &:before {
    content: "";
    display: block;
    border-top: 1px solid var(--rc-color-border-primary);
    width: 50%;
    position: absolute;
    top: 0;

    @include mobile {
      display: none;
    }
  }
}

.digital-product-card__actions {
  display: table-cell;
  vertical-align: top;
  white-space: nowrap;
  width: 100px;

  @include mobile {
    display: block;
    width: 100%;
  }
}

.digital-product-card__price {
  color: var(--rc-color-text-primary);
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-align: center;

  @include mobile {
    display: block;
    font-size: 1.3rem;
    color: var(--rc-color-palette-vu-orange-500);
    padding: 8px;
    text-align: left;
  }
}

.digital-product-card__view-details {
  @include show-only-on-desktop;
  @include rc-button.filled;
  font-size: 0.9em;
  margin-top: 0.5rem;
  width: 100%;
}

.digital-product-card__operating-systems {
  @include show-only-on-desktop;
  text-align: center;
  margin-top: 1rem;
  color: var(--rc-color-text-secondary);
}
