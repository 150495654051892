@use '@reverbdotcom/cadence/styles/tools/mixins/links';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.order-actions {
  @include clearfix;
  border-top: 0.1rem solid var(--rc-color-border-primary);
  border-left: none;
  border-right: none;
  padding: var(--rc-space-4);

  .row {
    margin-top: 0;
    margin-bottom: 0;
  }

  a.btn,
  .button {
    float: right;
    @media screen and (min-width: 641px) {
      margin-left: 9px;
    }
    @media screen and (max-width: 640px) {
      width: 100%;
      margin-left: 0;
      margin-bottom: 0.8rem;
    }
  }

  p {
    float: left;
    margin-left: 5px;
    padding: 4px 0;

    @include mobile {
      margin-bottom: 1rem;
    }
  }

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 6px 6px;
  }

  .accept {
    @media screen and (max-width: 640px) {
      width: 100%;
      box-sizing: border-box;
    }

    .message {
      width: 100%;
      box-sizing: border-box;
      margin: 10px 0 4px 0;
      margin: (10/14)+rem 0 (4/14)+rem 0;

      &.speech-bubble:before {
        margin-left: 25px;
      }
      &.speech-bubble:after {
        margin-left: 26px;
      }
    }
  }

  &.alert-success {
    background: var(--rc-color-background-alert-success);
  }

  &.alert-error {
    background: var(--rc-color-background-alert-error);
  }

  &.alert-error {
    background-color: var(--rc-color-sentiment-negative-lighter);
  }

  &.alert-warn { background: var(--rc-color-sentiment-warning-lighter); }

  &.more-order-actions {
    padding-top: 0;
    padding-bottom: 0;

    .toggler::before { border-color: transparent var(--rc-color-text-primary); }
    .toggler.open::before {
      border-color: var(--rc-color-text-primary) transparent;
      margin-top: 2px;
    }

    .toggler {
      @include mobile { margin-left: 1.5rem; }
    }
  }

  .order-icon-orange { color: var(--rc-color-text-accent); }
  .order-icon-green { color: var(--rc-color-text-success); }
  .order-icon-blue { color: var(--rc-color-palette-blue-600); }
  .order-icon-grey { color: var(--rc-color-text-primary); }
}

.order .order-contact-button {
  @include mobile { text-align: left; }
}

ul.dashboard-list {
  font-size: 1.6rem;

  li.order {
    @include clearfix;
    border-radius: var(--rc-border-radius-lg);
    background-color: var(--rc-color-background-module);
    border: 0.1rem solid var(--rc-color-border-primary);
    margin-bottom: var(--rc-space-8);

    &.order--in-module {
      border: 0;
      margin: 0;

      + .order {
        margin-top: 1rem;
      }

      .order-actions {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .header {
      &.padded {
        padding: var(--rc-space-4) var(--rc-space-4) 0;
      }

      a {
        @include links.text-link;
      }
    }

    // the <a> around the thumbnail
    > .g-container {
      &.padded {
        padding: var(--rc-space-4);
      }

      > .g-col-2 > a {
        background-color: var(--rc-color-palette-gray-100);
        display: block;
        border-radius: var(--rc-border-radius-lg);
        overflow: hidden;
      }
    }

    .heading-4 {
      @include rc-text.default($weight: 'bold');

      a {
        @include links.text-link;
      }
    }

    // style the phone number link
    a[href^="tel"] {
      @include links.text-link;
    }

    .actions {
      @extend .order-actions;
    }

    .progress-bar.on-gray-bg li {
      border-color: var(--rc-color-background-module);
    }
  }
  .button {
    white-space: normal;
  }
}
