.good-callout {
  padding: 2rem;
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 1.4rem;

  @include mobile {
    flex-basis: 100%;
  }

  &.good-callout--footer {
    margin: 2rem 0;
    max-width: 45rem;
    padding: 0 2rem 0 0;

    @include tablet {
      flex-direction: column;
      max-width: 100%;
      padding: 0 1rem;
    }
  }
}

.good-callout__icon-container {
  display: flex;
}

.good-callout__icon {
  height: 3rem;
  width: 3rem;
  fill: var(--rc-color-palette-gray-900);

  .good-callout--footer & {
    fill: var(--rc-color-palette-gray-500);
  }
}

.good-callout__content {
  margin-left: 2rem;

  .good-callout--footer & {
    @include tablet {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}

.good-callout__title {
  font-weight: bold;
}

.good-callout__description {
  opacity: 0.8;
}
