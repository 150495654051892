.toggleable {
  display: none;
}

.toggler {
  margin: 0 0 9px 18px;
}

.toggler:before {
  content: "";
  border-color: transparent #111;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 0.9em;
  position: relative;
}

.toggler.open:before {
  border-color: #111 transparent;
  border-width: 0.45em 0.35em 0 0.35em;
}
