.pgs-modal {
  box-sizing: border-box;
  width: 360px;
  text-align: center;

  @include mobile {
    width: 100%;
  }

  .actions {
    display: none;
  }

  .body {
    padding: 0;
  }
}

.pgs-modal__logo {
  background-color: var(--rc-color-palette-gray-200);
  @include scaling(padding-top, 2rem);
  @include scaling(padding-bottom, 2rem);

  img {
    width: 100px;
    height: 100px;
  }
}

.pgs-modal__greeting {
  @include scaling(padding-top, 3rem);
  @include scaling(padding-bottom, 1rem);
  @include scaling(padding-left, 2rem);
  @include scaling(padding-right, 2rem);
  @include scaling(margin-bottom, 1rem);
  position: relative;
  font-size: 18px;
  font-weight: bold;
  color: var(--rc-color-text-primary);
  box-sizing: border-box;

  &:after {
    content: "";
    height: 2px;
    left: 50%;
    margin-left: -10%;
    position: absolute;
    bottom: -1px;
    width: 20%;
    background-color: var(--rc-color-palette-gray-900);
  }
}

.pgs-modal__message {
  @include scaling(padding-bottom, 3rem);
  @include scaling(padding-left, 3rem);
  @include scaling(padding-right, 3rem);
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;

  @include mobile {
    width: 100%;
  }
}
