%overlay {
  position: absolute;
  margin-top: 0;
  background: white;
  opacity: 0.95;
}

.overlay { @extend %overlay; }

.disabled-overlay {
  @include clearfix;
  position: relative;
}

.disabled-overlay:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--rc-color-background-page-secondary);
  opacity: 0.5;
}

.lightbox-only {
  display: none;
}
