table.statement-table {
  font-size: 1.2rem;

  thead { background: var(--rc-color-background-module-muted); }

  .paid-out { color: var(--rc-color-text-alert-success); }

  &.totals {
    width: 270px;
    @include mobile { width: 100%; }

    td {
      padding: 3px;
    }

    tr:last-of-type {
      border-top: 1px solid var(--rc-color-border-primary);

      td {
        padding-top: 6px;
      }
    }

    tr:nth-last-of-type(2) {
      td {
        padding-bottom: 6px;
      }
    }

  }

  @include mobile {
    font-size: 11px;
  }

  td {
    &.money { width: 90px; }
    &.date { width: 80px; }
    &.description-sale_fee { width: 375px; }
    &.description-credit { width: 480px; }
    &.amount-paid { width: 100px; }
    &.amount-owed { width: 100px; }
    &.status {
      &.badge {
        width: 100px;
      }
      &.unpaid {
        color: var(--rc-color-text-price-nudge);
      }
    }
    &.view {
      width: 40px;
    }
  }
}
