@use '../../config/configuration';
@use '../functions/grid' as functions-grid;
@use 'clearfix';
@use 'responsive';

@use 'sass:math';

@mixin grid-container($fullbleed: true, $context: configuration.$grid-count, $gutter: configuration.$grid-gutter) {
  $adjusted-gutter: functions-grid.relative-gutter($context, $gutter);

  @include clearfix.clearfix;

  @if ($fullbleed == true) {
    margin-left: - ($adjusted-gutter * 0.5);
    margin-right: - ($adjusted-gutter * 0.5);
  }
}

@mixin grid-column($cols, $offset: false, $cycle: false, $cycle-with-gutter: true, $gutter: configuration.$grid-gutter, $context: configuration.$grid-count) {
  $adjusted-gutter: functions-grid.relative-gutter($context, $gutter);

  width: functions-grid.column-width($cols, $context, $adjusted-gutter);
  float: left;
  margin-left: $adjusted-gutter * 0.5;
  margin-right: $adjusted-gutter * 0.5;
  box-sizing: border-box;

  @if ($offset != false) {
    @include grid-offset($offset, $context, $adjusted-gutter);
  }

  @if ($cycle == true) {
    @include grid-cycle($cols, $context, $cycle-with-gutter, $adjusted-gutter);
  }
}

@mixin grid-columns($wide, $tablet: false, $mobile: false, $cycle: false, $cycle-with-gutter: true, $gutter: configuration.$grid-gutter, $context: configuration.$grid-count) {
  @include grid-column($wide, $cycle: $cycle, $cycle-with-gutter: $cycle-with-gutter, $gutter: $gutter, $context: $context);

  @if ($tablet != false) {
    @include responsive.tablet {
      @include grid-column($tablet, $cycle: $cycle, $cycle-with-gutter: $cycle-with-gutter, $gutter: $gutter, $context: $context);
    }
  }

  @if ($mobile != false) {
    @include responsive.mobile {
      @include grid-column($mobile, $cycle: $cycle, $cycle-with-gutter: $cycle-with-gutter, $gutter: $gutter, $context: $context);
    }
  }
}

@mixin grid-cycle($cols, $context: configuration.$grid-count, $cycle-with-gutter: true, $gutter: configuration.$grid-gutter) {
  // resets any stray clears from other breakpoints
  &:nth-of-type(n) {
    clear: none;

    @if ($cycle-with-gutter == true) {
      margin-top: 0;
    }
  }

  &:nth-of-type(#{math.div($context, $cols)}n + 1) {
    clear: both;
  }

  @if ($cycle-with-gutter == true) {
    &:nth-of-type(n + #{math.div($context, $cols) + 1}) {
      margin-top: $gutter;
    }
  }
}

@mixin grid-offset($cols, $context: configuration.$grid-count, $gutter: configuration.$grid-gutter) {
  $adjusted-gutter: functions-grid.relative-gutter($context, $gutter);

  margin-left: functions-grid.column-width($cols, $context, $adjusted-gutter) + $adjusted-gutter * 1.5;
}
