.offer__negotiation {
  position: relative;
  @include clearfix;
}

.offer__negotiation__status {
  float: left;
  line-height: 1;

  @include mobile {
    float: none;
    margin: 0;
  }
}

.offer__negotiation__time {
  float: right;
  color: var(--rc-color-text-secondary);
  font-size: 90%;
  padding-top: 0.3em;

  @include mobile {
    float: none;
    padding-bottom: 0.3em;
  }
}

.offer__negotiation__actions {
  clear: both;
  font-size: 80%;

  li {
    display: inline-block;
    margin-right: 1em;
  }

  @include mobile {
    li {
      display: block;
      margin: 0;
      float: left;
      width: 50%;
    }
    a {
      display: block;
      padding: 0.3em 0;
    }
  }
}

.negotiation {
  background: var(--rc-color-palette-gray-100);
  border-top: 1px solid var(--rc-color-palette-gray-200);
  padding: 10px;
}

.offer-active,
.offer-rejected,
.offer-expired,
.offer-accepted {
  background: var(--rc-color-palette-gray-100);
}

.status-offer-active {
  @include mobile {
    .btn {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
}

.offer-history li + li {
  margin-top: 0.5rem;
}

.selling-offer-tab, .buying-offer-tab {
  position: absolute;
  visibility: hidden;
  top: -180px;
  // ^ legacy tab implementation, compensates for the browser scrolling to the id
}
