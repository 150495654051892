@use '@reverbdotcom/cadence/styles/tools/mixins/rc-text';

.blog-featured {
  background: var(--rc-color-palette-gray-900);
}

.blog-featured__inner {
  margin: 0 auto;
  max-width: 160rem;

  ul {
    width: 100%;
    display: flex;

    @include mobile {
      display: block;
    }
  }

  li {
    flex: 1;
  }
}

.blog-featured__article {
  @include overlay-gradient($hoverable: true);

  position: relative;
  height: 0;
  padding-bottom: 65%;
  background-position: top center;
  background-size: cover;

  a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }

  @include mobile {
    padding-bottom: 35%;
  }
}

.blog-featured__article__title {
  @include rc-text.title('700');
  position: absolute;
  // to prevent text-shadow cutoff from truncation's overflow: hidden
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--rc-space-8);
  color: var(--rc-color-palette-bw-white);
  text-shadow: 0 0 1rem var(--rc-color-palette-gray-900);

  @include tablet {
    padding: var(--rc-space-4);
  }
}

.blog-index {
  &.blog-index--homepage {
    @include grid-container;
  }
}

.blog-index__main {
  @include grid-columns(8, $mobile: 12);

  @include min-width(1100px) {
    @include grid-column(9);
  }
}

.blog-index__sidebar {
  @include grid-columns(4, $mobile: 12);

  @include min-width(1100px) {
    @include grid-column(3);
  }
}

.blog-index__articles {
  // special tiles overrides just for the blog
  .tiles__tile {
    @include responsive(width, 33%, 50%, 100%);
    @include responsive(flex-basis, 33%, 50%, 100%);

    min-width: 0;
    max-width: none;

    .blog-index--search-results & {
      @include responsive(width, 25%, 33%, 100%);
      @include responsive(flex-basis, 25%, 33%, 100%);
    }
  }
}

.featured-article-row {
  background-color: var(--rc-color-palette-gray-200);
}

.blog-articles__header {
  @include scaling(margin-bottom, 4rem);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    display: block;
    text-align: center;
  }
}

.blog-articles__header__text {
  flex: 1 0 auto;

  @include mobile {
    margin-bottom: 1rem;
  }
}

.blog-articles__search {
  .blog-index__sidebar & {
    margin-bottom: 2rem;
  }

  .blog-articles__header & {
    flex: 0 0 26rem;
  }
}

.blog-articles__header__query {
  font-weight: bold;
  font-size: 2.2rem;
}

.blog-articles__header__page {
  font-size: 1.6rem;
  font-weight: bold;
}

.blog-index__articles__no-results {
  max-width: 50rem;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 4rem;

  img {
    width: 100%;
    height: auto;
  }
}
