
[class*='dashboard-'],
[class*='oauth-applications'] {
  .page-header {
    @include clearfix;

    margin-bottom: 16px;

    h2 {
      float: left;
      line-height: 34px;
    }

    .actions {
      float: right;
      line-height: 34px;
    }

    > .btn {
      float: right;
    }
  }
}

.dashboard-ad-label {
  width: 120px;
}

.updating-list {
  opacity: 0.5;
}

.dashboard-list {
  > li {
    @include clearfix;

    .cell {
      display: block;
      float: left;
      margin-bottom: 1px;
      padding: 8px 10px;

      @include mobile {
        padding: 8px;
      }
    }

    &.head {
      border: none;
      font-weight: bold;
      margin-bottom: 2px;
    }
  }
}

.dashboard-nav-container {
  border-bottom: 1px solid var(--rc-color-border-primary);
  background: var(--rc-color-background-page);
}

.dashboard-nav-container__inner {
  @include site-wrapper;

  @include tablet {
    @include smooth-scroll;

    overflow-x: auto;
    overflow-y: hidden;

    .fa-chevron-down {
      display: none;
    }
  }
}

.dashboard-top-nav {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
}

.dashboard-nav-button {
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 1.4rem;
  white-space: nowrap;

  &:first-of-type {
    margin-left: 0;
  }

  [class^='fa'] {
    padding-right: 4px;
  }

  > a {
    display: block;
    padding: 15px 10px 10px;
    transition: color 0.1s;
    color: var(--rc-color-text-secondary);
    border-bottom: solid 0.4rem transparent;

    &:hover {
      color: var(--rc-color-text-primary);
      border-bottom-color: var(--rc-color-border-selectable);
    }
  }

  &.active {
    > a {
      font-weight: bold;
      color: var(--rc-color-text-primary);
      border-bottom-color: var(--rc-color-border-selected);
    }
  }

  .dropdown-menu {
    left: 0;
    min-width: 120px;
    padding: 0.6rem 0;

    a {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      color: var(--rc-color-text-primary);
      transition: 0s background-color;

      &:hover {
        background-color: var(--rc-color-background-module-hovered);
      }
    }
  }

  // make sure SVGs in this area match the other icon colors
  path {
    fill: currentColor;
  }
}

.dashboard-filter-sidebar {
  font-size: 1.4rem;

  .subfacets {
    margin-left: 1rem;
    position: relative;
  }
}
