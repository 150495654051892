@use 'sass:map';
@use 'sass:list';

// stylelint-disable cadence/no-invalid-css-vars

// Note: Size / weight options and defaults need to stay in sync with RCText.tsx

$valid-variants: 'default', 'display', 'eyebrow', 'fine', 'title', 'utility';

$default-sizes: (
  'default': '400',
  'display': '1600',
  'eyebrow': '300',
  'fine': '300',
  'title': '900',
  'utility': '400',
);

$valid-sizes: (
  'default': ('350', '400'),
  'display': ('1600'),
  'eyebrow': ('300'),
  'fine': ('300'),
  'title': ('500', '600', '700', '800', '900', '1000', '1200'),
  'utility': ('350', '400', '450', '500', '550', '600'),
);

$weight-variables: (
  'regular': var(--rc-font-weight-normal),
  'medium': var(--rc-font-weight-medium),
  'semibold': var(--rc-font-weight-semibold),
  'bold': var(--rc-font-weight-bold),
);

$default-weights: (
  'default': 'regular',
  'display': 'bold',
  'eyebrow': 'bold',
  'fine': 'regular',
  'title': 'bold',
  'utility': 'regular',
);

$valid-weights: (
  'default': ('regular', 'bold'),
  'display': ('regular', 'bold'),
  'eyebrow': ('regular', 'bold'),
  'fine': ('regular', 'bold'),
  'title': ('regular', 'bold'),
  'utility': ('regular', 'medium', 'semibold', 'bold'),
);

// Base font styles
@mixin _base(
  $line-height: var(--rc-font-line-height-default),
  $letter-spacing: var(--rc-font-letter-spacing-default)
) {
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

// Gets supported size for variant
@mixin _size($variant, $size) {
  @if index($valid-variants, $variant) {
    $size-options: map.get($valid-sizes, $variant);

    @if index($size-options, $size) {
      font-size: var(--rc-font-size-#{$size});
    }

    @else {
      @error "Unsupported size – '#{$size}' – for rc-text.#{$variant}. Choose from: #{$size-options}.";
    }
  }

  @else {
    @error "Unsupported variant - '#{$variant}' - for rc-text";
  }
}

// Gets supported weight for variant
@mixin _weight($variant, $weight) {
  @if index($valid-variants, $variant) {
    $weight-options: map.get($valid-weights, $variant);

    @if index($weight-options, $weight) {
      font-weight: #{map.get($weight-variables, $weight)};
    }

    @else {
      @error "Unsupported weight – '#{$weight}' – for rc-text.#{$variant}. Choose from: #{$weight-options}.";
    }
  }

  @else {
    @error "Unsupported variant - '#{$variant}' - for rc-text";
  }
}

// Text style mixins

@mixin default(
  $size: map.get($default-sizes, 'default'),
  $weight: map.get($default-weights, 'default')
) {
  @include _size($variant: 'default', $size: $size);
  @include _weight($variant: 'default', $weight: $weight);
  @include _base;
}

@mixin display(
  $size: map.get($default-sizes, 'display'),
  $weight: map.get($default-weights, 'display')
) {
  @include _size($variant: 'display', $size: $size);
  @include _weight($variant: 'display', $weight: $weight);
  @include _base($line-height: var(--rc-font-line-height-display), $letter-spacing: var(--rc-font-letter-spacing-heading));
}

@mixin eyebrow(
  $size: map.get($default-sizes, 'eyebrow'),
  $weight: map.get($default-weights, 'eyebrow')
) {
  @include _size($variant: 'eyebrow', $size: $size);
  @include _weight($variant: 'eyebrow', $weight: $weight);
  @include _base($line-height: var(--rc-font-line-height-eyebrow), $letter-spacing: var(--rc-font-letter-spacing-eyebrow));

  text-transform: uppercase;
}

@mixin fine(
  $size: map.get($default-sizes, 'fine'),
  $weight: map.get($default-weights, 'fine')
) {
  @include _size($variant: 'fine', $size: $size);
  @include _weight($variant: 'fine', $weight: $weight);
  @include _base;
}

@mixin title(
  $size,
  $weight: map.get($default-weights, 'title')
) {
  @include _size($variant: 'title', $size: $size);
  @include _weight($variant: 'title', $weight: $weight);
  @include _base($line-height: var(--rc-font-line-height-title), $letter-spacing: var(--rc-font-letter-spacing-heading));
}

@mixin utility(
  $size,
  $weight: map.get($default-weights, 'utility')
) {
  @include _size($variant: 'utility', $size: $size);
  @include _weight($variant: 'utility', $weight: $weight);
  @include _base($line-height: var(--rc-font-line-height-utility));
}
