@use '../../config/configuration' as config;
@use './responsive';

// This main site-wrapper mixin uses our site's max width.
@mixin site-wrapper {
  padding-left: var(--rc-space-gutter-page);
  padding-right: var(--rc-space-gutter-page);
  box-sizing: border-box;
  max-width: var(--rc-size-max-width-content);
  margin-left: auto;
  margin-right: auto;
}

// The following modifier mixins further restrict the max width.
// `box-sizing: content-box` prevents the left / right padding from encroaching on content unnecessarily

// Desktop breakpoint's max width
@mixin site-wrapper--desktop {
  box-sizing: content-box;
  max-width: config.$site-width-max;
}

// Tablet breakpoint's max width
@mixin site-wrapper--narrow {
  box-sizing: content-box;
  max-width: config.$site-width-mid;
}

// Unique max-width for CMS content
@mixin site-wrapper--cms {
  box-sizing: content-box;
  max-width: config.$site-width-cms;
}

// special classes used to bleed items left or right
// outside of the cms column

@mixin site-wrapper-overhang {
  margin-left: -5%;
  margin-right: -5%;

  @include responsive.tablet {
    margin-left: 0;
    margin-right: 0;
  }
}

@mixin site-wrapper-overhang-left {
  margin-left: -5%;

  @include responsive.tablet {
    margin-left: 0;
  }
}

@mixin site-wrapper-overhang-right {
  margin-right: -5%;

  @include responsive.tablet {
    margin-right: 0;
  }
}

