@mixin overlay-text($border-radius: 0, $text-padding: 20px 30px, $subtitle-size: 13px, $title-size: 22px, $gradient-start: 50%) {
  position: relative;

  .overlay-text-container {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translateZ(0);
    background: transparent center no-repeat;
    background-size: cover;
  }

  .overlay-text-gradient {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    border-radius: $input-border-radius;
    @include background-gradient(rgba(0,0,0,0), rgba(0,0,0,0.9), $gradient-start, 100%);
  }

  .overlay-text-block {
    position: absolute;
    bottom: 0;
    padding: $text-padding;
  }

  .overlay-text-subtitle {
    font-size: $subtitle-size;
    text-transform: uppercase;
    color: var(--rc-color-palette-bw-white);
    padding-bottom: 4px;
    margin-bottom: 4px;
    border-bottom: 1px solid var(--rc-color-palette-bw-white);
  }

  .overlay-text-title {
    color: var(--rc-color-palette-bw-white);
    font-size: $title-size;
    line-height: 1.3em;
    font-weight: bold;
  }
}

