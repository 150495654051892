$chartist: (
  line-color-a: var(--rc-color-legacy-condition-brand-new),
);

.chartist-tooltip {
  display: none;
}

.chartist-tooltip__override {
  position: absolute;
  display: inline-block;
  min-width: 4em;
  font-weight: bold;
  padding: .5em;
  background: #333;
  color: var(--rc-color-palette-bw-white);
  text-align: center;
  pointer-events: none;
  z-index: 99999999;
  border-radius: 4px;
  margin-top: 10px;
}

.chartist-tooltip__override:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -6px;
  border: 6px solid transparent;
  border-top-color: #333;
}

