// legacy; use design system grids instead

.card-grid {
  @include clearfix;
}

.card-grid__item {
  margin-bottom: $grid-gutter;

  .card-grid--4up & {
    @include mobile {
      @include grid-column(6, $cycle: true);
    }
    @include breakpoint-tablet-up {
      @include grid-column(3, $cycle: true);
    }
  }
}

