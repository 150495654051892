.content-banner {
  padding: 12px 14px;
  background-color: var(--rc-color-palette-vu-orange-100);
  border-bottom-color: var(--rc-color-palette-vu-orange-300);
}

.content-banner__message {
  font-size: 14px;
  margin: 0;
}
