@use 'button-reset';
@use 'responsive-hover';

@mixin button-as-link {
  @include button-reset.button-reset;

  color: var(--rc-color-text-link);
  fill: var(--rc-color-text-link);
  font-size: 1em;
  cursor: pointer;
  transition: opacity .2s;

  @include responsive-hover.responsive-hover {
    color: var(--rc-color-text-link-hovered);
    fill: var(--rc-color-text-link-hovered);
  }

  &[disabled],
  &[disabled]:hover {
    color: var(--rc-color-text-primary-disabled);
  }
}
