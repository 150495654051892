@use '@reverbdotcom/cadence/styles/tools/mixins/links';
@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.dash-listing-cards {
  .dash-listing-card {
    margin: 7px 0;

    &:first-child { margin-top: 0; }

    &:last-child { margin-bottom: 0; }
  }
}

.dash-listing-card {
  border-radius: 4px;
  border: solid 1px var(--rc-color-border-primary);
  padding: 14px;
  background-color: var(--rc-color-background-page);

  @include mobile {
    display: block;
    padding: 10px;
  }
}

.dash-listing-card__main {
  display: flex;
  justify-content: space-between;

  @include mobile {
    display: block;
  }
}

.dash-listing-card__titleblock {
  display: flex;
  flex: 0 0 75%;

  @include mobile {
    justify-content: space-between;
  }
}

.dash-listing-card__thumbnail {
  flex: 0 0 60px;

  .dash-listing-card--condensed & {
    flex-basis: 40px;
  }

  @include mobile {
    flex-basis: 46px;
    order: 2;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 4px;
    border: 1px solid var(--rc-color-border-primary);
  }
}

.dash-listing-card__info {
  margin-left: 14px;

  @include mobile {
    margin-left: 0;
    margin-right: 10px;
  }
}

.dash-listing-card__title,
.dash-listing-card__your-cost,
.dash-listing-card__info-stat,
a.dash-listing-card__stat {
  @include links.text-link;
}

.dash-listing-card__title {
  font-size: 16px;
}

.dash-listing-card__sku {
  color: var(--rc-color-text-secondary);
  margin: 0;
}

.dash-listing-card__info-stats {
  margin-top: 6px;
}

.dash-listing-card__order {
  color: var(--rc-color-text-secondary);
}

.dash-listing-card__info-stat {
  margin-right: 6px;
}

.dash-listing-card__cost-block {
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  text-align: right;
  align-items: flex-end;

  @include mobile {
    text-align: left;
  }
}

.dash-listing-card__price {
  margin: 0;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.4;

  &.dash-listing-card__price--original {
    font-size: 1.4rem;
    text-decoration: line-through;
    font-weight: normal;
    color: var(--rc-color-text-secondary);
  }

  &.dash-listing-card__price--sale {
    color: var(--rc-color-text-price-nudge);
  }
}

.dash-listing-card__original-order {
  line-height: 1.5;
}

.dash-listing-card__details-toggle {
  @include rc-button.muted($size: 'mini');
  @include fa-icon-after('chevron-down', $margin-left: 0.25em);
  margin-top: var(--rc-space-2);
  align-self: flex-end;

  &[data-toggle-container-state='open'] {
    @include fa-icon-after('chevron-up', $margin-left: 0.25em);
  }
}

.dash-listing-card__details-wrapper {
  display: none;
}

.dash-listing-card__details {
  border-top: solid 1px var(--rc-color-border-primary);
  margin-top: 14px;
  padding-top: 14px;
  display: flex;
  justify-content: space-between;

  @include mobile {
    display: block;
  }
}

.dash-listing-card__state-condition {
  @include mobile {
    text-align: center;
    margin-bottom: 10px;
  }

  > :first-child {
    margin-left: 0;
  }
}

.dash-listing-card__stats {
  > :last-child {
    margin-right: 0;
  }
}

.dash-listing-card__stat {
  text-align: center;
  display: inline-block;
  margin: 0 12px;

  @include mobile {
    margin: 0 4px;
  }
}

.dash-listing-card__stat-count {
  font-size: 16px;
  font-weight: 700;
  display: block;

  &.dash-listing-card__stat-count--state-live {
    color: var(--rc-color-text-success);
  }
}

.dash-listing-card__stat-label {
  display: block;
}

.dash-listing-card__product-summary {
  flex: 0 0 45%;
  margin-left: 14px;

  @include mobile {
    margin: 10px 0 0;
  }
}

.dash-listing-card__order-details {
  flex: 0 0 25%;
  margin-left: 20px;

  @include mobile {
    margin: 10px 0;
  }
}

.dash-listing-card__refund-input {
  flex: 0 0 8%;
  margin-left: 20px;

  @include mobile {
    margin-left: 0;
  }

  input {
    @include mobile {
      margin-bottom: 0;
    }
  }
}

.refund-picker__meta {
  @include scaling(margin-bottom, 10px);

  &.refund-picker__meta--entered-total {
    @include scaling(margin-top, 10px);
    text-align: right;
  }
}

.refund-picker__meta__col {
  display: inline-block;
  margin-right: 15px;

  @include mobile {
    margin-bottom: 5px;
  }
}

.refund-picker__meta__label {
  color: var(--rc-color-text-secondary);
}

.dash-listing-card__order-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  td {
    padding: 0.25em 0;

    & + td {
      text-align: right;
    }
  }

  tr:first-of-type {
    td {
      padding-top: 0;
    }
  }

  tr:last-of-type {
    border-top: 1px solid var(--rc-color-border-primary);
    font-weight: bold;

    td {
      padding-bottom: 0;
    }
  }
}

.refund-picker__reason-form {
  @include scaling(margin-top, 10px);
  box-sizing: border-box;
  width: 40%;

  @include mobile {
    width: 100%;
  }
}

.refund-picker__back {
  @include scaling(margin-bottom, 20px);
  display: block;
}

.refund-picker__header {
  @include scaling(margin-bottom, 20px);
}

.dash-listing-card__refunds-index {
  display: flex;
  justify-content: space-between;

  @include mobile {
    display: block;
  }
}

.dash-listing-card__refunds-index__col {
  @include mobile {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
