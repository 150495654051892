.auction-label {
  display: inline-block;
  font-weight: bold;
  font-size: 1.3rem;
  color: var(--rc-color-text-secondary);
  text-transform: uppercase;
}

.time-left {
  display: inline-block;
  color: var(--rc-color-sentiment-negative-dark);
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 2.4rem;
}

.bids-label {
  display: inline-block;
  color: var(--rc-color-text-primary);
  font-size: 2.4rem;
  font-weight: bold;
}

.max-winning {
  color: var(--rc-color-text-alert-success);
}

.max-losing {
  color: var(--rc-color-sentiment-negative-dark);
}

p.muted.bid-increment {
  transition: background-color .5s linear;
  font-weight: bold;
  line-height: 34px;
  float: left;
  &.error {
    color: var(--rc-color-text-danger);
  }
}

.bid-above-buy-it-now {
  font-weight: bold;
  color: var(--rc-color-text-price-nudge);
}

table.bid-history {
  td, th {
    border: 0px;
    padding: 6px 10px;

    &.bidder { width: 170px; padding-left: 0}
    &.bid-amount { width: 160px; }
  }
}

div.module.faq-hint {
  background: var(--rc-color-background-module-secondary);
  font-weight: normal;
  color: var(--rc-color-text-primary);
  width: 100%;
  box-sizing: border-box;
}
