.default-transition-enter {
  opacity: 0.01;
  &.default-transition-enter-active {
    opacity: 1;
    transition: opacity 150ms ease-in;
  }
}

.default-transition-leave {
  opacity: 1;
  &.default-transition-leave-active {
    opacity: 0.01;
    transition: opacity 150ms ease-in;
  }
}
