.payout-option {
  @include clearfix;

  border-radius: $input-border-radius;
  cursor: pointer;
  border: 2px solid var(--rc-color-palette-gray-200);
  background: var(--rc-color-palette-gray-100);

  @include responsive-hover {
    cursor: pointer;
    background: var(--rc-color-palette-bw-white);
    border-color: var(--rc-color-palette-gray-500);
  }

  label {
    padding: 12px;

    .payout-radio {
      margin: 3px auto 0;
    }
  }

  &.payout-option-selected {
    background: var(--rc-color-palette-bw-white);
    border-color: var(--rc-color-sentiment-positive-dark);
  }
}

.edit-paypal-steps {
  list-style: none;
  counter-reset: edit-paypal-counter;
  padding-left: 2rem;
  margin-bottom: 2rem;
  max-width: 50rem;

  li {
    counter-increment: edit-paypal-counter;
    margin: 0 0 1rem 0;
    position: relative;

    &:before {
      content: counter(edit-paypal-counter) ". ";
      color: var(--rc-color-palette-vu-orange-500);
      font-weight: bold;
      position: absolute;
      left: -2rem;
    }
  }
}

