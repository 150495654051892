@use '@reverbdotcom/cadence/styles/tools/mixins/svg';
@use '@reverbdotcom/cadence/styles/config/scss-tokens';

.profile-type {
  display: flex;

  @include mobile {
    display: block;
  }
}

.profile-type__item {
  flex: 1;
  margin-right: 3%;
  position: relative;
  display: flex;

  &:last-child {
    margin-right: 0;
  }

  @include mobile {
    margin-right: 0;
  }
}

.profile-type__item__title {
  font-weight: bold;
  display: block;

  &.profile-type__item__title--public {
    @include fa-icon-before(user-circle-o);
  }

  &.profile-type__item__title--private {
    @include fa-icon-before(lock);
  }
}

.profile-type__item__description {
  display: block;
  font-size: 85%;
  color: var(--rc-color-text-secondary);
}

.public-profile-example {
  margin-top: 0.5em;
  padding: 6px;
  border-radius: 3px;
  border: 1px solid var(--rc-color-palette-gray-200);
}

.public-profile-example__container {
  @include grid-container;
}

.public-profile-example__image {
  @include grid-column(3);

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.public-profile-example__content {
  @include grid-column(9);
  font-size: 80%;
}

.public-profile-example__content__links {
  font-weight: bold;
  margin: 0;
}

.public-profile-example__content__links__name-link {
  color: var(--rc-color-palette-blue-600);
}

.public-profile-example__content__links__shop-link {
  // stylelint-disable-next-line cadence/no-scss-tokens -- Needs scss var for legacy support
  @include svg.icon-before(shop, scss-tokens.$rc-color-palette-blue-600);
  color: var(--rc-color-palette-blue-600);

  &:before {
    margin-right: 0.2em;
  }
}
