// DEPRECATED - use design system mixins and classes for these

@mixin show-only-on-desktop {
  @include mobile {
    display: none !important;
  }
}

@mixin show-only-on-mobile {
  @include breakpoint-tablet-up {
    display: none !important;
  }
}

.show-only-on-desktop {
  @include show-only-on-desktop;
}

.show-only-on-mobile {
  @include show-only-on-mobile;
}

// deprecated - will be refactored away
@include mobile { .hidden-mobile { display: none !important; } }
@include breakpoint-tablet-up { .hidden-desktop { display: none !important; } }

// mystical responsive hacks, will be refactored away hopefully
%no-nav-mobile {
  .site-header, .site-footer {
    @include mobile {
      display: none;
    }
  }
}

html.mobile-dialog { @extend %no-nav-mobile; }

.in-mobile-app { @extend %no-nav-mobile; }
