.reverb-trulioo-banner {
  margin: 0 auto;
  max-width: 49rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 6rem;
}

.reverb-trulioo-banner__porthole {
  background: var(--rc-color-palette-blue-700);
  border: 0.8rem solid var(--rc-color-palette-blue-700);
  border-radius: 5rem;
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 3rem;
  left: 20;
}

.reverb-trulioo-banner__reverb-trulioo {
  background-color: var(--rc-color-palette-blue-700);
  height: 8rem;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  display: flex;
  justify-content: center;

  img {
    width: 20rem;
  }
}

.reverb-trulioo-banner__description {
  border: 0.1rem solid var(--rc-color-border-primary);
  border-top: 0rem;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  text-align: left;
  padding: 1rem 1.5rem;
  background-color: var(--rc-color-background-module);
}
