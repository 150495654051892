.products.show {
  .horizontal-nav {
    margin-top: 4px;
  }

  .page {
    float: left;
    width: 620px;
  }
}

.listing-description-short-readmore {
  ol, ul {
    margin-bottom: 1.5rem;
  }

  li {
    list-style-type: disc;
    margin: 0 3rem;

    + li {
      margin-top: 0.5rem;
    }
  }

  ol li {
    list-style-type: decimal;
  }
}

.description-section {
  .tabbable .tab-pane {
    padding: 24px;
  }

  section {
    @include scaling(margin-bottom, 4rem);
    word-wrap: break-word;

    &:last-child { margin-bottom: 0; }

    ul, ol { line-height: 1.5; }

    h4 { margin-bottom: 12px; }
  }

  .description,
  .product-detail-formatted {
    line-height: 26px;
    font-size: 1.6rem;

    dl {
      @include clearfix;
    }

    p {
      line-height: 26px;
      margin-bottom: 2rem;
    }

    p, li, dl { font-size: 1.6rem; }

    ul, ol { margin-bottom: 2rem; }

    a { font-weight: normal; }

    h3 {
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }

    ul { @extend %bullets; }

    hr { margin: 16px 0; }

    &.description {
      border-top: 0.1rem solid var(--rc-color-border-primary);
      padding-top: 1.5rem;
      margin-top: 1.5rem;
    }
  }
}

.description-section__spec-list {
  line-height: 1.1;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em;

  dt {
    flex: 0 0 15rem;
    font-weight: bold;
    white-space: normal;
    margin-bottom: 0.7em;
  }

  dd {
    flex: 0 0 calc(100% - 15rem);
    // ie11 doesn't calc inside flex-basis
    min-width: calc(100% - 15rem);
    padding: 0;
    margin-bottom: 0.7em;
  }
}
