dl.label-pairs {
  dt {
    margin-bottom: 0.25em;

    label {
      margin-bottom: 0;
    }
  }
  dd {
    margin-bottom: 1em;

    input,
    select {
      margin-bottom: 0;
    }

    + dd {
      margin-top: -0.5em;
    }
  }
}

.password-validation {
  position: relative;
}

.password-validation__flag {
  display: none;
  color: var(--rc-color-text-alert-success);
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 18px;

  @include mobile {
    top: 4px;
  }

  &:after {
    @include fa-icon(check);
  }

  &.active {
    display: block;
  }
}

textarea,
input[type=text],
input[type=number] {
  &.focus-sub-label + .sub-label {
    position: absolute;
    pointer-events: none;
    transition: all 0.3s;
    transform: translate3d(0,-100%,0);
    opacity: 0;
  }
  &.focus-sub-label:focus + .sub-label {
    transform: translate3d(0,0,0);
    opacity: 1.0;
  }
}

.textarea--resizeable {
  resize: vertical;
}

legend.form-legend {
  font-size: 1.8rem;
  font-weight: bold;
  padding-bottom: .5em;
}

// Labels
label {
  &.checkbox {
    font-weight: normal;
    font-size: 1.6rem;

    input { margin: 0 3px 0 0; }
  }

  &.inline { display: inline-block; }
}

.sub-label {
  margin-top: -6px;

  label {
    font-size: 1.3rem;
    margin-bottom: 0;
  }
}

select {
  &.small-dropdown {
    font-size: 14px;
    height: 30px;
  }

  &.default {
    padding: 0;
    font-size: 12px;
    border: 1px solid #ccc;
    height: inherit;
  }

  &.nochosen, &.small, &.default {
    @include mobile {
      appearance: none;
      padding: 4px 12px;
    }
  }

  @include mobile {
    &.nochosen { font-size: inherit; }
  }
}

// so that mobile app web views have nice mobile selects
.in-mobile-app select {
  &.nochosen, &.small, &.default {
    @include mobile {
      appearance: none;
      padding: 4px 12px;
    }
  }
}

// Radios and Checkboxes
.radio-buttons {
  label { display: inline; }
}

.radio-filter-label {
  margin-left: 2px;
  font-weight: normal;
  color: var(--rc-color-palette-blue-600);
  font-size: 1.6rem;
}

.inline-checkbox {
  input[type=radio] {
    position: relative;
    vertical-align: top;
    margin: 3px -20px 0 0;
  }

  label { padding-left: 26px; }
}

// Inline Labels
// ie. $, oz., lbs., etc.
[class*=suffix-] {
  input[type=number],
  input[type=tel],
  input[type=text],
  { padding-right: 26px; }
}

[class*=prefix-] {
  input[type=text],
  input[type=number],
  input[type=tel] { padding-left: 20px; }
}

[class*=suffix-]:after,
[class*=suffix-]::after,
[class*=prefix-]:before,
[class*=prefix-]::before {
  pointer-events: none;
  position: absolute;
  display: block;
  bottom: 2rem;
  line-height: 1;
}

[class*=suffix-]:after,
[class*=suffix-]::after {
  right: 1rem;
}

[class*=prefix-]:before,
[class*=prefix-]::before {
  left: 1rem;
}

// Prefix labels
.prefix-dollars:before { content: '$'; }

// Suffix labels
.suffix-inches:after { content: 'in'; }
.suffix-centimeters:after { content: 'cm'; }
.suffix-pounds, .suffix-kilograms, .suffix-centimeters {
  input[type=tel],
  input[type=text],
  input[type=number] {
    padding-right: 32px;
  }
}
.suffix-pounds:after { content: 'lbs'; }
.suffix-kilograms:after { content: 'kg'; }

// prepend font-awesome '$' icon
// tweaked elsewhere for search filters
.prepend-icon-wrapper {
  position: relative;

  input.text { padding-left: 18px; }

  .prepend-icon {
    color: #aaaaaa;
    position: absolute;
    top: 7px;
    left: 7px;
    font-size: 1.4rem;
  }

  .append-icon {
    color: #aaaaaa;
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 1.4rem;
  }
}

.unlabeled {
  margin-top: 20px;
}
