.print {
  background: var(--rc-color-palette-bw-white);

  tr {
    page-break-inside: avoid;
  }
}

.print, .invoice-pdf.print {
  margin: 0;
  padding: 0;
  color: #555;

  a {
    color: #555;
  }

  td.left-col, th.left-col {
    width: 330px;
  }

  td.right-col, th.right-col {
    width: 240px;
  }

  td.half-table {
    width: 130px;
  }

  .print-false {
    display: none;
  }

  .tabbable .tab-content {
    border: 0;
  }
}

// In order to hide the "Print Shipping Label"
// button for when in a print window of a browser
// but not when our print layout is used to render the shipping label preview
.shipping-label-print-btn {
  @media print {
    display: none;
  }
}
