@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';

.pagination {
  margin: 4rem auto;
  text-align: center;

  // hack because of will-paginate output doubling classes
  .pagination {
    margin: 0 auto;
  }

  @include mobile {
    margin: 2rem auto;
  }

  .product-reviews & {
    margin: 1rem auto 0;
  }

  &.pagination--hide-disabled {
    li.disabled {
      display: none;
    }
  }

  &.pagination--hide-endcaps {
    li.previous_page.disabled,
    li.next_page.disabled {
      display: none;
    }
    li:not(.disabled) + li.disabled + li:not(.next_page) {
      display: none;
    }
  }

  ul {
    display: inline-block;

    @include mobile {
      display: flex;
    }

    li {
      display: inline;

      @include mobile {
        flex: 1;

        &:not(:first-of-type):not(:last-of-type) {
          display: none;
        }
      }

      + li {
        margin-left: 5px;
      }

      a {
        @include rc-button.muted;

        @include mobile {
          width: 100%;
        }
      }

      &.active a,
      &.active a:hover {
        @include rc-button.colors($theme: 'main', $variant: 'outlined', $inverted: false);
      }

      &:not(.prev, .next) a {
        padding-left: var(--rc-space-1);
        padding-right: var(--rc-space-1);
      }
    }


    li.dotspace a,
    li.dotspace a:hover,
    li.disabled a,
    li.disabled a:hover {
      text-decoration: none;
      cursor: default;
      opacity: 0.25;
      pointer-events: none;
    }

  }
}

.pagination .previous_page a {
  @include mobile {
    padding: 0.75rem 1.5rem;
    min-width: 10rem;
    white-space: nowrap;
  }

  .fa {
    padding-right: 0.5em;
  }
}

.pagination .next_page a {
  @include mobile {
    padding: 0.75rem 1.5rem;
    min-width: 10rem;
    white-space: nowrap;
  }

  .fa {
    padding-left: 0.5em;
  }
}

.checkout-address-pagination {
  .pagination {
    margin-top: 0;
    text-align: right;

    @include mobile {
      text-align: center;
    }
  }

  .page-num {
    display: none;
  }
}
