.admin-curation-dialog {
  box-sizing: border-box;
  max-height: 80vh;
  width: 90vw;
  overflow: auto;
  @include mobile {
    // necessary override
    &.body {
      padding: 5px;
    }
  }
}

.admin-curation-dialog__item {
  @include grid-column(3, $cycle: true);
  border-bottom: 0.7%;
  label {
    font-size: 0.9em;
    font-weight: normal;
    margin: 2px;
    cursor: pointer;
    color: var(--rc-color-palette-gray-600);
    transition: all 0.1s;
    padding: 2px 4px;
    border-left: 2px solid var(--rc-color-background-page-secondary);
    &:hover {
      color: var(--rc-color-text-primary);
    }

    @include mobile {
      font-size: 1em;
      padding: 5px;
    }
  }
  input[type='checkbox'] {
    display: none;
    &:checked ~ label {
      border-color: var(--rc-color-palette-vu-orange-500);
      color: var(--rc-color-text-primary);
    }
  }
  @include mobile {
    @include grid-column(6, $cycle: true);
  }
}
