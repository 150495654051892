$height: 21rem;
$mobile-height: 18.5rem;

.email-signup {
  @include commons-svg-background('instruments-lighten.svg');
  @include box-shadow;
  background-color: var(--rc-color-palette-blue-600);
  transform: translate3d(0,0,0);
  transition: all 250ms ease-in-out;
  box-sizing: border-box;
  height: $height;
  padding: 2.4rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: -$height;
  margin-top: 0;
  width: 100%;
  z-index: 2000;

  .loading-component {
    margin: 0;
  }

  &.active {
    bottom: 0;
  }

  @include mobile {
    height: $mobile-height;
    border-radius: 0;
    bottom: -$mobile-height;
    margin-top: auto;
    padding: 1.2rem 1.8rem;
    width: 100%;

    &.active {
      bottom: 0;
    }
  }
}

.email-signup__form-wrapper {
  width: 50rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include mobile {
    width: 100%;
  }
}

.email-signup__terms {
  margin-top: 0.5rem;
  font-size: 1.1rem;
  color: var(--rc-color-palette-bw-white);
  text-align: center;
}

.email-signup__message-wrapper {
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @include mobile {
    width: 100%;
  }
}

.email-signup__message-title {
  color: var(--rc-color-palette-bw-white);
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 0.25em;

  @include mobile {
    font-size: 1.8rem;
  }
}

.email-signup__message-subtitle {
  color: var(--rc-color-palette-bw-white);
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0 0 1rem;

  @include mobile {
    font-size: 1.2rem;
    line-height: 1.2em !important; // 'important' b/c blog posts have a global override on <p>
  }
}

.email-signup__close-icon {
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  padding: 0;
  color: var(--rc-color-palette-bw-white);
  font-size: 2rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;

  &:hover {
    opacity: 0.75;
  }

  @include mobile {
    right: 0.4rem;
    top: 0.1rem;
  }
}
