.categorization-list {
  column-count: 3;
  column-gap: 3em;

  .categorization-list {
    border-left: 2px solid var(--rc-color-border-primary);
    font-weight: normal;
    column-count: 1;
    column-gap: 0;
    margin-left: 1em;
    padding-left: 1em;
  }

  @include mobile {
    padding: 1em;
    column-count: 1;
    column-gap: 0;
  }
}

.categorization-list__item {
  column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  margin-bottom: 20px;

  .categorization-list__item {
    padding: 0.3em 0;
    margin: 0;
  }

  h3 {
    font-size: 160%;
    margin-bottom: 1em;
  }

  @include mobile {
    padding-left: 0.2em;
  }
}
