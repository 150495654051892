@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.product-sidebar {
  @include tablet {
    font-size: 1.4rem;
  }
}

.product-sidebar,
.sidebar-price-module {
  strike {
    display: block;
    clear: both;
    color: var(--rc-color-text-secondary);
    font-weight: normal;
  }
}

.sale-accent {
  color: var(--rc-color-text-price-nudge);

  .purchase-module-header & {
    margin: -1.6em 0 0.3em 0;
    text-align: center;
    font-size: 2rem;

    .fa {
      background-color: var(--rc-color-palette-gray-100);
      padding: 0 0.5em;
    }
  }

}

.sale-detail {
  padding-left: 2rem;
  line-height: 1.15;

  .purchase-module-header & {
    padding-left: 0;
    line-height: 1.25;
  }
}

.sale-detail__save {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.6rem;
  color: var(--rc-color-text-price-nudge);
  margin: 0;
}

.sale-detail__instant-discount {
  color: var(--rc-color-text-price-nudge);
  margin: 0;
}

.sale-detail__shop-more {
  @include fa-icon-after(angle-right);
  font-size: 80%;
}

.big-price {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 2.4rem;
}

.listing-price {
  font-size: 3.2rem;
  font-weight: bold;
  white-space: nowrap;

  .price-currency {
    font-size: 0.5em;
  }

  &.listing-price--accepted-offer {
    color: var(--rc-color-text-alert-success);
  }

  &.listing-price--sticky-buy-price {
    @include responsive(font-size, 2.4rem, 2.2rem, 1.8rem);
    padding: 0 1rem;
  }
}

.listing-price__on-sale__you-pay {
  font-size: 1.4rem;
}

.listing-price__on-sale {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--rc-color-text-price-nudge);
}

.listing-price__price-dropped {
  color: var(--rc-color-text-price-nudge);
}

.listing-price__strike {
  font-size: 2rem;
  text-decoration: line-through;
  font-weight: normal;
  color: var(--rc-color-text-secondary);
}

.listing-price__on-sale__sale-price {
  font-size: 2.5rem;
  font-weight: bold;
  padding-left: 0.5rem;
}

.listing-shipping-price {
  font-size: 1.4rem;
  font-weight: normal;
  white-space: normal;
  color: var(--rc-color-text-secondary);

  &.listing-shipping-price--sticky-buy {
    font-size: 1.2rem;
  }
}

.sidebar-price-module {
  text-align: center;

  .big-price {
    color: var(--rc-color-text-primary);

    strike {
      margin-bottom: 0.4rem;
    }
  }

  .in-stock {
    line-height: 1rem;
    font-weight: normal;
  }
}

.ship-price {
  font-size: 1.4rem;
  color: var(--rc-color-text-secondary);
  white-space: nowrap;
  font-weight: bold;
}

.in-stock {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--rc-color-text-secondary);
}

.make-offer-open {
  @include rc-button.muted;

  .fa {
    font-size: 0.9em;
  }
}

.sidebar-related-content {
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  background: var(--rc-color-background-module-secondary);

  img {
    display: block;
  }

  h4 {
    padding: 9px;
    margin: 0;
  }

  @include overlay-text(
    $border-radius: 3px,
    $text-padding: 12px 15px,
    $subtitle-size: 12px,
    $title-size: 14px,
    $gradient-start: 50%
  );
}

.comparison-shopping-sidebar-prices {
  li:nth-child(2) {
    border-top: 1px solid var(--rc-color-border-primary);
  }
}

.watch-and-handpick {
  display: flex;
}

.watch-and-handpick__col {
  text-align: center;
  flex: 1 1 auto;

  &.watch-and-handpick__col--handpick {
    max-width: 65%;
  }
}

.free-two-day-shipping-tooltip {
  font-size: 12px;
  color: var(--rc-color-palette-vu-orange-500);

  @include mobile {
    font-size: inherit;
  }
}

.free-two-day-shipping-tooltip__wrapper {
   div {
     display: inline;
   }
 }

.sticky-buy {
  z-index: 3;
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  padding: 1rem 0;
  background-color: var(--rc-color-palette-bw-white);
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
  transition: top 0.3s ease-in-out;

  &.sticky-buy--active {
    top: 0;
  }
}

.sticky-buy__inner {
  @include site-wrapper;
  @include site-wrapper--narrow;

  display: flex;
  align-items: center;
}

.sticky-buy__price {
  flex: 1;
  text-align: center;
  padding: 0 2rem;
}

.sticky-buy__title {
  flex: 6;
  font-size: 1.8rem;
  font-weight: bold;

  @include mobile {
    display: none;
  }
}

.sticky-buy__button {
  flex: 1;
}

.sidebar-callout {
  position: relative;
  padding: 1rem;
  margin-top: 1.5rem;
  border: 1px solid;
  border-color: var(--rc-color-palette-blue-500);
  background: var(--rc-color-palette-blue-100);
  color: var(--rc-color-text-primary);
  font-size: 90%;
  text-align: center;

  &.sidebar-callout--watch {
    .used-vs-new-nudge-present & {
      display: none;
    }
  }

  &:before {
    content: '';
    border: 1.1rem solid transparent;
    border-bottom-color: var(--rc-color-palette-blue-500);
    position: absolute;
    bottom: 100%;
    right: 50%;
    transform: translateX(50%);
  }

  &:after {
    content: '';
    border: 1rem solid transparent;
    border-bottom-color: var(--rc-color-palette-blue-100);
    position: absolute;
    bottom: 100%;
    right: 50%;
    transform: translateX(50%);
  }

  &.sidebar-callout--offer {
    background: var(--rc-color-palette-vu-orange-100);
    border-color: var(--rc-color-palette-vu-orange-400);
    color: var(--rc-color-palette-vu-orange-700);

    &:before {
      border-bottom-color: var(--rc-color-palette-vu-orange-400);
    }

    &:after {
      border-bottom-color: var(--rc-color-palette-vu-orange-100);
    }
  }

  &.sidebar-callout--used-vs-new {
    margin-bottom: 1rem;
    font-size: 1.4rem;
    font-weight: normal;
    border-color: var(--rc-color-sentiment-positive-light);
    background: var(--rc-color-sentiment-positive-lighter);
    color: var(--rc-color-text-primary);

    &:before {
      border: 1.1rem solid transparent;
      border-top-color: var(--rc-color-sentiment-positive-light);
      bottom: unset;
      top: 100%;
    }

    &:after {
      border: 1rem solid transparent;
      border-top-color: var(--rc-color-sentiment-positive-lighter);
      bottom: unset;
      top: 100%;
    }
  }
}
