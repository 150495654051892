body {
  &.ReactModal__Body--open {
    overflow: hidden;
  }

  @include mobile {
    &:after {
      content: '';
      background: var(--rc-color-highlight-600);
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1999;
      opacity: 0;
      transition: opacity .3s;
    }

    &.mobile-menu-open {
      overflow: hidden;
    }

    &.mobile-panel-open {
      overflow: hidden;
      &:after {
        bottom: 0;
        opacity: 1;
      }
    }
  }
}

.container {
  &.cms, &.pages-cms {
    background: var(--rc-color-background-page);
  }
  &.blog-container {
    position: relative;
  }
}

// Hide page-header on full_responsive CMS layouts to allow full-width banners
.pages-content {
  .page-header { display: none; }
}

.full-width-footer {
  &.full-width-footer__dark {
    background: var(--rc-color-palette-gray-300);
  }
}

// legacy class; please use .viewport-bleed
.content-width-full-bleed {
  @include viewport-bleed;
}

// legacy class;
// please use the site-wrapper class and
// subclasses from design system
.content-frame {
  @include site-wrapper;

  // i'd love to remove this but the side effects
  // are going to be very hard to track down
  position: relative;

  &.content-frame--narrow {
    @include site-wrapper--narrow;
  }

  &.content-frame--ultrawide {
    max-width: 1600px;
  }

  .body-checkout & {
    padding-bottom: 40px;

    @include mobile {
      padding-bottom: 20px;
    }
  }
}

.light-frame { background: var(--rc-color-palette-bw-white); }

.dark-frame { background: var(--rc-color-palette-gray-300); }

.page {
  position: relative;
}

#content {
  @include site-wrapper;

  &.form {
    margin: auto;
    padding: 12px;

    form {
      background: var(--rc-color-palette-bw-white);
      padding: 22px;
      padding-top: 0;
      border-radius: 5px;
    }
  }
}

.dark-blue-bg {
  background: var(--rc-color-palette-gray-900);
  color: var(--rc-color-palette-bw-white);

  a {
    color: var(--rc-color-palette-bw-white);
    &:hover { opacity: 0.90; }
  }
}

.categories-sidebar {
  width: 220px;
  float: left;

  &.wide { width: 100%; }
}
