@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.refer-a-friend__header {
  @include site-wrapper;
  @include site-wrapper--narrow;
  display: flex;
  align-items: center;

  h1 {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 0.3em;
  }

  @include mobile {
    display: block;
    text-align: center;

    h1 {
      font-size: 1.6em;
    }
  }
}

.refer-a-friend__header__image {
  flex: 0 0 30rem;
  align-self: flex-end;
  padding-top: 3rem;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @include mobile {
    width: 40vw;
    margin: 0 auto;
    padding: 0;
  }
}

.refer-a-friend__module-section {
  @include commons-svg-background('instruments-darken.svg');

  padding: 3rem 0;
  border-top: 0.1rem solid var(--rc-color-border-primary);
  border-bottom: 0.1rem solid var(--rc-color-border-primary);
}

.refer-a-friend__module {
  max-width: $site-width-cms;
  background: white;
  border: 0.1rem solid var(--rc-color-palette-gray-200);
  text-align: center;
  margin: 0 auto;

  @include mobile {
    margin: 0 1rem;
  }
}

.referral-link-top {
  background: var(--rc-color-palette-vu-orange-500);
  border-radius: 6px 6px 0 0;
}

.referral-link-btm {
  background: var(--rc-color-palette-bw-white);
  border-radius: 0 0 6px 6px;
  padding: 1.5rem;

  @include mobile {
    padding: 1rem 0.5rem;
  }

  input[type='text'] {
    padding: 12px 0;
  }
}

.referral-signup {
  background: var(--rc-color-palette-bw-white);
}

.referral-what-is-reverb {
  background-image: url('../images/referrals/what-is-reverb-refer-a-friend.jpg');

  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.refer-a-friend-homepage-lead {
  font-size: 24px;
  font-weight: bold;

  @include mobile {
    font-size: 1.4rem;
  }
}

.refer-a-friend-homepage-subtext {
  font-size: 24px;

  @include mobile {
    font-size: 13px;
  }
}

.referral-facebook-share,
.referral-twitter-share {
  // Building a 1-off rc-button instance to support vendor buttons until they can be RC'd
  @include rc-button.filled($size: 'small', $fullwidth: true);

  // Set CSS vars to override rc-button defaults
  --text-color: var( --rc-color-palette-bw-white);
  --text-color-hovered: var(--rc-color-palette-bw-white);

  &:hover {
    opacity: 0.85;
  }
}

.referral-facebook-share {
  // Set CSS vars to override rc-button defaults
  --background-color: var(--rc-color-vendor-facebook);
  --background-color-hovered: var(--rc-color-vendor-facebook);
}

.referral-twitter-share {
  // Set CSS vars to override rc-button defaults
  --background-color: var(--rc-color-vendor-twitter);
  --background-color-hovered: var(--rc-color-vendor-twitter);
}

.referral-link {
  @include mobile {
    text-align: left;
  }
}

.copied-to-clipboard-tooltip {
  background: var(--rc-color-palette-gray-900);
  padding: 6px 10px;
  color: var(--rc-color-palette-bw-white);
  border-radius: 6px;
  opacity: 0.8;
  float: right;
}
