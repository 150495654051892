.pg-hero {
  display: flex;
  align-items: center;
  background: var(--rc-color-palette-bw-white);

  @include tablet {
    display: block;
  }
}

.pg-hero__col {
  flex: 0 0 50%;
  display: flex;
  justify-content: flex-end;

  @include tablet {
    justify-content: center;
  }

  &.pg-hero__col--search {
    background: var(--rc-color-palette-gray-900) url('../images/price-guides/pg-header.jpg') center no-repeat;
    background-size: cover;
    justify-content: flex-start;

    @include tablet {
      justify-content: center;
    }
  }
}

.pg-hero__intro {
  padding: 4vw 3vw;
  max-width: 500px;

  @include tablet {
    max-width: 90%;
    text-align: center;
  }

  @include mobile {
    padding: 6vw 0;
  }
}

.pg-hero__intro__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.pg-hero__intro__subtitle {
  font-size: 14px;

  @include tablet {
    text-align: center;
  }
}

.pg-hero__search {
  padding: 8vw 3vw;
  box-sizing: border-box;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include tablet {
    padding: 6vw 3vw;
  }
}

.pg-hero__search__label {
  font-weight: bold;
  font-size: 24px;
  color: var(--rc-color-palette-bw-white);
  margin-bottom: 10px;

  @include mobile {
    font-size: 18px;
  }
}

.pg-section-title {
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;

  @include mobile {
    font-size: 18px;
  }
}
