.registration-form-header {
  width: 100%;
  height: 260px;
  background-size: cover;
  box-sizing: border-box;

  .registration-title {
    color: var(--rc-color-palette-bw-white);
    font-size: 2.2rem;
  }
}

.body-registration-form {
  background-color: var(--rc-color-palette-bw-white);
}

.registration-form {
  background-color: var(--rc-color-palette-bw-white);

  .thank-you-guitar {
    width: 215px;
    height: auto;
  }

  @include mobile {
    padding: 0 1rem;
  }
}

.registration-datepicker + .picker {
  width: 260px;
  @include mobile { width: 90%; }
}

// Special NAMM newsletter signup form
.newsletter-signup-page {
  .body-wrapper {
    margin-top: 0;

    &:after {
      height: 0;
    }
  }

  .site-footer {
    display: none;
  }
}

.newsletter-signup-hero {
  padding: 5rem 0;
  height: auto;
  background-size: cover;
  background-position: center center;
  color: var(--rc-color-palette-bw-white);
}
